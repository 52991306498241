import { createNativeStackNavigator } from "@react-navigation/native-stack"
import TrainingScreen from "../screens/TrainingScreen"
import TrainingDetailsScreen from "../screens/TrainingDetailsScreen"
import { TrainingMgmtParamList, TrainingParamList } from "./types"
import TrainingProvider from "../providers/TrainingProvider"
import TrainingProgramScreen from "../screens/TrainingProgramScreen"
import { useMember } from "../hooks/useMember"
import TrainingMgmtProvider from "../providers/TrainingMgmtProvider"
import { useEffect } from "react"
import { Platform } from "react-native"
import TrainingInstructorProvider from "../providers/TrainingInstructorProvider"
import TrainingInstructorView from "../components/instructor/TrainingInstructorView"
import { useIsFocused } from "@react-navigation/native"
import TrainingMgmtProgram from "../screens/TrainingMgmtProgramScreen"
import TrainingMgmtScreen from "../screens/TrainingMgmtScreen"

const TrainingStack = createNativeStackNavigator<TrainingParamList>()
const TrainingMgmtStack = createNativeStackNavigator<TrainingMgmtParamList>()
export default function TrainingNavigator() {

    const isFocused = useIsFocused()
    const { member, trainingMode, updateTrainingMode } = useMember()

    useEffect(() => {
        if (Platform.OS === 'web') {
            if (window.location.search) {
                const params = new URLSearchParams(window.location.search)
                const trainingModeParam = params.get('mode')
                if (member && member.hubManagementModes.length > 0 && trainingModeParam !== trainingMode) {
                    updateTrainingMode(trainingModeParam)
                }
            }
        }
    }, [])

    if (!isFocused) {
        return null
    }

    if (['management'].includes(trainingMode)) {
        return (
            <TrainingMgmtProvider>
                <TrainingMgmtStack.Navigator screenOptions={{ headerShown: false }}>
                    <TrainingMgmtStack.Screen name="TrainingView" component={TrainingMgmtScreen} options={{ title: 'Training Mgmt Hub' }} />
                    <TrainingMgmtStack.Screen name="TrainingProgram" component={TrainingMgmtProgram} options={{ title: 'Training Mgmt Program' }} />
                </TrainingMgmtStack.Navigator>
            </TrainingMgmtProvider>
        )
    }

    if (['instructor'].includes(trainingMode)) {
        if (member && member.emailValidated) {
            return (
                <TrainingInstructorProvider>
                    <TrainingInstructorView />
                </TrainingInstructorProvider>
            )
        }
        return <TrainingInstructorView />
    }

    return (
        <TrainingProvider>
            <TrainingStack.Navigator screenOptions={{ headerShown: false }}>
                <TrainingStack.Screen name="TrainingView" component={TrainingScreen} options={{ title: 'Training Hub' }} />
                <TrainingStack.Screen name="TrainingProgram" component={TrainingProgramScreen} options={{ title: 'Training Program' }} />
                <TrainingStack.Screen name="TrainingDetails" component={TrainingDetailsScreen} options={{ title: 'Training Details' }} />
            </TrainingStack.Navigator>
        </TrainingProvider>
    )
}