import { Training } from "../../models/TrainingData";
import { H2, H3, View, Text } from '../ui';
import { useDripsyTheme } from "dripsy";
import { Dimensions } from 'react-native';
import { WebViewer } from "../WebViewer";
export default function TrainingInfoView({ training }: { training: Training }) {
    const { theme } = useDripsyTheme()
    const windowSize:any = Dimensions.get('window').height-200;    
    let html = ""
    if (training.publicNote && training.publicNote.length > 0) {
        html += training.publicNote;
    }
    if (training.privateNote && training.privateNote.length > 0) {
        html += '<H3>Individual Information</H3>'+training.privateNote;
    }
    if (training.instructorNote && training.instructorNote.length > 0) {
        html += '<H3>Notes from Your Instructor</H3>'+training.instructorNote;
    }
    return (
        <View sx={{ overflowY:'auto', paddingBottom: '$5', paddingX: '$1', maxHeight: windowSize }}>
            <H2 sx={theme.styles.header}>Class Information</H2>
            <View sx={{ paddingBottom: '$2' }}>
                <WebViewer html={html} />
            </View>
        </View>
    )
}