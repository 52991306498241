import { useDripsyTheme } from 'dripsy';
import { View, Text, H2, H3 } from '../ui';
import { useState } from 'react';
import { RadioButton } from 'react-native-paper';
import { useTraining } from '../../hooks/useTraining';
import { useMember } from '../../hooks/useMember';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import { MaterialTopTabNavigationProp } from '@react-navigation/material-top-tabs';
import { Dimensions } from 'react-native';
import { Pressable } from '../ui/Pressable';

interface EquipmentViewProps {
    opportunityId: string
}

export default function EquipmentView(props: EquipmentViewProps) {
    const { opportunityId } = props
    const [equipmentRequired, setEquipmentRequired] = useState(false)
    const [equipmentNotRequired, setEquipmentNotRequired] = useState(false)
    const { updateOpp, updateTraining } = useTraining()
    const { member } = useMember()
    const navigation = useNavigation<MaterialTopTabNavigationProp<any>>()
    const { theme } = useDripsyTheme()
    const windowSize:any = Dimensions.get('window').height-200;
    
    const needsEquipment = () => {
        setEquipmentRequired(true)
        updateOpp(opportunityId, "HasOwnIMVP", "0")
        updateTraining(opportunityId, { askIfNeedEquipment: false })
    }

    const doesntNeedEquipment = () => {
        setEquipmentNotRequired(true)
        updateOpp(opportunityId, "HasOwnIMVP", "1")
        updateTraining(opportunityId, { askIfNeedEquipment: false })
    }

    const onProfileTab = () => {
        navigation.jumpTo('Profile', {formTab: "address"})
    }

    if (member && !member.shipping) {
        return (
            <View sx={{ overflowY:'auto', paddingBottom: '$5', paddingX: '$3', maxHeight: windowSize }}>
                <H2 sx={theme.styles.header}>Missing Shipping Address</H2>
                <Text>It seems you are missing your shipping address. Please go to the <Text sx={{ ...theme.styles.link }} onPress={() => onProfileTab()}>profile</Text> tab and update your address.</Text>
            </View>
        )
    }

    return (
        <View sx={{ overflowY:'auto', paddingBottom: '$5', paddingX: '$3', maxHeight: windowSize }}>
            <H2 sx={theme.styles.header}>Interact to get the most out of your class with video and audio</H2>
            <Text>Students who share their video in class report higher satisfaction with their class. Your satisfaction is important to us, so we'll provide you complimentary headphones and web camera that are yours to keep.</Text>
            <Text sx={{fontWeight: 'bold', marginTop: '$4', marginBottom: '$2', textAlign: 'center'}}>Do you need headphones with a mic and a webcam?</Text>
            {!equipmentRequired && !equipmentNotRequired ? (
                <>
                    <View sx={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                        <RadioButton.Android
                            value="No"
                            status={ equipmentNotRequired ? 'checked' : 'unchecked'}
                            onPress={doesntNeedEquipment}
                            color={theme.colors.$primary}
                        />
                        <Pressable sx={theme.buttons.radioLabel} onPress={doesntNeedEquipment}><Text>No, I am providing my own equipment</Text></Pressable>
                    </View>
                    <View sx={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                        <RadioButton.Android
                            value="Yes"
                            status={ equipmentRequired ? 'checked' : 'unchecked'}
                            onPress={needsEquipment}
                            color={theme.colors.$primary}
                        />
                        <Pressable sx={theme.buttons.radioLabel} onPress={needsEquipment}><Text>Yes, please send me a Live Virtual kit</Text></Pressable>
                    </View>
                </>
            ):(
                <Text sx={{textAlign: 'center', color: '$primary', fontWeight: 'bold'}}>Thank you</Text>
            )
            }
        </View>
    )
}