import { useState, useEffect } from 'react'
import { Text, View, ActivityIndicator } from '../../components/ui'
import FollowOnCourses from './FollowOnCourses'
import BannerAd from '../ui/BannerAd';
import HubModal from '../ui/HubModal';
import NoTrainingMessage from '../ui/NoTrainingMessage';
import ValidateEmail from '../ui/ValidateEmail'
import TrainingCardView from '../TrainingCardView';
import { useMember } from "../../hooks/useMember";
import { useQuery } from '@tanstack/react-query'
import { useMemberApi } from '../../api/useMemberApi'

interface TrainingCardViewProps {
    windowSelection?: (selection: string) => void
}

export default function TrainingView(props: TrainingCardViewProps) {
    const { windowSelection } = props
    const { getTrainingData } = useMemberApi()
    const { member, setHeader, showHeader } = useMember()
    const { isPending, isError, isLoading, isFetching, data, error, refetch } = useQuery({ queryKey: ['trainingData', member?.email], queryFn: () => getTrainingData(), staleTime: Infinity })
    const [modalVisible, setModalVisible] = useState(false);

    const trainingData = data;

    useEffect(() => {
        setHeader(true);
    }, [showHeader])

    const FooterContent = () => {
        return (
            <><FollowOnCourses courses={trainingData?.followOn} /><BannerAd /></>
        )
    }
    async function onEmailValidation() {
        setModalVisible(false)
    }

    return (
        <>
            {!trainingData || isFetching ? (<ActivityIndicator sx={{ marginTop: '$4' }} size="large" color="$primary" />) : (
                <>
                    <NoTrainingMessage
                        trainingData={trainingData}
                        loading={isLoading} />
                    <TrainingCardView
                        trainingData={trainingData}
                        loading={isPending}
                        onRefreshTraining={refetch}
                        setupValidateEmailModal={() => setModalVisible(true)}
                        FooterContent={FooterContent}
                        windowSelection={windowSelection}
                    />
                </>
            )}
            <HubModal
                modalLoading={false}
                modalVisible={modalVisible}
                ModalContent={<ValidateEmail onSuccess={onEmailValidation} />}
                setModalVisible={setModalVisible} />
        </>
    )
}