export const HubModes = [
    'default',
    'management',
    'instructor'
]

export default interface Member {
    firstName: string
    lastName: string
    email: string
    company: string
    role: string
    emailValidated: boolean
    hubManagementModes: ('default' | 'management' | 'instructor')[]
    instructorType: string
    street: string
    city: string
    country: string
    state: string
    zip: string

    shippingSameAsContact: boolean,
    billingSameAsContact: boolean,

    shipping?: Address
    token?: string
}

export interface ConfirmMember {
    hasAccount: boolean
    name: string
    studentHubId: string
}

export interface Address {
    country: string
    state: string
    street: string
    city: string
    zip: string
}

export interface AddressTypes {
    shipping: string
}

export interface BannerAdvertisement {
    key: number,
    mobile: string,
    desktop: string,
    url: string,
    title: string,
    alt: string
}

