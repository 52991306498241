import { createContext, useEffect, useState } from "react";
import { useMemberApi } from "../api/useMemberApi";
import MissingProvider from "../utils/MissingProvider";
import { TrainingInstructor, TrainingInstructorData, TrainingInstructorFiles } from "../models/TrainingInstructorData";

type TrainingInstructorDataKeys = {
    active: string,
    future: string,
    past: string
}

interface TrainingInstructorDataContextType {
    loading: boolean
    trainingInstructorData: TrainingInstructorData | undefined
    trainingInstructorFiles: TrainingInstructorFiles[] | undefined
    refreshTrainingInstructorData: (start?: string, end?: string) => Promise<void>
    updateTrainingInstructor: (id: number, values: { [key: string]: any }) => void
}

export const TrainingInstructorDataContext = createContext<TrainingInstructorDataContextType>({
    loading: false,
    trainingInstructorData: { active: [], future: [], past: [] },
    trainingInstructorFiles: [],
    refreshTrainingInstructorData: async (start, end) => { MissingProvider() },
    updateTrainingInstructor: (id, values) => MissingProvider
})

interface TrainingInstructorProviderProps {
    children?: React.ReactNode
}

function TrainingInstructorProvider({ children }: TrainingInstructorProviderProps) {
    const { getTrainingInstructorData, getInstructorFiles } = useMemberApi()
    const [trainingInstructorData, setTrainingInstructorData] = useState<TrainingInstructorData>()
    const [trainingInstructorFiles, setTrainingInstructorFiles] = useState<TrainingInstructorFiles[]>()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        async function loadAsync() {
            loadInstructorList();
            loadTrainingInstructorFiles();
        }
        loadAsync()
    }, [])

    async function loadInstructorList(start?: string, end?: string) {
        try {
            setLoading(true)
            const trainingInstructorList = await getTrainingInstructorData(start, end)
            setTrainingInstructorData(trainingInstructorList)
        } catch (err: any) {
            console.error("Error fetching training instructor data", err.toString())
        } finally {
            setLoading(false)
        }
    }

    async function loadTrainingInstructorFiles() {
        try {
            const trainingFiles = await getInstructorFiles()
            setTrainingInstructorFiles(trainingFiles)
        } catch (err: any) {
            console.error("Error loading training instructor files", err.toString())
        }
    }

    async function refreshTrainingInstructorData(start?: string, end?: string) {
        try {
            setLoading(true)
            const trainingDataList = await getTrainingInstructorData(start, end)
            setTrainingInstructorData(trainingDataList)
        } catch (err: any) {
            console.error("Error refreshing training instructor data", err.toString())
        } finally {
            setLoading(false)
        }
    }

    function updateTrainingInstructor(id: number, values: { [key: string]: any }) {

        let k: keyof TrainingInstructorDataKeys

        if (trainingInstructorData) {
            for (k in trainingInstructorData) {
                const trainingList: TrainingInstructor[] = trainingInstructorData[k]
                for (const i in trainingList) {
                    let training = trainingList[i]
                    if (training.id === id) {
                        training = { ...training, ...values }
                        trainingList[i] = training
                    }
                }
            }
        }
    }

    return (
        <TrainingInstructorDataContext.Provider value={{ loading, trainingInstructorData, trainingInstructorFiles, refreshTrainingInstructorData, updateTrainingInstructor }}>
            {children}
        </TrainingInstructorDataContext.Provider>
    )
}

export default TrainingInstructorProvider