import AppProvider from './app/AppProvider';
import AppUI from './app/AppUI';
import * as Sentry from 'sentry-expo'
import { getEnv, isProd, isDev, getVersion, isStaging } from './app/utils/EnvironmentUtils'
import { GestureHandlerRootView } from 'react-native-gesture-handler';

// Init Sentry
if (!isDev()) {
  Sentry.init({
    dsn: 'https://262f9ff6eccb4f2cb649a215814fd91f@o154740.ingest.sentry.io/4504085384265728',
    enableInExpoDevelopment: false,
    enableNative: true,
    environment: getEnv(),
    release: getVersion(),
    debug: isProd() ? false : true, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
  })
}
// End Sentry


export default function App() {
  return (
    <GestureHandlerRootView style={{ flex: 1, overflow: 'hidden' }}>
      <AppProvider>
        <AppUI />
      </AppProvider>
    </GestureHandlerRootView>
  );
}