import { useEffect, useState } from 'react';
import { H2, View, Text } from '../../components/ui';
import { useTraining } from '../../hooks/useTraining';
import { Quiz } from '../../models/Quiz';
import { useDripsyTheme } from 'dripsy';
import { AntDesign } from '@expo/vector-icons';
import CalendarIcon from "svg/calendar-icon.svg"
import CalendarIconGray from "svg/calendaricon-gray.svg"
import { Pressable } from '../ui/Pressable';
import { Linking } from 'react-native';
import { useConfig } from "../../config/useConfig";
import { useMediaQuery } from "react-responsive";

interface QuizzesProps {
    eventId: number
}

export default function Quizzes(props: QuizzesProps) {
    const { eventId } = props
    const { getQuizzes } = useTraining()
    const { theme } = useDripsyTheme()
    const [quizzes, setQuizzes] = useState<Quiz[]>([])
    const { brand } = useConfig()
    const isMobile = useMediaQuery({ query: '(max-width: 550px)' })

    useEffect(() => {
        async function loadAsync() {
            try {
                const fetchQuizzes = await getQuizzes(eventId)
                setQuizzes(fetchQuizzes)
            } catch (err: any) {
                console.warn(err.toString())
            }
        }

        loadAsync()
    }, [])

    const QuizCard = ({ quiz, textColor }: { quiz: Quiz, textColor?: string }) => {
        return (
            <>
                <View sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                    <Text sx={{
                        color: textColor ? textColor : 'white',
                        overflowY: 'visible',
                        marginLeft: '$2',
                        fontSize: '18px',
                        fontWeight: '900',
                        letterSpacing: '0.1px',
                        lineHeight: 25,
                        marginTop: 10,
                        padding: 0,
                        textOverflow: 'ellipsis',
                        height: 50,
                        overflow: 'hidden',
                        WebkitLineClamp: 2
                    }}>{`${quiz.name}`}</Text>
                </View>
                <View sx={{ flexDirection: 'row', color: 'white', alignItems: 'center', position: 'absolute', bottom: 43, display: 'block', left: 13 }}>
                    {quiz.studentLink.length > 0 ?
                        (
                            <CalendarIcon style={{ width: 23, marginRight: 10, marginLeft: 2, position: 'relative', top: 6 }} />
                        ) : (
                            <CalendarIconGray style={{ width: 23, marginRight: 10, marginLeft: 2, position: 'relative', top: 6 }} />
                        )}
                    <Text sx={{ color: textColor ? textColor : 'white', fontSize: 13 }}>Date: {quiz.startDate.toString()} - {quiz.endDate.toString()}</Text>
                </View>
                {quiz.studentLink.length > 0 && <View sx={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Text sx={{ color: brand == 'accelebrate' ? '#fff' : '#ccd814', textDecorationLine: 'underline', textAlign: 'right', fontWeight: 500, marginRight: 6 }}>
                        Take Assessment
                    </Text>
                    <AntDesign name={"right"} size={20} color={brand == 'accelebrate' ? '#fff' : '#ccd814'} />
                </View>}
            </>
        )
    }

    if (quizzes.length == 0) {
        return null
    }

    return (
        <View sx={{ marginBottom: '$3', position: 'relative' }}>
            <H2 sx={{ ...theme.styles.classheader, marginTop: 0 }}>Assessments</H2>
            <View sx={{ display: 'flex', flexDirection: isMobile ? 'inherit' : 'row', flexWrap: isMobile ? 'inherit' : 'wrap' }}>
                {quizzes.map((quiz) => (
                    <View style={{ flexBasis: '50%' }} key={quiz.id}>
                        {quiz.studentLink.length > 0 ? (
                            <Pressable sx={{ ...theme.box, height: 160, minWidth: 270, flexBasis: 'auto', flexShrink: 1, backgroundColor: '#21314D' }}
                                onPress={() => { Linking.openURL(quiz.studentLink) }}>
                                <QuizCard quiz={quiz} />
                            </Pressable>
                        ) : (
                            <View sx={{ ...theme.box, height: 160, minWidth: 270, flexBasis: 'auto', marginTop: '$4', flexShrink: 1 }}>
                                <QuizCard quiz={quiz} textColor='$mediumgray' />
                            </View>
                        )}
                    </View>
                ))}
            </View>
        </View>
    )
}