import { useMediaQuery } from 'react-responsive';
import { H3, View } from '../../components/ui'
import Course from "../../models/Course";
import CourseThumb from './CourseThumb';
import { Platform } from 'react-native';
import { useConfig } from '../../config/useConfig';

export default function FollowOnCourses({ courses }: { courses: Course[] | undefined }) {
    const { features } = useConfig()

    if (features && !features.Tabs.Training?.RecommendedCourses) {
        return null
    }

    const isSmallViewPort = useMediaQuery({ query: '(max-width: 600px)' }) || Platform.OS !== 'web'

    return (
        <>
            { courses && courses.length > 0 && 
                <View sx={{width: '100%', borderTopWidth: 2, borderTopColor: '$primary', marginTop: '$5', paddingTop: '$1'}}>
                    <H3>Your next recommended courses are</H3>
                    <View sx={{flexDirection: 'row', flexWrap: 'wrap', alignItems: 'flex-start', justifyContent: isSmallViewPort ? 'center' : 'flex-start', marginTop: '$1'}}>
                        {courses.map(course => <CourseThumb course={course} key={course.code} />)}
                    </View>
                </View>
            }
        </>
    )
}