import { useEffect, useState } from "react";
import { useTrainingMgmt } from "../../hooks/useTrainingMgmt";
import { ReggieFile } from "../../models/TrainingData";
import { ScrollView, H2, H3, View, Text, ActivityIndicator } from "../ui";
import { Pressable } from '../ui/Pressable';
import { useDripsyTheme } from "dripsy";
import { AntDesign } from '@expo/vector-icons';
import { Dimensions, Linking } from "react-native";
import { useMediaQuery } from "react-responsive";
interface MgmtFilesViewProps {
    eventId: number
    parentEventId: number
}

export default function MgmtFilesView(props: MgmtFilesViewProps) {
    const { eventId, parentEventId } = props;
    const [loading, setLoading] = useState(false)
    const [files, setFiles] = useState<ReggieFile[]>([])
    const { getTrainingMgmtFiles } = useTrainingMgmt()
    const { theme } = useDripsyTheme()

    const isMobile = useMediaQuery({ query: '(max-width: 400px)' });
    const acceptedFileTypeIds = [10, 11, 12]
    let sortedFiles: { [key: string]: ReggieFile[] } = {}

    const windowSize: any = Dimensions.get('window').height - 200;
    const fileStyle = {
        paddingBottom: theme.space.$5,
        paddingX: theme.space.$3,
        maxHeight: windowSize,
        minWidth: theme.sizes.labMinWidth,
        width: '100%'
    }

    useEffect(() => {
        async function loadAsync() {
            setLoading(true)
            try {
                const mgmtFiles = await getTrainingMgmtFiles(eventId, parentEventId)
                setFiles(mgmtFiles)
            } catch (err: any) {
                console.error("Error fetching training management files", err.toString())
            } finally {
                setLoading(false)
            }
        }

        loadAsync()
    }, [])


    if (loading) {
        return <ActivityIndicator sx={{ marginTop: '$4' }} size="large" color="$primary" />
    }

    files.forEach((file) => {
        let filetype = acceptedFileTypeIds.includes(file.typeId) ? file.type : 'Other Files'
        if (filetype in sortedFiles) {
            sortedFiles[filetype].push(file)
        } else {
            sortedFiles[filetype] = [file]
        }
    })

    const FileList = ({ files }: { files: ReggieFile[] }) => {

        return (
            <>
                {files.filter((file) => file.textTitle === "").map((file, index) => (
                    <View key={index} sx={{
                        flexDirection: 'row', justifyContent: 'space-between', paddingVertical: '$2',
                        paddingX: isMobile ? 7 : '$3', alignItems: 'center', backgroundColor: '$gray',
                        borderLeftWidth: 1, borderColor: '$primary',
                        borderBottomWidth: 1,
                        borderRightWidth: 1
                    }}>
                        <Text sx={{ fontSize: 14, color: '$primary', paddingRight: '$5', flexBasis: '85%' }}>{file.name}</Text>
                        <View sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flexBasis: '15%' }}>
                            <Pressable onPress={() => Linking.openURL(file.link)}>
                                <AntDesign name="clouddownloado" size={32} color={theme.colors.$primary} />
                            </Pressable>
                        </View>
                    </View>
                ))}
            </>
        )
    }

    const FileSection = () => {

        let ret = []
        for (const key in sortedFiles) {
            const reggieFiles = sortedFiles[key]

            ret.push(
                <View key={key} sx={{ width: '100%', maxWidth: '560px' }}>
                    <H3 sx={{ marginBottom: '$3' }}>{key}</H3>

                    <View sx={{
                        flexDirection: 'row', justifyContent: 'space-between', paddingVertical: '$2',
                        paddingHorizontal: '$3', alignItems: 'center', backgroundColor: '$primary'
                    }}>
                        <Text sx={{ fontSize: 14, color: '#FFF', fontWeight: 'bold', paddingRight: '$5', flexBasis: '85%' }}>Name</Text>
                        <Text sx={{ fontSize: 14, color: '#FFF', fontWeight: 'bold', flexBasis: '15%' }}></Text>
                    </View>
                    <FileList files={reggieFiles} />
                </View>
            )
        }

        return ret
    }

    return (
        <ScrollView sx={fileStyle}>
            <H2 sx={theme.styles.header}>Files</H2>
            <View sx={{ alignItems: 'center' }}>
                {files.length > 0 ? (
                    <FileSection />
                ) : (
                    <Text sx={{
                        padding: 20,
                        fontStyle: 'italic',
                        color: '#a6a6a7'
                    }}>No Available Files</Text>
                )}
            </View>
        </ScrollView>
    )
}