import QuotesScreen from '../screens/QuotesScreen';
import ProfileScreen from '../screens/ProfileScreen';
import SubscriptionsScreen from '../screens/SubscriptionsScreen';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { useDripsyTheme } from 'dripsy';
import { useMember } from "../hooks/useMember";
import { useConfig, AppTabs } from '../config/useConfig';
import TrainingNavigator from './TrainingNavigator';

const TopTabs = createMaterialTopTabNavigator()

export default function TopTabsNavigator() {
    const { trainingMode, setHeader } = useMember()
    const { theme } = useDripsyTheme()
    const { features } = useConfig()
    function tabActive(tab: keyof AppTabs): Boolean {
        if (features) {
            return features.Tabs[tab]?.Active ?? false
        }
        return false;
    }
    function displayHeader() {
        setHeader(!(['management'].includes(trainingMode) || ['instructor'].includes(trainingMode)));
    }
    return (
        <TopTabs.Navigator
            screenOptions={{
                tabBarStyle: { borderTopColor: '#F0F0F0', borderTopWidth: 1 },
                tabBarIndicatorStyle: { top: 0, backgroundColor: theme.colors.$highlight },
                swipeEnabled: false,
            }}
            sceneContainerStyle={{ backgroundColor: 'white' }}>
            {tabActive("Training") && <TopTabs.Screen
                name="Training"
                listeners={{ tabPress: () => { displayHeader() } }}
                component={TrainingNavigator} />}
            {tabActive("Quotes") && trainingMode != 'instructor' &&
                <TopTabs.Screen
                    name="Quotes"
                    component={QuotesScreen} />
            }
            {tabActive("Profile") && <TopTabs.Screen
                name="Profile"
                component={ProfileScreen} />}
            {tabActive("Subscriptions") && trainingMode != 'instructor' &&
                <TopTabs.Screen
                    name="Subscriptions"
                    component={SubscriptionsScreen} />
            }
        </TopTabs.Navigator>
    )
}

