import { useRestUtils } from './useRestUtils'

export function usePushNotificationsApi() {
    const { post } = useRestUtils()

    function postUpdateToken(token: string, platform: string): Promise<void> {
        return post("/members/pushtoken", {
            "Token": token,
            "Platform": platform
        })
    }

    return {
        postUpdateToken
    }
}
