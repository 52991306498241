import { useDripsyTheme } from 'dripsy';
import { Dimensions } from 'react-native';
import { View, H2, ScrollView } from './ui';
import { ReggieFile } from "../models/TrainingData";
import { WebViewer } from './WebViewer';

export function NotesView({ files, width, hideMainTitle }: { files: ReggieFile[], width?: string, hideMainTitle?: boolean}) {
    const { theme } = useDripsyTheme()
    const windowSize:any = Dimensions.get('window').height-200;
    let html = ""
    files.filter((file) => file.textTitle.length > 0).forEach( (file) =>
        html += `
            <h4>${file.textTitle}</h4>
            <span>${file.textContent}</span>
        `
    )
    return (
        <>
            {files.length > 0 && (
                <ScrollView sx={{ paddingBottom: theme.space.$5, paddingX: theme.space.$4, maxHeight: windowSize, minWidth: theme.sizes.labMinWidth, width: '100%'  }}>
                    {!hideMainTitle && <H2 sx={theme.styles.header}>Notes</H2>}
                    <View sx={{alignItems: 'flex-start'}}>
                        <WebViewer html={html} scrollEnabled={true}></WebViewer>
                    </View>
                </ScrollView>
            )}
        </>
    )
}