import { H2, Text, useDripsyTheme, View } from "dripsy"
import { Linking } from "react-native"
import { TrackGoogleAnalyticsEvent } from '../utils/GoogleAnalytics';

export default function SubscriptionsScreen() {
    const { theme } = useDripsyTheme()
    
    const ManageSubscriptions = () => {
        TrackGoogleAnalyticsEvent("manage_subscriptions", "ManageSubscription", "Changed email subscription preferences.", "xhr");
        Linking.openURL('https://www2.exitcertified.com/emailPreference/e/epc/49232/TZJGGPF2AF9i8QV04hu-ljYNDacoMz15EU0bu6E_wGs/641');
    }

    return (
        <View sx={{ flex: 1, padding: '$2', backgroundColor: '#fff' }}>
            <H2 sx={theme.styles.header}>Email Subscriptions</H2>
            <Text sx={{ color: '$primary' }}>
                Manage your email subscriptions
                <Text sx={{ fontWeight: 'bold', paddingLeft: '$1' }} onPress={() => ManageSubscriptions()}>here</Text>.
            </Text>
        </View>
    )
}