import { Text, View, ActivityIndicator } from '../../components/ui'
import { modalHeight, modalWidth } from '../../utils/Common'
import { Pressable } from "../ui/Pressable"
import { useDripsyTheme } from 'dripsy'
import { useRef } from "react"
import Modal from "react-native-modal"

interface HubModalProps {
    modalVisible: boolean
    modalLoading: boolean
    ModalContent: React.ReactElement | null | undefined
    setModalVisible: (visible: boolean) => void
    buttonText?: string
}

export default function HubModal(props: HubModalProps) {
    const { modalVisible, modalLoading, ModalContent, setModalVisible, buttonText } = props
    const modalRef = useRef<Modal>(null)
    const { theme } = useDripsyTheme()
    const resetModal = () => {
        // modalRef.current?.close()
        setModalVisible(false)
    }

    return (
        <Modal ref={modalRef} isVisible={modalVisible}>
            <View sx={{ alignItems: 'center' }}>
                <View sx={{ bg: '#fff', maxHeight: modalHeight(), width: modalWidth(), flexDirection: "column", justifyContent: "center", alignItems: "center", paddingY: '$2', borderRadius: 4 }}>
                    {ModalContent}
                    <View sx={{ flexDirection: 'row' }}>
                        {modalLoading ? (<ActivityIndicator sx={{ marginLeft: -32, marginRight: 12 }} size="small" color={theme.colors.$primary} />) : null}
                        <Pressable
                            onPress={resetModal}
                            version="buttons.gray" >
                            <Text sx={{ color: 'white' }}>{buttonText ? buttonText : 'Close'}</Text>
                        </Pressable>
                    </View>
                </View>
            </View>
        </Modal>
    )
}