import { createNativeStackNavigator } from '@react-navigation/native-stack';
import ForgotPasswordScreen from '../screens/ForgotPasswordScreen';
import LoginScreen from '../screens/LoginScreen';
import PushNotificationsScreen from '../screens/PushNotificationsScreen';
import SignupScreen from '../screens/SignupScreen';

const LoginStack = createNativeStackNavigator()
export default function LoginNavigator() {

    return (
        <LoginStack.Navigator screenOptions={{ headerShown: false }}>
            <LoginStack.Screen name="Login" component={LoginScreen} />
            <LoginStack.Screen name="PushNotification" component={PushNotificationsScreen} options={{}} />
            <LoginStack.Screen name="ForgotPassword" component={ForgotPasswordScreen} options={{ headerShown: false }} />
            <LoginStack.Screen name="Signup" component={SignupScreen} options={{ headerShown: false }} />
        </LoginStack.Navigator>
    )
}