import { View, Text, H2 } from '../components/ui'
import { Pressable } from "../components/ui/Pressable";
import { Platform } from 'react-native';
import { Checkbox } from "react-native-paper";
import Animated, { useSharedValue,useAnimatedGestureHandler, useAnimatedStyle, withTiming } from "react-native-reanimated";
import { PanGestureHandler, PanGestureHandlerGestureEvent } from 'react-native-gesture-handler';
import { useDripsyTheme } from "dripsy";
import { useMember } from "../hooks/useMember";
import LogOutButton from './login/LogOutButton';

export function Sidebar() {
    const { theme } = useDripsyTheme()
    const { member, trainingMode, updateTrainingMode } = useMember()
    
    const mgmtMode = (mode: string) => {
        var mMode = trainingMode === mode ? "default" : mode;
        updateTrainingMode(mMode)
        onExit()
    }
    
    const width = 200;
    const rotation = useSharedValue(0);
    const verticalOffset = useSharedValue(0);
    const opacity = useSharedValue(1);
    const x = useSharedValue(-width*1.5);
    
    const onPress = () => {
        x.value = withTiming((x.value == 0) ? -width*1.5 : 0, { duration: 200 });
        rotation.value = withTiming((x.value == 0) ? 0 : 45, { duration: 200 });
        opacity.value = withTiming((x.value == 0) ? 1 : 0, { duration: 200 });
        verticalOffset.value = withTiming((x.value == 0) ? 0 : -7, { duration: 200 });
    };

    const onExit = () => {
        x.value = withTiming(-width*1.5, { duration: 200 });
        rotation.value = withTiming(0, { duration: 200 });
        opacity.value = withTiming(1, { duration: 200 });
        verticalOffset.value = withTiming(0, { duration: 200 });
    };

    const panGestureHandler = useAnimatedGestureHandler<PanGestureHandlerGestureEvent,{ startProgress: number }>({
        onStart: (_, ctx) => {
            ctx.startProgress = x.value;
        },
        onActive: (event, ctx) => {
            var v = ctx.startProgress + event.translationX;
            if(v<0)x.value=v;
        },
        onEnd: () => {
            if (x.value > width) {
                x.value = withTiming(x.value);
            } else if (x.value < -width/4) {
                x.value = withTiming(-width*1.5);
                rotation.value = withTiming(0);
                opacity.value = withTiming(1);
                verticalOffset.value = withTiming(0);
            } else {
                x.value = withTiming(0);
                rotation.value = withTiming(45);
                opacity.value = withTiming(0);
                verticalOffset.value = withTiming(-7);
            }
        }
    });
    
    const padding = (Platform.OS === "android") ? 50 : (Platform.OS === "ios") ? 40 : 0;
    const boxShadow = Platform.select({
        ios: {
          shadowColor: 'rgba(0,0,0,.25)',
          shadowOffset: { height: 50, width: 2 },
          shadowOpacity: 1,
          shadowRadius: 10
        },
        android: {
          elevation: 2
        }
    });

    const boxStyle = useAnimatedStyle(() => {
        return {
            width: width,
            height: '100%',
            backgroundColor: "white",
            boxShadow: 'rgba(0, 0, 0, 0.33) 2px 13px 10px',
            position: 'absolute',
            left:0, top:50+padding, bottom: 0,
            zIndex: 1000,
            transform: [{ translateX: x.value }],
            ...boxShadow
        };
    });

    const buttonStyle = {
        position: (Platform.OS === "ios")?'fixed':'relative',
        top: (Platform.OS === "ios")?0:(Platform.OS === "android")?5:-44,
        zIndex: 1001,
        margin: 0,
        paddingX: 20,
        paddingY: 16,
        width: 60,
        backgroundColor: 'transparent'
    }

    const barStyle = {
        height: 2,
        width: 22,
        marginTop: 5,
        backgroundColor: 'gray'
    };
    const barStyle1 = useAnimatedStyle(() => {
        return {
            transform: [{ rotate: rotation.value+'deg' }],
            top:Math.abs(verticalOffset.value),
            ...barStyle
        };
    });
    const barStyle2 = useAnimatedStyle(() => {
        return {
            opacity: opacity.value,
            ...barStyle
        };
    });
    const barStyle3 = useAnimatedStyle(() => {
        return {
            transform: [{ rotate: -rotation.value+'deg' }],
            top:verticalOffset.value,
            ...barStyle
        };
    });

    const bgStyle = {
        position: "absolute",
        right:0,
        bottom:0,
        top:0,
        left:0,
        width: '100%',
        zIndex:999,
        backgroundColor: 'transparent'
    };
    const bgView = useAnimatedStyle(() => {
        return {
            display: (Platform.OS === "ios")?opacity.value:opacity.value==1?'none':'flex',
        };
    });

    const HubModes = () => {
        if (member && member.hubManagementModes.length > 0) {
            return (
                <>
                    <Text sx={{color: '$primary', fontSize: '$3', textAlign: 'center'}}>HUB Modes</Text>
                    <View sx={{alignItems: 'center'}}>
                        {member.hubManagementModes.map((mode) => (
                            <View key={mode} sx={{marginY: '$1', marginX: '$2', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}}>
                                <Checkbox.Android
                                    status={trainingMode === mode ? 'checked' : 'unchecked'}
                                    onPress={() => mgmtMode(mode)}
                                    color={theme.colors.$primary}
                                />
                                <Pressable onPress={() => mgmtMode(mode)} sx={{backgroundColor:'transparent', paddingX: 0, paddingY: 0, flexBasis: '85%'}}>
                                    <Text sx={{fontSize: '$1'}}>{mode.toUpperCase()}</Text>
                                </Pressable>
                            </View>
                        ))}
                    </View>
                </>
            )
        }

        return null
    }

    return (
        <>
            <Animated.View style={bgView}>
                <Pressable sx={bgStyle} onPress={onExit}></Pressable>
            </Animated.View>
            <View sx={{position: 'absolute', width: 0, zIndex:1000, height: '100%', paddingTop:40, overflow: 'visible', backgroundColor:'transparent'}}>
                <Pressable sx={buttonStyle} onPress={onPress}>
                    <Animated.View style={barStyle1} />
                    <Animated.View style={barStyle2} />
                    <Animated.View style={barStyle3} />
                </Pressable>
                <PanGestureHandler onGestureEvent={panGestureHandler}>
                    <Animated.View style={boxStyle}>
                        <H2 sx={{color: '$primary', textAlign: 'center', textTransform: 'uppercase'}}>Settings</H2>
                        <HubModes />
                        <LogOutButton />
                    </Animated.View>
                </PanGestureHandler>
            </View>
        </>
    );
};