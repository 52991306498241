import { useEffect, useRef, useState } from 'react';
import { useMember } from '../../hooks/useMember';
import { View, Text, H2, H3, ActivityIndicator, ScrollView } from '../ui';
import { LabPlatform, TestConnection } from '../../models/TestConnection';
import { useConfig } from '../../config/useConfig';
import { Platform } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { Pressable } from '../ui/Pressable';
import { useTraining } from '../../hooks/useTraining';
import { useDripsyTheme, Pressable as NativePressable } from 'dripsy';
import { Dimensions, ScrollView as nScrollView } from 'react-native';
import BrandEmailLink from "../ui/BrandEmailLink";
import ContactInfo from '../ui/ContactInfo';
import { WebViewer } from "../WebViewer";
interface TestConnectionViewProps {
    opportunityId: string
    platform?: string
}

export default function TestConnectionView(props: TestConnectionViewProps) {
    const { opportunityId, platform } = props
    const { getTestConnectionDetails } = useMember()
    const { updateOpp } = useTraining()
    const { brand, webBase } = useConfig()
    const [testConnectionDetails, setTestConnectionDetails] = useState<TestConnection>()
    const [errorString, setErrorString] = useState("")
    const [loading, setLoading] = useState(false)
    const [classQuestionAnswered, setClassQuestionAnswered] = useState(false)
    const [classQuestionOpen, setClassQuestionOpen] = useState(false)
    const [labQuestionOpen, setLabQuestionOpen] = useState(false)
    const [showHelpText, setShowHelpText] = useState(false)
    const { theme } = useDripsyTheme()
    const scrollRef = useRef<nScrollView>(null)
    const windowSize: any = Dimensions.get('window').height - 200;

    useEffect(() => {

        async function loadAsync() {
            try {
                setLoading(true)
                const testDetails = await getTestConnectionDetails(opportunityId, platform)

                if (!testDetails.platform) { // if we have no platform, lets assume it is answered
                    setClassQuestionAnswered(true)
                }

                setTestConnectionDetails(testDetails)

                if (testDetails && (testDetails.platform || testDetails.labs.length > 0)) {
                    if (testDetails.platform) {
                        setClassQuestionOpen(true);
                    } else if (testDetails.labs.length > 0) {
                        setLabQuestionOpen(true);
                    }
                }
            } catch (err: any) {
                setErrorString(err.toString())
            } finally {
                setLoading(false)
            }
        }

        loadAsync()
    }, [])

    useEffect(() => {
        if (testConnectionDetails && classQuestionAnswered && areLabsCompleted() && !showHelpText) {
            updateOpp(opportunityId, 'SetupStatusOk', new Date(Date.now()).toISOString());
            updateOpp(opportunityId, "SetupStatus", 'Setup Completed - Web')
        }
    }, [classQuestionAnswered, areLabsCompleted()])

    function handleUpdateOpp(updateKey: string, updateVal: string, message?: string, eventNum?: number) {
        if (!testConnectionDetails) {
            return
        }

        let hasIssue = false
        if (['SetupStatusNoClass', 'SetupStatusNoLab'].includes(updateKey)) {
            setShowHelpText(true)
            scrollRef.current?.scrollTo({
                y: 0,
                animated: true
            })
            hasIssue = true
        } else {
            setShowHelpText(false)
        }

        if (['ClassPassed', 'SetupStatusNoClass'].includes(updateKey)) {
            setClassQuestionAnswered(true)
            if (!hasIssue) {
                setClassQuestionOpen(false)
                setLabQuestionOpen(true)
            }
        } else {
            if (eventNum !== undefined && eventNum >= 0) {
                testConnectionDetails.labs[eventNum].answered = true
            }

            if (!hasIssue && areLabsCompleted()) {
                setLabQuestionOpen(false)
            }
        }

        updateOpp(opportunityId, updateKey, updateVal)
        if (message) {
            updateOpp(opportunityId, "SetupStatus", message)
        }
    }

    function areLabsCompleted() {
        if (testConnectionDetails) {
            for (const k in testConnectionDetails.labs) {
                const lab = testConnectionDetails.labs[k]

                if (!lab.answered) {
                    return false
                }
            }
        }
        return true
    }

    if (errorString.length > 0) {
        return (
            <View sx={{ paddingY: '$5', paddingX: '$3', maxHeight: windowSize }}>
                <Text>{errorString}</Text>
                <Text>Please contact <BrandEmailLink style={{ color: theme.colors.$primary }} username={brand === "exitcertified" ? "livevirtual" : "support"} /> or call 1-844-275-4687</Text>
            </View>
        )
    }

    const HelpText = () => {
        return (
            <View sx={{ overflowY: 'auto', paddingY: '$5', paddingX: '$3', maxHeight: windowSize }}>
                <Text sx={{ fontSize: '$3', fontWeight: 'bold', marginBottom: '$2' }}>Having trouble completing the setup?</Text>
                <ContactInfo />
            </View>
        )
    }

    const LabPlatformButtons = ({ isLab, eventNum }: { isLab: boolean | undefined, eventNum?: number }) => {
        return (
            <View sx={{ flexDirection: 'row' }}>
                <Pressable
                    onPress={() => { isLab ? (handleUpdateOpp('LabPassed', new Date(Date.now()).toISOString(), undefined, eventNum)) : (handleUpdateOpp('ClassPassed', new Date(Date.now()).toISOString())) }}
                    version="buttons.primary"
                >
                    <Text sx={{ color: 'white' }}>Yes</Text>
                </Pressable>
                <Pressable
                    onPress={() => { isLab ? (handleUpdateOpp('SetupStatusNoLab', new Date(Date.now()).toISOString(), 'Lab Setup Issue')) : (handleUpdateOpp('SetupStatusNoClass', new Date(Date.now()).toISOString(), 'Classroom Setup Issue')) }}
                    version="buttons.gray"
                >
                    <Text sx={{ color: 'white' }}>No</Text>
                </Pressable>
            </View>
        )
    }

    const LabPlatform = ({ labPlatform, isLab, index }: { labPlatform: LabPlatform, isLab?: boolean, index?: number }) => {
        return (
            <>
                {Platform.OS === 'web' ? (
                    <>
                        <Text sx={{ fontSize: '$4', fontWeight: 'bold', color: '$gray', backgroundColor: '$mediumgray', padding: '$2' }}>{isLab ? "Lab" : "Classroom"} {index ? index + 1 : 1}</Text>
                        <View sx={{ backgroundColor: '$gray', borderColor: '$mediumgray', borderWidth: 1, marginBottom: '$2', padding: '$2', opacity: labPlatform.answered ? 0.5 : 1 }}>
                            <div style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: labPlatform.default }} />
                            {labPlatform.windows && labPlatform.windows.length > 0 &&
                                <>
                                    <H3>Windows:</H3>
                                    <div style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: labPlatform.windows }} />
                                </>
                            }
                            {labPlatform.mac && labPlatform.mac.length > 0 &&
                                <>
                                    <H3>Mac:</H3>
                                    <div style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: labPlatform.mac }} />
                                </>

                            }
                            {labPlatform.linux && labPlatform.linux.length > 0 &&
                                <>
                                    <H3>Linux:</H3>
                                    <div style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: labPlatform.linux }} />
                                </>
                            }

                            {!labPlatform.hideCompleteButtons && !labPlatform.answered &&
                                <LabPlatformButtons isLab={isLab} eventNum={index} />
                            }
                        </View>
                    </>
                ) : (
                    <>
                        <WebViewer html={labPlatform.default}></WebViewer>
                        {labPlatform.windows && labPlatform.windows.length > 0 &&
                            <>
                                <H3>Windows:</H3>
                                <WebViewer html={labPlatform.windows}></WebViewer>
                            </>
                        }
                        {labPlatform.mac && labPlatform.mac.length > 0 &&
                            <>
                                <H3>Mac:</H3>
                                <WebViewer html={labPlatform.mac}></WebViewer>
                            </>
                        }
                        {labPlatform.linux && labPlatform.linux.length > 0 &&
                            <>
                                <H3>Linux:</H3>
                                <WebViewer html={labPlatform.linux}></WebViewer>
                            </>
                        }
                    </>
                )}
            </>
        )
    }

    return (
        <ScrollView innerRef={scrollRef} sx={{ paddingBottom: '$5', paddingX: '$3', minHeight: 300, maxHeight: windowSize }}>
            {loading ? (
                <ActivityIndicator sx={{ marginTop: '$7' }} size="large" color="$primary" />
            ) : (
                <>
                    {showHelpText && <HelpText />}

                    {testConnectionDetails && classQuestionAnswered && areLabsCompleted() && !showHelpText ? (
                        <View>
                            <H2>Congratulations! You have completed your Class Connection setup!</H2>
                            <Text>
                                Please close your classroom and/or lab connections.
                                Prior to class, we will email you class connection details.
                                If you have any questions, please feel free contact us.
                            </Text>
                            <Text>See you soon!</Text>
                        </View>
                    ) : (
                        <>
                            {testConnectionDetails && testConnectionDetails.platform && (
                                <View sx={{ opacity: classQuestionAnswered ? 0.5 : 1 }}>
                                    <NativePressable style={{ flexDirection: 'row', alignItems: 'center' }} onPress={() => setClassQuestionOpen(!classQuestionOpen)}>
                                        <H2 sx={{ marginRight: '$2' }}>Classroom Connection</H2>
                                        <AntDesign name={classQuestionOpen ? "caretup" : "caretdown"} size={24} color={theme.colors.$primary} />
                                    </NativePressable>
                                    {classQuestionOpen && <LabPlatform labPlatform={testConnectionDetails.platform} />}
                                </View>
                            )}

                            {testConnectionDetails && testConnectionDetails.labs.length > 0 && (
                                <>
                                    <NativePressable style={{ flexDirection: 'row', alignItems: 'center' }} onPress={() => setLabQuestionOpen(!labQuestionOpen)}>
                                        <H2 sx={{ marginRight: '$2' }}>Lab Connection</H2>
                                        <AntDesign name={labQuestionOpen ? "caretup" : "caretdown"} size={24} color={theme.colors.$primary} />
                                    </NativePressable>
                                    {labQuestionOpen &&
                                        <>
                                            {testConnectionDetails.labs.map((lab, i) => (
                                                <LabPlatform key={i} labPlatform={lab} isLab={true} index={i} />
                                            ))}
                                        </>
                                    }
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </ScrollView>
    )
}