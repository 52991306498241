import AsyncStorage from '@react-native-async-storage/async-storage'

type KeyType = string

class SyncStorage {
    data: Map<any, any> = new Map()

    loading: boolean = true

    async init(): Promise<Array<any>> {
        const keys = await AsyncStorage.getAllKeys()
        const valuePairs = await AsyncStorage.multiGet(keys)

        valuePairs.forEach(valuePair => {
            this.saveItem(valuePair)
        })

        return Object.fromEntries(this.data)
        // return [...this.data]
    }

    get(key: KeyType): any {
        return this.data.get(key)
    }

    set(key: KeyType, value: any): Promise<any> {
        if (!key) return this.handleError('set', 'a key')

        this.data.set(key, value)
        return AsyncStorage.setItem(key, JSON.stringify(value))
    }

    remove(key: KeyType): Promise<any> {
        if (!key) return this.handleError('remove', 'a key')

        this.data.delete(key)
        return AsyncStorage.removeItem(key)
    }

    saveItem(item: [string, string | null]) {
        let value

        try {
            value = item[1] ? JSON.parse(item[1]) : null
        } catch (e) {
            [, value] = item
        }

        this.data.set(item[0], value)
        this.loading = false
    }

    getAllKeys(): Array<any> {
        return Array.from(this.data.keys())
    }

    handleError(func: string, param?: string): Promise<string> {
        let message
        if (!param) {
            message = func
        } else {
            message = `${func}() requires at least ${param} as its first parameter.`
        }
        console.warn(message) // eslint-disable-line no-console
        return Promise.reject(message)
    }
}

const storage = new SyncStorage()

export default storage