import { Pressable } from "./Pressable"
import { Text } from '../ui'
import { emailDomain } from "../../utils/Common"
import { Linking } from "react-native"

interface BrandEmailLinkProps {
    username: string
    style: Object
}

export default function BrandEmailLink(props: BrandEmailLinkProps) {
    const { username, style } = props
    const domainName = emailDomain()

    const shouldStartLoadWithRequest = (req: any) => {
        if(req.url != 'about:blank') {
            Linking.openURL(req.url);
        }
    };

    return (
        <Pressable sx={{backgroundColor: 'transparent', paddingTop: 0,paddingLeft: 0,paddingRight: 0,paddingBottom: 0,fontSize: 14,margin: 0}} onPress={() => { shouldStartLoadWithRequest({url:`mailto:${username}@${domainName}`}) }}>
            <Text sx={{textDecorationLine: 'underline', ...style}}>{username}@{domainName}</Text>
        </Pressable>
    )
}