import { createNativeStackNavigator } from "@react-navigation/native-stack"
import GetStartedScreen from "../screens/GetStartedScreen"
import { UtilityParamList } from "./types"

const GetStartedStack = createNativeStackNavigator<UtilityParamList>()
export default function GetStartedNavigator() {

    return (
        <GetStartedStack.Navigator screenOptions={{ headerShown: false }}>
            <GetStartedStack.Screen name="GetStarted" component={GetStartedScreen} />
        </GetStartedStack.Navigator>
    )
}