import { useDripsyTheme } from 'dripsy';
import { useMediaQuery } from "react-responsive";

export function CalendarStylesheet() {
    const { theme } = useDripsyTheme()
    const isMobile = useMediaQuery({ query: '(max-width: 960px)' });
    const infoBoxHeight = isMobile ? 290 : 340;
    return `
        .react-calendar {
            width: 650px;
            max-width: 100%;
            background: `+ theme.colors.$gray + `;
            font-family: Arial, Helvetica, sans-serif;
            line-height: 1.125em;
            padding-bottom: 35px;
            padding-top: 0px;
        }

        .react-calendar__navigation__label span {
            font-size: 19px;
            font-weight: 700;
            text-transform: uppercase;
            color: #6a7683;
            top: 9px;
            position: relative;
        }

        .react-calendar__navigation__next2-button,
        .react-calendar__navigation__prev2-button {
            display: none;
        }

        .react-calendar__navigation__prev-button {
            position: relative;
            left: calc(50% - 150px);
            top: 5px;
            background: none;
        }

        .react-calendar__navigation__next-button {
            position: relative;
            right: calc(50% - 150px);
            top: 5px;
            background: none;
        }

        .react-calendar__navigation__arrow {
            font-size: 29px;
            color: `+ theme.colors.$primary + `;
        }

        .react-calendar__tile--active {
            color: white;
            background: none;
        }

        .react-calendar__month-view__days__day {
            height: 50px;
        }

        .react-calendar__month-view__days__day abbr {
            font-weight: 600;
        }

        .react-calendar__tile--now abbr {
            background: #e3e3e3;
            padding: 11px;
            border-radius: 100px;
            font-weight: 600;
            min-width: 40px !important;
            display: inline-block;
            top: -5px;
            position: relative;
        }

        .react-calendar__tile--active abbr {
            background: `+ theme.colors.$primary + `;
            padding: 11px;
            border-radius: 100px;
            font-weight: 600;
            min-width: 40px !important;
            display: inline-block;
            top: -5px;
            position: relative;
            color: white;
        }

        .react-calendar__tile--hasActive abbr,
        .react-calendar__tile--hasActive abbr {
            background: `+ theme.colors.$primary + `;
            color: white;
            padding: 11px;
            border-radius: 100px;
            font-weight: 600;
            min-width: 40px !important;
            display: inline-block;
            top: -5px;
            position: relative;
        }

        .react-calendar--doubleView {
            width: 700px;
        }

        .react-calendar--doubleView .react-calendar__viewContainer {
            display: flex;
            margin: -0.5em;
        }

        .react-calendar--doubleView .react-calendar__viewContainer>* {
            width: 50%;
            margin: 0.5em;
        }

        .react-calendar,
        .react-calendar *,
        .react-calendar *:before,
        .react-calendar *:after {
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
        }

        .react-calendar button {
            margin: 0;
            border: 0;
            outline: none;
        }

        .react-calendar button:enabled:hover {
            cursor: pointer;
        }

        .react-calendar__navigation {
            display: flex;
            height: 44px;
            margin-bottom: 25px;
        }

        .react-calendar__navigation button {
            min-width: 44px;
            background: none;
        }

        .react-calendar__navigation button:disabled {
            background-color: #f0f0f0;
        }

        .react-calendar__month-view__weekdays {
            text-align: center;
            text-transform: uppercase;
            font: inherit;
            font-size: 0.75em;
            font-weight: bold;
            margin-bottom: 25px;
        }

        .react-calendar__month-view__weekdays__weekday {
            padding: 0.5em;
        }

        .react-calendar__month-view__weekdays__weekday abbr {
            text-decoration: none;
            color: #3e444c;
            font-size: 15px;
            font-weight: 500;
        }

        .react-calendar__month-view__weekNumbers .react-calendar__tile {
            display: flex;
            align-items: center;
            justify-content: center;
            font: inherit;
            font-size: 0.75em;
            font-weight: bold;
        }

        .react-calendar__month-view__days__day--neighboringMonth,
        .react-calendar__decade-view__years__year--neighboringDecade,
        .react-calendar__century-view__decades__decade--neighboringCentury {
            color: #757575;
        }

        .react-calendar__century-view__decades__decade,
        .react-calendar__decade-view__years__year {
            font-weight: bold !important;
        }

        .react-calendar__year-view .react-calendar__tile,
        .react-calendar__decade-view .react-calendar__tile,
        .react-calendar__century-view .react-calendar__tile {
            padding: 2em 0.5em;
        }

        .react-calendar__year-view__months__month abbr {
            font-weight: 600;
        }
        
        .react-calendar__year-view__months__month div[data-identifier="has_entries"] {
            display: none;
        }

        .react-calendar__tile {
            position: relative;
            max-width: 100%;
            padding: 10px 6.6667px;
            background: none;
            text-align: center;
            line-height: 16px;
            font: inherit;
            font-size: 0.833em;
        }

        .react-calendar__tile abbr {
            padding: 11px;
        }

        .react-calendar__tile:disabled {
            background-color: #f0f0f0;
            color: #ababab;
        }

        .react-calendar__month-view__days__day--neighboringMonth:disabled,
        .react-calendar__decade-view__years__year--neighboringDecade:disabled,
        .react-calendar__century-view__decades__decade--neighboringCentury:disabled {
            color: #cdcdcd;
        }

        .react-calendar--selectRange .react-calendar__tile--hover {
            background-color: #e6e6e6;
        }

        [data-testid="calendarItemList"] {
            height: `+ infoBoxHeight + `px;
            margin-right: 4px;
        }

        [data-testid="calendarItemList"]::-webkit-scrollbar {
            background: transparent;
            height: 8px;
            width: 6px;
        }

        [data-testid="calendarItemList"]::-webkit-scrollbar-thumb {
            border-radius: 0.25rem;
            background: rgb(106, 119, 131);
        }

        [data-testid="calendarItemList"]::-webkit-scrollbar-track {
            background: transparent;
        }
    `;

}