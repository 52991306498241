import FormTextInput from "./FormTextInput"
import { useState } from "react"
import { Picker } from '@react-native-picker/picker'
import { AddressTypes } from "../../../models/Member"
import { useDripsyTheme } from "dripsy"
import { useQuery } from "@tanstack/react-query"
import { useMemberApi } from "../../../api/useMemberApi"

type CountryStateSelectorProps = {
    selectedCountry: string
    selectedState: string
    prefix?: keyof AddressTypes
    onSelectState: (state: string, prefix?: keyof AddressTypes) => void
    onSelectCountry: (state: string, prefix?: keyof AddressTypes) => void
}

export default function CountryStateSelector(props: CountryStateSelectorProps) {
    const { selectedCountry, selectedState, prefix } = props
    const [ stateProv, setStateProv ] = useState<string>("")
    const [ country, setCountry ] = useState<string>("")
    const { theme } = useDripsyTheme()

    const { getCountries, getStates } = useMemberApi()

    const countriesQuery = useQuery({ queryKey: ['countries'], queryFn: () => getCountries(), staleTime: Infinity })
    const countries = countriesQuery.data

    const selectorCountry = country ? country : selectedCountry

    const statesQuery = useQuery({ queryKey: ['states', selectorCountry], queryFn: () => getStates(selectorCountry), enabled: selectorCountry !== undefined, staleTime: Infinity })
    let states = null
    if (statesQuery.data) {
        states = Object.entries(statesQuery.data)
    }

    const onSelectedCountry = async (country: string) => {
        setCountry(country)
        props.onSelectCountry(country, prefix)
    }

    const onSelectedState = (state: string) => {
        setStateProv(state)
        props.onSelectState(state, prefix)
    }

    return (
        <>
            <Picker
                selectedValue={country ? country : selectedCountry}
                onValueChange={(itemValue, itemIndex) => {
                    onSelectedCountry(itemValue)
                }}
                style={{ backgroundColor: theme.colors.$gray, padding: theme.space.$2, margin: theme.space.$2, width: '96%', lineHeight: 24 }}
            >
                <Picker.Item key='' label="-- Select Country --" value={''} enabled={true} />
                {countries && Object.entries(countries).map(([val, label], index) => (
                    <Picker.Item key={index} label={label} value={val} />
                ))}
            </Picker>

            {states && states.length > 0 ? (
                <Picker
                    selectedValue={stateProv ? stateProv : selectedState}
                    onValueChange={(itemValue, itemIndex) => {
                        onSelectedState(itemValue)
                    }}
                    style={{ backgroundColor: theme.colors.$gray, padding: theme.space.$2, margin: theme.space.$2, width: '96%', lineHeight: 24 }}
                >
                    <Picker.Item key='' label="-- Select State/Province --" value={''} enabled={true} />
                    {states.map(([val, label], index) => (
                        <Picker.Item key={index} label={label} value={val} />
                    ))}
                </Picker>
            ) : (
                <FormTextInput
                    name="state"
                    label="State"
                    value={stateProv ? stateProv : selectedState}
                    onChangeText={(state) => onSelectedState(state)}
                    rules={{
                        required: "State Required"
                    }}
                />
            )}
        </>
    )
}