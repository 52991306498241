import { H2, Text, View, ScrollView } from './';
import { useDripsyTheme } from 'dripsy';
import { Dimensions, StyleSheet, TextInput } from 'react-native';
import { Pressable } from "./Pressable";
import { useMember } from "../../hooks/useMember";
import FilterIconSolid from "svg/icon-filter-solid.svg";
import HubModal from './HubModal';
import { useEffect, useState, useContext, useRef } from "react"
import { useMediaQuery } from "react-responsive";
import { useForm } from "react-hook-form";
import { TrainingInstructorDataContext } from "../../providers/TrainingInstructorProvider";
import { TrainingMgmtDataContext } from "../../providers/TrainingMgmtProvider";
import DateTimePicker from 'react-native-ui-datepicker';

interface FormValues {
    startDateTime: Date,
    endDateTime: Date,
    startDate: string,
    startTime: string,
    endDate: string,
    endTime: string,
    show: string
}

export default function TrainingFilters() {
    const { refreshTrainingInstructorData } = useContext(TrainingInstructorDataContext)
    const { refreshTrainingMgmtData } = useContext(TrainingMgmtDataContext)
    const { member, trainingMode } = useMember()
    const { theme } = useDripsyTheme()
    const [showFilters, setShowFilters] = useState(false)
    const { ...methods } = useForm<FormValues>({ mode: 'onBlur' })
    const windowSize: any = Dimensions.get('window').height - 200;
    const isMobile = useMediaQuery({ query: '(max-width: 400px)' })

    const styles = StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: '#F5FCFF',
            selectedItemColor: theme.colors.$primary
        },
    });

    const Filters = () => {
        const startDate = getStartDateTime();
        const endDate = getEndDateTime();

        return (
            <>
                <ScrollView sx={{
                    paddingBottom: theme.space.$5,
                    paddingX: 60,
                    maxHeight: windowSize,
                    minWidth: theme.sizes.labMinWidth,
                    width: '100%'
                }}>
                    <H2 sx={theme.styles.header}>Training Filters</H2>
                    <View sx={{ alignItems: 'flex-start' }}>
                        <View sx={{ width: '100%', display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
                            <View sx={{ marginRight: isMobile ? 0 : '2%', marginBottom: isMobile ? 20 : 0, width: isMobile ? '100%' : '49%' }}>
                                <DatePicker
                                    label="Start Date"
                                    value={startDate}
                                    onChange={(date: string) => setStartDate(date)}
                                />
                            </View>
                            <View sx={{ width: isMobile ? '100%' : '49%' }}>
                                <DatePicker
                                    label="End Date"
                                    value={endDate}
                                    onChange={(date: string) => setEndDate(date)}
                                />
                            </View>
                        </View>
                    </View>
                </ScrollView>
                <View sx={{ position: 'absolute', bottom: 8, flexFlow: 'row', justifyContent: 'flex-end' }}>
                    <Pressable version='buttons.gray' sx={{ marginRight: 85 }} onPress={() => reset()}>
                        <Text sx={{ color: 'white' }}>Reset</Text>
                    </Pressable>
                    <Pressable version='buttons.primary' onPress={() => refreshTraining()}>
                        <Text sx={{ color: 'white' }}>Apply</Text>
                    </Pressable>
                </View>
            </>
        );
    }

    const getStartDateTime = () => {
        var startDate = methods.getValues('startDate');
        var d = startDate ? new Date(startDate) : new Date();
        return d;
    }
    const getEndDateTime = () => {
        var endDate = methods.getValues('endDate');
        var d = endDate ? new Date(endDate) : new Date();
        return d;
    }

    const setStartDate = (newValue: string) => {
        var date = new Date(newValue);
        methods.setValue('startDate', date.toLocaleDateString());
    }
    const setEndDate = (newValue: string) => {
        var date = new Date(newValue);
        methods.setValue('endDate', date.toLocaleDateString());
    }

    const reset = () => {
        if (['management'].includes(trainingMode)) {
            refreshTrainingMgmtData()
        } else if (['instructor'].includes(trainingMode)) {
            refreshTrainingInstructorData();
        }
        setShowFilters(false);
    }

    const refreshTraining = () => {

        var Start = methods.getValues('startDate');
        var End = methods.getValues('endDate') ? methods.getValues('endDate') : new Date().toLocaleDateString();
        if ((Start && End) && (Start != End)) {
            if (['management'].includes(trainingMode)) {
                refreshTrainingMgmtData(Start, End)
            } else if (['instructor'].includes(trainingMode)) {
                refreshTrainingInstructorData(Start, End);
            }
        }

        setShowFilters(false);
    }

    return (
        <>
            <Pressable onPress={() => setShowFilters(true)}
                sx={{ paddingX: isMobile ? 7 : '$3', width: 95, paddingY: 10, bg: '$background', flexDirection: 'row', alignItems: 'center' }}>
                <FilterIconSolid fill={theme.colors.$primary} width={20} height={20} />
                <Text sx={{ color: '$primary', fontWeight: 'bold', marginLeft: '$2' }}>Filters</Text>
            </Pressable>
            <HubModal
                modalLoading={false}
                modalVisible={showFilters}
                ModalContent={<Filters />}
                setModalVisible={setShowFilters}
            />
        </>
    )

}

const DateLabel = ({ label, value, onChange }: { label?: string, value?: string, onChange: Function }) => {
    const { theme } = useDripsyTheme()
    const [textValue, setTextValue] = useState("");

    return (
        <>
            <Text sx={{ color: theme.colors.$primary, fontWeight: 'bold' }}>
                {label}
            </Text>
            <TextInput
                style={{ backgroundColor: theme.colors.$gray, padding: theme.space.$2, marginVertical: theme.space.$2, width: '100%' }}
                value={textValue ? textValue : value}
                onChangeText={(state: any) => {
                    setTextValue(state)
                    onChange(state)
                }}
            />
        </>
    )
}

const DatePicker = ({ label, value, onChange }: { label?: string, value?: Date, onChange: (value: string) => void }) => {
    const { theme } = useDripsyTheme()
    const [date, setDate] = useState<Date>()

    return (
        <>
            <DateLabel
                label={label}
                value={date ? date.toLocaleDateString() : value ? value.toLocaleDateString() : 'Select Date'}
                onChange={(value: string) => {
                    var pattern = /^(?:(?:(?:0?[13578]|1[02])(\/|-|\.)31)\1|(?:(?:0?[1,3-9]|1[0-2])(\/|-|\.)(?:29|30)\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:0?2(\/|-|\.)29\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:(?:0?[1-9])|(?:1[0-2]))(\/|-|\.)(?:0?[1-9]|1\d|2[0-8])\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
                    if (pattern.test(value)) {
                        var date = new Date(value);
                        onChange(value);
                        setDate(date)
                    }
                }}
            />

            <View sx={{}}>
                <DateTimePicker
                    mode="single"
                    headerButtonColor={theme.colors.$primary}
                    selectedTextStyle={{
                        fontWeight: 'bold',
                    }}
                    headerTextStyle={{
                        color: theme.colors.$primary
                    }}
                    selectedItemColor={theme.colors.$primary}
                    date={date ? date : value ? value : null}
                    onChange={(params: any) => {
                        onChange(params.date.$d);
                        setDate(params.date.$d)
                    }}
                />
            </View>
        </>
    )
}