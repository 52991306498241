import { useDripsyTheme } from 'dripsy';
import { View, H2, Text, ScrollView, ActivityIndicator } from "./"
import Calendar from 'react-calendar'
import { useMediaQuery } from "react-responsive";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { useNavigation } from "@react-navigation/native";
import type { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { TrainingListItem } from "../../models/TrainingData";
import { CalendarStylesheet } from "../../utils/CalendarStylesheet";
import { useConfig } from "../../config/useConfig";
import { useTraining } from "../../hooks/useTraining";
import CalendarListSelector from '../../components/ui/CalendarListSelector';

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

interface CalendarButtonProps {
    windowSelection?: (selection: string) => void
}

export default function TrainingCalendar(props: CalendarButtonProps) {
    const { windowSelection } = props
    const { loading, trainingCalendar, getTrainingCalendarEvents } = useTraining()
    const { theme } = useDripsyTheme()
    const [value, onChange] = useState<Value>(new Date());
    const [dateString, setDateString] = useState<String>('');
    const navigation = useNavigation<NativeStackNavigationProp<any>>()
    const isMobile = useMediaQuery({ query: '(max-width: 960px)' })

    useEffect(() => {
        var now = new Date();
        setDateString(format(now, 'MMMM d, y'));
        async function loadAsync() {
            try {
                if (!trainingCalendar) {
                    await getTrainingCalendarEvents()
                }
            } catch (err: any) {
                console.warn(err.toString())
            } finally {

            }
        }
        loadAsync();
    }, [])

    const dateChange = (date: Value) => {
        if (date) {
            var selection = new Date(date?.toString());
            var formatted = format(selection, 'MMMM d, y');
            setDateString(formatted);
            onChange(date);
        }
    }

    const eventInDateRange = (event: TrainingListItem) => {
        const start = new Date(event.startDate + ' 12:00 AM');
        const end = new Date(event.endDate + ' 12:00 AM');
        var selection = new Date(dateString.toString());
        if (selection.valueOf() >= start.valueOf() && selection.valueOf() <= end.valueOf()) {
            return event;
        }
    }

    const goToEvent = (training: TrainingListItem) => {
        if (training.program) {
            navigation.navigate('TrainingProgram', { id: training.parentEventId });
        } else {
            if (training.reggieId > 0) {
                navigation.navigate('TrainingDetails', { id: training.reggieId });
            } else if (training.resellId > 0) {
                navigation.navigate('TrainingDetails', { id: `r${training.resellId}` });
            }
        }
    }

    const tileContent = (date: any) => {
        if (trainingCalendar) {
            const hasEvent = trainingCalendar.some((event) => {
                const start = new Date(event.startDate + ' 12:00 AM');
                const end = new Date(event.endDate + ' 12:00 AM');
                const day = new Date(date.date);
                return day >= start && day <= end;
            });
            return (hasEvent && <div data-identifier="has_entries" style={{ padding: 2, position: 'absolute', top: 4, right: 0, bottom: 0, left: 26, zIndex: 1, borderRadius: 100, width: 40, height: 40, border: '2px solid ' + theme.colors.$primary }} />);
        }
    };

    return (
        <>
            <style><CalendarStylesheet /></style>
            {(loading || !trainingCalendar) ? (
                <View sx={{ backgroundColor: 'white', height: '100%' }}>
                    <ActivityIndicator sx={{ marginTop: '$4' }} size="large" color="$primary" />
                </View>
            ) : (
                <ScrollView sx={{ backgroundColor: 'white', height: '100%', overflowX: 'visible' }}>
                    <View sx={theme.layout.view}>
                        {windowSelection && <CalendarListSelector initalState="TrainingCalendar" pageState={windowSelection} />}
                    </View>
                    <View sx={{ marginTop: 50, display: 'flex', flexDirection: isMobile ? 'column' : 'row', margin: 'auto', marginBottom: 35 }}>
                        <Calendar onChange={dateChange} tileContent={tileContent} value={value} />
                        <View sx={{ backgroundColor: '#3e444c', width: isMobile ? '100%' : 300 }}>
                            <H2 sx={{ color: '#ffffff', textAlign: 'center', fontSize: 17, fontWeight: 400, marginTop: 18 }}>{dateString}</H2>
                            <View sx={{ backgroundColor: '#6a7783', borderRadius: 10, height: 5, width: 37, margin: 'auto', marginTop: 0, marginBottom: 20 }}></View>
                            <ScrollView testID="calendarItemList">
                                {trainingCalendar && trainingCalendar.length > 0 && trainingCalendar.filter((event) => eventInDateRange(event)).map((event, index) => (
                                    <View key={index} sx={{ marginTop: 0, marginBottom: 25, marginLeft: 25, marginRight: 25 }}>
                                        <Text onPress={() => { goToEvent(event) }} sx={{ color: '#ffffff', lineHeight: 23, textDecorationLine: 'underline', marginBottom: 12, fontSize: 16 }}>
                                            {event.name}
                                        </Text>
                                        <Text sx={{ color: '#ffffff', marginBottom: 18, lineHeight: 22, fontSize: 15 }}>
                                            {event.startDate} - {event.endDate}<br />
                                            {event.time}<br />{event.location && 'Location: ' + event.location}
                                        </Text>
                                    </View>
                                ))}
                            </ScrollView>
                        </View>
                    </View>
                </ScrollView >
            )}
        </>
    )
}