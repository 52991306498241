import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import MemberDataProvider from "./providers/MemberDataProvider"
import MemberProvider from "./providers/MemberProvider"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { useConfig } from "./config/useConfig"

interface AppProviderProps {
    children: React.ReactNode
}

const queryClient = new QueryClient()

function AppProvider({ children }: AppProviderProps) {
    const config = useConfig()
    return (
        <QueryClientProvider client={queryClient}>
            <MemberDataProvider>
                <MemberProvider>
                    {children}
                </MemberProvider>
            </MemberDataProvider>
            {config.env !== 'production' && <ReactQueryDevtools initialIsOpen={false} />}
        </QueryClientProvider>
    )
}

export default AppProvider