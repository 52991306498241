import { makeTheme } from "dripsy";
import { getEnvVars } from "./config/useConfig";
const envVars = getEnvVars()
const Theme = makeTheme({
    types: {
        strictVariants: false
    },
    colors: {
        $text: 'rgba(0,0,0,0.87)',
        $background: '#fff',
        $primary: envVars.appTheme && envVars.appTheme.primaryColor.length > 0 ? envVars.appTheme.primaryColor : '#00558C',
        $secondary: '#00B1E2',
        $error: '#F44336',
        $success: '#5cb85c',
        $highlight: envVars.appTheme && envVars.appTheme.highlightColor.length > 0 ? envVars.appTheme.highlightColor : '#CCD814',
        $highlightText: envVars.appTheme && envVars.appTheme.highlightTextColor.length > 0 ? envVars.appTheme.highlightTextColor : '#00558c',
        $gray: '#ececec',
        $mediumgray: '#b3b3b3',
        $darkgray: '#8c8c8c',
        $orange: '#ffa039'
    },
    space: {
        // recommended: set 0 first, then double for consistent nested spacing
        $0: 0,
        $1: 4,
        $2: 8,
        $3: 12,
        $4: 24,
        $5: 32,
        $6: 64,
        $7: 128,
    },
    fontSizes: {
        $00: 10,
        $0: 12,
        $1: 14,
        $2: 16,
        $3: 18,
        $4: 20,
        $5: 24,
        $6: 32,
        $7: 40
    },
    sizes: {
        selector: 35,
        selectorBottom: 41,
        selectorRight: 8,
        selectorLeft: 8,
        selectorHeight: 10,
        selectorMargin: 7,
        selectorMarginBottom: 15,
        selectorStateBottom: 41,
        labMinWidth: "80%",
        container: 1024,
        $modalMax: "75%"
    },
    layout: {
        view: {
            maxWidth: 1280,
            width: '100%',
            margin: 'auto'
        },
        form: {
            maxWidth: ['100%', '100%', 1024],
            height: '100%',
            backgroundColor: '$background'
        },
        viewContainer: {
            maxWidth: 768,
            width: '100%',
            margin: 'auto',
            paddingX: 5
        },
        viewBack: {
            display: 'flex',
            flexDirection: 'row',
            zIndex: 10
        }
    },
    styles: {
        header: {
            color: '$primary',
            textAlign: 'center'
        },
        smallheader: {
            color: '$primary',
            textAlign: 'center',
            fontSize: 16,
            fontWeight: 800,
            marginTop: 24
        },
        classheader: {
            textAlign: 'left',
            marginLeft: 10,
            marginBottom: 0,
            color: 'black',
            fontWeight: 600,
            fontSize: 20
        },
        tableColumn: {
            flexShrink: 1,
            flexGrow: 1,
            // minWidth: 300
        },
        tableRow: {
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        rowContent: {
            borderColor: '$gray',
            borderWidth: 1,
            textAlign: 'center',
            paddingY: '$3',
            paddingX: '$2',
            flexGrow: 1,
            justifyContent: 'center',
            alignItems: 'center'
        },
        rowHeader: {
            color: '#fff',
            fontWeight: 'bold',
            textAlign: 'center',
            bg: '$primary',
            paddingY: '$3',
            paddingX: '$2'
        },
        link: {
            color: '$primary',
            textDecorationLine: 'underline'
        },
        courseInfoItem: {
            width: '50%',
            paddingY: '$2',
            paddingLeft: '$2'
        }
    },
    buttons: {
        tab: {
            backgroundColor: '$gray'
        },
        gray: {
            backgroundColor: '$darkgray'
        },
        checkboxLabel: {
            backgroundColor: 'transparent',
            paddingLeft: 0,
            paddingRight: 0,
            fontSize: 14,
            margin: 0,
            display: 'contents'
        },
        radioLabel: {
            backgroundColor: 'transparent',
            paddingTop: 8,
            paddingLeft: 0,
            paddingRight: 0,
            fontSize: 14,
            margin: 0
        },
        radioGroup: {
            justifyContent: 'normal',
            width: '100%',
            alignItems: 'baseline',
            marginLeft: 20,
            marginTop: 8,
            marginBottom: 8
        }
    },
    tableCell: {
        color: '$text',
        width: '22.5%',
        padding: '$2',
        overflowWrap: 'anywhere',
    },
    verticalAlign: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'column',
    },
    text: {
        h3: {
            color: '$primary',
            textAlign: 'center',
            textTransform: 'uppercase'
        },
        trainingCardLabel: {
            position: 'absolute',
            top: -29,
            backgroundColor: '#c9da2a',
            paddingY: 6,
            paddingRight: 47,
            paddingLeft: 18,
            left: '13px',
            fontWeight: 600,
            color: '#26687f'
        }
    },
    trainingHeader: {
        header: {
            fontSize: 22,
            marginBottom: 9,
            marginTop: 16,
            textAlign: 'center',
            position: 'relative',
            zIndex: 100,
            fontWeight: 900,
            color: '#fff'
        },
        subheader: {
            marginBottom: 5,
            textAlign: 'center',
            color: 'white'
        },
        text: {
            letterSpacing: 0.4,
            textAlign: 'center',
            zIndex: 100,
            color: '#fff',
            position: 'relative',
            left: 10,
            top: -5
        },
        subtitle: {
            color: 'rgb(0, 85, 140)',
            fontWeight: 600,
            position: 'relative',
            top: -25,
            left: 70,
            fontSize: 11
        }
    },
    card: {
        borderBottomWidth: 0,
        boxShadow: '#0000002b 0px 2px 5px',
        marginRight: 10,
        marginLeft: 10,
        paddingTop: 12,
        paddingBottom: 12,
        paddingLeft: 4,
        paddingRight: 4,
        margin: 0,
        marginTop: 65,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    box: {
        borderBottomWidth: 0,
        boxShadow: '#0000002b 0px 2px 5px',
        paddingTop: 12,
        paddingBottom: 12,
        paddingLeft: 14,
        paddingRight: 14,
        marginTop: 20,
        flexDirection: 'column',
        justifyContent: 'space-between',
        flexShrink: 1,
        marginX: 10,
        height: 220,
        minWidth: 270
    }
})

type MyTheme = typeof Theme

declare module 'dripsy' {
    interface DripsyCustomTheme extends MyTheme { }
}

export { Theme }