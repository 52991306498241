import { useDripsyTheme } from "dripsy";
import { useCallback, useEffect } from "react";
import { useFocusEffect } from "@react-navigation/native"
import { Linking } from "react-native";
import { Text, View, H2, H3, FlatList } from "../components/ui";
import { Pressable } from "../components/ui/Pressable";
import { useMember } from "../hooks/useMember";
import Notification from "../models/Notification";

export default function NotificationsScreen() {
    const { loading, notifications, fetchNotifications } = useMember()
    const { theme } = useDripsyTheme()

    // useEffect(() => {
    //     async function loadAsync() {
    //         console.log("fetching notifications data")
    //         try {
    //             await fetchNotifications()
    //         } catch (err: any) {
    //             console.warn(err.toString())
    //         }
    //     }

    //     loadAsync()
    // }, [])

    useFocusEffect(useCallback(() => {
        fetchNotifications()
    }, []))

    const Item = ({ notification }: { notification: Notification }) => {
        return (
            <View sx={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', backgroundColor: notification.unread ? '$highlight' : 'white' }}>
                <Text sx={{ color: notification.unread ? '$primary' : undefined, width: '33%', height: '100%', ...theme.styles.rowContent }}>{notification.title}</Text>
                <Text sx={{ color: notification.unread ? '$primary' : undefined, width: '33%', height: '100%', ...theme.styles.rowContent }}>{notification.message}</Text>
                <View sx={{ width: '33%', height: '100%', borderWidth: 1, borderColor: '$gray' }}>
                    {!!notification.url &&
                        <View sx={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                            <Pressable onPress={() => Linking.openURL(notification.url)} sx={{ margin: 0 }}>
                                <Text sx={{ color: 'white' }}>Link</Text>
                            </Pressable>
                        </View>
                    }
                </View>
            </View>
        )
    }

    return (
        <View sx={{ flex: 1, padding: '$2', backgroundColor: '#fff' }}>
            <H2 sx={theme.styles.header}>Notifications</H2>
            <FlatList
                data={notifications}
                keyExtractor={(item, index) => index.toString()}
                renderItem={({ item }) => <Item notification={item as Notification} />}
                refreshing={loading}
                onRefresh={fetchNotifications}
                ListEmptyComponent={<H3>No Notifications</H3>}
                ListHeaderComponent={
                    <View sx={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <Text sx={{ width: '33%', ...theme.styles.rowHeader }}>Title</Text>
                        <Text sx={{ width: '33%', ...theme.styles.rowHeader }}>Message</Text>
                        <Text sx={{ width: '33%', ...theme.styles.rowHeader }}>&nbsp;</Text>
                    </View>}
            />
        </View>
    )
}