import { H2, H3, Text, View } from '../ui';
import { AntDesign } from '@expo/vector-icons';
import CalendarIcon from "svg/calendaricon-blue.svg";
import { Training } from '../../models/TrainingData';
import { useDripsyTheme } from 'dripsy';
import { Event } from '../../models/Event';

interface TrainingCardProps {
    training: Training | Event
}

export function TrainingCard(props: TrainingCardProps) {
    const { training } = props
    const { theme } = useDripsyTheme()

    return (
        <>
            <Text sx={{
                color: '$primary',
                maxWidth: '65%',
                maxHeight: '104px',
                overflowY: 'visible',
                marginLeft: '$2',
                fontSize: '18px',
                fontWeight: '900',
                letterSpacing: '0.1px',
                paddingX: 15,
                paddingTop: 21,
                paddingBottom: 60
            }}>{`${training.name}`}</Text>
            <View sx={{ flexDirection: 'row', alignItems: 'center', right: 15, top: -32 }}>
                <View sx={{ flexDirection: 'column', alignItems: 'right' }}>
                    <Text sx={{ color: '$primary', textAlign: 'right', fontWeight: 500, marginRight: 3 }}>View Details</Text>
                </View>
                <AntDesign name={"right"} size={28} color={theme.colors.$primary} />
            </View>
            <Text sx={{ flexDirection: 'row', color: '$primary', alignItems: 'center', position: 'absolute', bottom: 19, display: 'block', left: 13 }}>
                <CalendarIcon style={{ width: 23, marginRight: 10, marginLeft: 2, position: 'relative', top: 6 }} />
                {training.customDate.length > 0 ? (
                    <>Date: {training.customDate}</>
                ) : (
                    <>Date: {training.startDate.toString()} - {training.endDate.toString()}</>
                )}
            </Text>
        </>
    )
}