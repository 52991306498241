
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { Container, H3, ScrollView } from "../components/ui";
import { useDripsyTheme } from "dripsy";
import SignupForm from "../components/login/SignupForm";

export default function SignupScreen() {
    const navigation = useNavigation<NativeStackNavigationProp<any>>()
    const { theme } = useDripsyTheme()

    function handleFinish() {
        navigation.replace("Tabs")
    }

    function handleCancel() {
        navigation.goBack()
    }

    return (
        <ScrollView sx={{ padding: '$2', backgroundColor: '#fff' }}>
            <Container variant="form">
                <H3 sx={{ color: '$primary', textAlign: 'center', textTransform: 'uppercase' }}>Register Account</H3>
                <SignupForm
                    onSave={handleFinish}
                    onCancel={handleCancel}
                />
            </Container>
        </ScrollView>
    )
}