import { useDripsyTheme } from 'dripsy';
import { Linking } from 'react-native';
import { useState, useEffect } from 'react'
import { View, Text, H2, H3, ScrollView } from '../ui';
import { KitInfo } from "../../models/TrainingData";
import { FilesView } from '../FilesView';
import { WebViewer } from '../WebViewer';
import { Pressable } from '../ui/Pressable';

interface kitHTMLText {
    title: string,
    html: string
}

function KitDetail({ title, text, html, link, linkTitle, center }: { title: string, text?: string, html?: string, link?: string, linkTitle?: string, center?: boolean }) {
    const { theme } = useDripsyTheme()
    return (
        <>
            {title && title.length > 0 && <H3 sx={{ marginBottom: "$3" }}>{title}</H3>}
            {text && text.length > 0 && <Text sx={{ textAlign: center ? "center" : "left" }}>{text}</Text>}
            {html && html.length > 0 &&
                <View sx={{ paddingX: theme.space.$3, minWidth: theme.sizes.labMinWidth }}>
                    <View sx={{ alignItems: 'flex-start' }}>
                        <WebViewer html={html} scrollEnabled={true}></WebViewer>
                    </View>
                </View>
            }
            {link && link.length > 0 && <Text sx={{ ...theme.styles.link, textAlign: "center" }} onPress={() => Linking.openURL(link)}>{linkTitle ? linkTitle : link}</Text>}
        </>
    )
}

export default function KitInfoView({ kitInfo }: { kitInfo: KitInfo }) {
    const { theme } = useDripsyTheme()
    const [kitCodeType, setkitCodeType] = useState('string');
    const [kitURL, setURL] = useState('string');

    useEffect(() => {
        if (kitInfo.kitCode.length > 0) {
            const anchorTag = kitInfo.kitCode;
            const hrefMatch = anchorTag.match(/href="([^"]+)"/);
            const href = hrefMatch ? hrefMatch[1] : null;
            if (href) {
                var kitURL = new URL(href);
                setURL(kitURL.href);
                setkitCodeType('link');
            } else if (kitInfo.kitCode.startsWith('http')) {
                setURL(kitInfo.kitCode);
                setkitCodeType('link');
            } else {
                const htmlRegex = /<\/?[a-z][\s\S]*>/i;
                if (htmlRegex.test(kitInfo.kitCode)) {
                    setkitCodeType('html');
                }
            }
        }
    }, [])

    const data: kitHTMLText[] = [
        { title: "", html: kitInfo.kitPreMessage },
        { title: "", html: kitInfo.kitText },
        { title: "Directions", html: kitInfo.kitDirections }
    ]

    let html = ""
    data.filter((d) => d.html.length > 0).forEach((d) =>
        html += d.title.length > 0 ? `<h3>${d.title}</h3><p>${d.html}</p>` : `<p>${d.html}</p>`
    )

    const KitCodeContent = () => {
        return (
            <>
                {kitCodeType == 'link' ?
                    <H3 sx={{ textTransform: 'none' }}>
                        Link: <Pressable sx={{ fontSize: 16, textDecorationLine: 'underline' }} onPress={() => { Linking.openURL(kitURL) }}>
                            <Text>{kitURL}</Text>
                        </Pressable>
                    </H3>
                    : kitCodeType == 'html' ?
                        <WebViewer html={kitInfo.kitCode} autoHeight={true} />
                        :
                        <H3 sx={{ textTransform: 'none' }}>
                            Code: <Text sx={{ fontSize: 16 }}>{kitInfo.kitCode}</Text>
                        </H3>
                }
            </>
        );
    }

    return (
        <ScrollView>
            <H2 sx={theme.styles.header}>Course Kit</H2>
            {html.length > 0 && <KitDetail title="" html={html} />}
            {kitInfo.kitCode.length > 0 && <KitCodeContent />}
            {kitInfo.kitCourseUrl.length > 0 && <KitDetail title="Link" link={`${kitInfo.kitCourseUrl}`} />}
            {kitInfo.kitMaterialUrlExternal.length > 0 && <KitDetail title="Link" link={kitInfo.kitMaterialUrlExternal} linkTitle="EKit Download link" />}
            <FilesView files={kitInfo.kitFiles} />
        </ScrollView>
    )
}