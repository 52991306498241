import { createContext, ReactNode, useEffect, useRef, useState } from "react"
import { Platform } from "react-native"
import * as Device from 'expo-device'
import { Subscription } from 'expo-modules-core'
import * as Notifications from 'expo-notifications'
import { useLinkTo } from "@react-navigation/native"
import alert from '../utils/Common'
import { usePushNotificationsApi } from "../api/usePushNotificationApi"
import MissingProvider from "../utils/MissingProvider"
import camelcaseKeys from "camelcase-keys"
import { useDripsyTheme } from "dripsy"
import { useMember } from "../hooks/useMember"

Notifications.setNotificationHandler({
    handleNotification: async () => ({
        shouldShowAlert: true,
        shouldPlaySound: true,
        shouldSetBadge: true,
    }),
})

type PushNotificationsContext = {
    ready: boolean
    askForPermission: boolean
    onAskForPermission: () => void
}

export const PushNotificationsContext = createContext<PushNotificationsContext>({
    ready: false,
    askForPermission: false,
    onAskForPermission: MissingProvider
})

type PushNotificationsProviderProps = {
    children?: ReactNode
}

function PushNotificationsProvider({ children }: PushNotificationsProviderProps) {
    const { theme } = useDripsyTheme()
    const { member } = useMember()
    const linkTo = useLinkTo()
    const { postUpdateToken } = usePushNotificationsApi()
    const notificationListener = useRef<Subscription>()
    const responseListener = useRef<Subscription>()
    const [token, setToken] = useState<string | undefined>()
    const [ready, setReady] = useState(false)
    const [askForPermission, setAskForPermission] = useState(false)

    // Determine if we need to check for permisions or just retrieve the token
    useEffect(() => {
        async function checkPushPermissionAsync() {
            if (Platform.OS !== 'web' && Device.isDevice) {
                const { status: existingStatus } = await Notifications.getPermissionsAsync()
                console.log("Push notification status:", existingStatus)

                if (existingStatus === "granted") {
                    startPushNotificationsListeners()
                } else if (existingStatus === "undetermined") {
                    setAskForPermission(true)
                }
            }
        }

        checkPushPermissionAsync()
    }, [])

    useEffect(() => {
        async function registerTokenAsync() {
            if (token) {
                console.log("Registering push token with user:", member?.email)
                try {
                    await postUpdateToken(token, Platform.OS)
                    setAskForPermission(true)
                } catch (err: any) {
                    console.log("Unable to set push token:", err)
                }
            }
        }

        if (Platform.OS !== 'web' && Device.isDevice && member) {
            registerTokenAsync()
        }
    }, [member, token])

    async function startPushNotificationsListeners() {
        console.log("Requesting push notification token...")

        const token = await registerForPushNotificationsAsync()

        if (token) {
            setToken(token)
            setReady(true)

            // This listener is fired whenever a notification is received while the app is foregrounded
            notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
                console.log("Notification received:", notification)
                // processNotification(notification)
            })

            // This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
            responseListener.current = Notifications.addNotificationResponseReceivedListener(response => {
                console.log("Notification response:", response)
                handleNotificationPress(response.notification)
            })

            return () => {
                if (notificationListener.current) {
                    Notifications.removeNotificationSubscription(notificationListener.current)
                }

                if (responseListener.current) {
                    Notifications.removeNotificationSubscription(responseListener.current)
                }
            }
        } else {
            setReady(true)
        }
    }

    async function registerForPushNotificationsAsync() {
        let token: string | undefined

        if (Platform.OS !== 'web' && Device.isDevice) { // Web not supported yet
            const { status: existingStatus } = await Notifications.getPermissionsAsync()
            let finalStatus = existingStatus

            if (existingStatus !== 'granted') {
                const { status } = await Notifications.requestPermissionsAsync({
                    ios: {
                        allowAlert: true,
                        allowBadge: true,
                        allowSound: true,
                        allowAnnouncements: true,
                    },
                })
                finalStatus = status

                if (status === 'granted') {
                    console.log("Permission Granted")
                } else {
                    console.log("Permission NOT Granted")
                }
            }

            if (finalStatus === 'granted') {
                try {
                    token = (await Notifications.getExpoPushTokenAsync()).data
                    console.log("Received push token:", token)
                } catch (err: any) {
                    // Google runs test test pipeline on the app and this fails in the test environment
                    console.log("Failed to fetch push token in granted case")
                }
            }
        } else {
            alert('Must use physical device for Push Notifications')
        }

        if (Platform.OS === 'android') {
            Notifications.setNotificationChannelAsync('default', {
                name: 'default',
                importance: Notifications.AndroidImportance.MAX,
                vibrationPattern: [0, 250, 250, 250],
                lightColor: '#FF231F7C',
            })
        }

        return token
    }

    function onAskForPermission() {
        startPushNotificationsListeners()
    }

    function handleNotificationPress(notification: Notifications.Notification) {
        if (notification.request.content.data) {
            const data: any = camelcaseKeys(notification.request.content.data, { deep: true })

            if( data.appUrl ) {
                linkTo(data.appUrl);
            } else {
                linkTo('/studenthub/notifications');
            }

            // if (data.url) {
            //     linkTo(data.url)
            // }
        }
    }

    return (
        <PushNotificationsContext.Provider value={{ ready, askForPermission, onAskForPermission }}>
            {children}
        </PushNotificationsContext.Provider>
    )
}

export default PushNotificationsProvider