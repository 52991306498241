
import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import FormTextInput from "../ui/forms/FormTextInput";
import { Pressable } from "../ui/Pressable";
import { useState } from "react";
import { SafeAreaView, Text, View, H3 } from "../../components/ui";
import { useMember } from "../../hooks/useMember";
import alert from "../../utils/Common";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useMutation } from "@tanstack/react-query";

interface FormValues {
    password: string
    repeatPassword: string
}

interface ChangePasswordFormProps {
    hash: string
}

export default function ChangePasswordForm(props: ChangePasswordFormProps) {
    const navigation = useNavigation<NativeStackNavigationProp<any>>()
    const { hash } = props
    const { savePassword } = useMember()
    const { ...methods } = useForm<FormValues>({ mode: 'onBlur' })
    const canSubmit = methods.formState.isValid && !methods.formState.isSubmitting
    const [updateError, setUpdateError] = useState("")

    const mutation = useMutation({
        mutationFn: (data: FormValues) => savePassword(data.password, hash),
        onSuccess: (data, variables, context) => {
            setUpdateError("")
            alert("Password changed", "You can now login with your new password", [{
                text: 'OK',
                onPress: () => navigation.goBack()
            }])
        },
        onError: (error, variables, context) => {
            setUpdateError(error.toString())
        }
    })

    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        mutation.mutate(data)
    }

    function onCancel() {
        navigation.goBack()
    }

    const onError: SubmitErrorHandler<FormValues> = (errors, e) => {
        return console.error("Form error(s):", errors)
    }

    return (
        <SafeAreaView sx={{ justifyContent: 'center', alignItems: 'center', paddingX: '$4' }}>
            {updateError.length > 0 && <Text sx={{ color: 'white', bg: '$error', fontWeight: 'bold', padding: '$2', borderRadius: 5, minWidth: 400, textAlign: 'center', marginBottom: '$2' }}>{updateError}</Text>}
            <Text sx={{ textAlign: 'center' }}>Please set a new password</Text>
            <FormProvider {...methods}>
                <FormTextInput
                    name="password"
                    label="Password"
                    defaultValue=""
                    placeholder="********"
                    autoCompleteType="password"
                    textContentType="password"
                    secureTextEntry={true}
                    rules={{
                        minLength: { value: 8, message: "Min Length 8" }
                    }}
                />
                <FormTextInput
                    name="repeatPassword"
                    label="Repeat Password"
                    defaultValue=""
                    placeholder="********"
                    autoCompleteType="password"
                    textContentType="password"
                    secureTextEntry={true}
                    rules={{
                        minLength: { value: 8, message: "Min Length 8" },
                        validate: {
                            matchesPassword: (value) => {
                                const { password } = methods.getValues()
                                return password === value || "Password don't match"
                            }
                        }
                    }}
                />

                <View sx={{ flexDirection: 'row' }}>
                    <Pressable
                        onPress={onCancel}
                        version="buttons.gray">
                        <Text sx={{ color: 'white' }}>Cancel</Text>
                    </Pressable>
                    <Pressable
                        onPress={methods.handleSubmit(onSubmit, onError)}
                        version="buttons.primary">
                        <Text sx={{ color: 'white' }}>Change</Text>
                    </Pressable>
                </View>
            </FormProvider>
        </SafeAreaView>
    )
}