import { createContext, useEffect, useState } from "react"
import { useMemberApi } from "../api/useMemberApi"
import { setApiKey } from "../api/useRestUtils"
import Contact from "../models/Contact"
import Member, { BannerAdvertisement, HubModes } from "../models/Member"
import Quote from "../models/Quote"
import MissingProvider from "../utils/MissingProvider"
import Storage from "../utils/Storage";
import { Platform } from "react-native"
import { useConfig } from "../config/useConfig";

interface MemberContextType {
    loading: boolean
    member: Member | undefined
    quotes: Quote[]
    notifications: Notification[]
    contacts: Contact[]
    roles: string[]
    showHeader: boolean
    bannerAd: BannerAdvertisement[]
    trainingMode: string
    fetchMember: () => Promise<void>
    updateMember: (member?: Member) => void
    fetchQuotes: () => Promise<void>
    fetchNotifications: () => Promise<void>
    fetchContacts: () => Promise<void>
    fetchRoles: () => Promise<void>
    setHeader: (state: boolean) => void
    updateTrainingMode: (mode: string | null) => void
}

export const MemberContext = createContext<MemberContextType>({
    loading: false,
    member: undefined,
    quotes: [],
    notifications: [],
    contacts: [],
    roles: [],
    bannerAd: [],
    showHeader: true,
    trainingMode: "default",
    fetchMember: async () => MissingProvider(),
    updateMember: (member) => { MissingProvider() },
    fetchQuotes: async () => MissingProvider(),
    fetchNotifications: async () => MissingProvider(),
    fetchContacts: async () => MissingProvider(),
    fetchRoles: async () => MissingProvider(),
    setHeader: async (state) => MissingProvider(),
    updateTrainingMode: () => { }
})

interface MemberProviderProps {
    children?: React.ReactNode
}

function MemberProvider({ children }: MemberProviderProps) {
    const { getCurrentMember, getQuotes, getNotifications, getContacts, getRoles } = useMemberApi()
    const [member, setMember] = useState<Member>()
    const [quotes, setQuotes] = useState<Quote[]>([])
    const [notifications, setNotifications] = useState<Notification[]>([])
    const [contacts, setContacts] = useState<Contact[]>([])
    const [roles, setRoles] = useState<string[]>([])
    const [showHeader, setShowHeader] = useState<boolean>(true)
    const [bannerAd, setBannerAd] = useState<BannerAdvertisement[]>([])
    const [loading, setLoading] = useState(false)
    const [trainingMode, setTrainingMode] = useState("default")
    const { brand } = useConfig()

    useEffect(() => {
        checkTrainingMode()
    }, [])

    async function checkTrainingMode() {
        const storageKeys = await Storage.init()
        let myTrainingMode = Storage.get('TrainingMode')
        let trainingEventId = 0
        if (Platform.OS === 'web') {
            if (window.location.search) {
                const params = new URLSearchParams(window.location.search)
                const trainingModeParam = params.get('mode')
                if (trainingModeParam) {
                    myTrainingMode = trainingModeParam
                }

                trainingEventId = Number(params.get('ID'))
            }
        }

        if (myTrainingMode) {
            updateTrainingMode(myTrainingMode, trainingEventId)
        }
    }

    async function fetchMember() {
        try {
            const member = await getCurrentMember()
            updateMember(member)
        } catch (err: any) {
            if (err.errorCode === "NOTLOGGEDIN") {
                updateMember(undefined)
            } else {
                console.log("Error fetching user:", err)
                throw (err) // pass it on
            }
        }
    }

    function updateMember(member: Member | undefined) {
        setMember(member)
        if (!member) {
            setApiKey(null)
        }

        if (member && !member.hubManagementModes.includes(Storage.get('TrainingMode'))) {
            updateTrainingMode('default')
        }
    }

    async function fetchQuotes() {
        try {
            setLoading(true)
            const quotes = await getQuotes()
            setQuotes(quotes)
        } catch (err: any) {
            console.log("Error fetching quotes:", err)
            throw (err)
        } finally {
            setLoading(false)
        }
    }

    async function fetchNotifications() {
        try {
            setLoading(true)
            const nots = await getNotifications()
            setNotifications(nots)
        } catch (err: any) {
            console.log("Error fetching notifications", err)
            throw (err)
        } finally {
            setLoading(false)
        }
    }

    async function fetchContacts() {
        try {
            setLoading(true)
            const contacts = await getContacts()
            setContacts(contacts)
        } catch (err: any) {
            console.log("Error fetching contacts", err)
            throw (err)
        } finally {
            setLoading(false)
        }
    }

    async function fetchRoles() {
        try {
            const roles = await getRoles()
            setRoles(roles)
        } catch (err: any) {
            console.log("Error fetching roles", err)
            throw (err)
        } finally {

        }
    }

    function updateTrainingMode(mode: string | null, trainingEventId?: number) {
        if (mode && HubModes.includes(mode)) {
            Storage.set('TrainingMode', mode)
            setTrainingMode(mode)

            if (trainingEventId && trainingEventId > 0) {
                Storage.set('TrainingMgmtEventID', trainingEventId)
            }
        }
    }

    function setHeader(state: boolean) {
        setShowHeader(state);
        return;
    }

    return (
        <MemberContext.Provider value={{ loading, member, quotes, notifications, contacts, roles, showHeader, bannerAd, trainingMode, fetchMember, updateMember, fetchQuotes, fetchNotifications, fetchContacts, fetchRoles, setHeader, updateTrainingMode }}>
            {children}
        </MemberContext.Provider>
    )
}

export default MemberProvider