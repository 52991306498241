import TrainingView from "../components/training/TrainingView"
import TrainingCalendar from "../components/ui/TrainingCalendar";
import { View } from '../components/ui';
import { useState } from "react";

export default function TrainingScreen() {
    const [trainingView, setTrainingView] = useState<string>('TrainingView');

    return (
        <>
            <View sx={{ flex: 1, backgroundColor: '#fff', width: '100%' }}>
                {trainingView == 'TrainingCalendar' && <TrainingCalendar windowSelection={setTrainingView} />}
                {trainingView == 'TrainingView' && <TrainingView windowSelection={setTrainingView} />}
            </View>
        </>
    )
}