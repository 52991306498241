import { Image, View, Text, ScrollView, H1 } from "../../components/ui"
import { Linking, Platform } from "react-native"
import { useConfig } from "../../config/useConfig";
import { useMediaQuery } from "react-responsive";
import { Pressable } from "./Pressable";

const getHelpIcon = require('../../../assets/training/get-help-icon.png')

export function GetHelp() {
    const env = useConfig()
    const isMobile = useMediaQuery({ query: '(max-width: 550px)' })

    var iconStyle: any = { height: 24, width: 24, marginRight: '$2' };
    var buttonStyle: any = {
        zIndex: 10, margin: 0, flexDirection: 'row', cursor: 'pointer', minWidth: isMobile ? 170 : 200, flexShrink: 1, justifyContent: 'flex-start', alignItems: 'center',
        marginTop: '$1', bg: '$primary', alignSelf: 'flex-start', paddingX: isMobile ? 11 : 12, paddingY: isMobile ? 8 : 12, boxShadow: '1px 1px 3px #00000054'
    };
    var textStyle: any = { fontWeight: 500, color: '$background', textDecorationLine: 'none', textAlign: 'center', zIndex: 100 };

    return (
        <View>
            {Platform.OS !== 'web' ? (
                <Pressable sx={buttonStyle} onPress={() => Linking.openURL("https://www.exitcertified.com")}>
                    <Image source={getHelpIcon} sx={iconStyle} resizeMode="contain" />
                    <Text sx={{ ...textStyle, fontSize: isMobile ? 13 : 16, left: 35, right: 0, position: 'absolute' }}>Get Help | Chat</Text>
                </Pressable>
            ) : (
                <View sx={buttonStyle}>
                    <Image source={getHelpIcon} sx={iconStyle} resizeMode="contain" />
                    <View sx={{ width: '100%', textAlign: 'center', top: isMobile ? -2 : 15, left: 0, position: 'absolute' }}>
                        {env.qualifiedExperienceId ? (
                            <div dangerouslySetInnerHTML={{
                                __html: `<a href="#" id="get-help" style="color:white;text-decoration:none;font-size:16px;font-weight:500;" data-qualified-action="showExperience" data-qualified-arg="${env.qualifiedExperienceId}">
                                        Get Help | Chat
                                    </a>`
                            }} />
                        ) : (
                            <Text sx={{ ...textStyle, fontSize: isMobile ? 13 : 16, right: 0, position: 'absolute', left: 35, top: isMobile ? 14 : 0 }} onPress={() => Linking.openURL(`${env.webBase}/contact`)}>
                                Get Help | Chat
                            </Text>
                        )}
                    </View>
                </View>
            )}
        </View>
    )

}