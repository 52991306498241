import Course from "../../models/Course";
import { View, Text } from '../../components/ui';
import { Pressable } from '../../components/ui/Pressable';
import { MaterialIcons } from '@expo/vector-icons';
import { Linking } from 'react-native'
import { useConfig } from "../../config/useConfig";
import { useDripsyTheme } from "dripsy";
import { TrackGoogleAnalyticsEvent } from "../../utils/GoogleAnalytics";

export default function CourseThumb({ course }: { course: Course }) {
    const env = useConfig()
    const { theme } = useDripsyTheme()

    const ClickFollowOnCourses = (course: any) => {
        TrackGoogleAnalyticsEvent("follow_on_courses", "ViewFollowOnCourse", "Clicked on a Follow On Course.", "xhr");
        Linking.openURL(`${env.webBase}/${course.url}`)
    }

    return (
        <Pressable
            onPress={() => { ClickFollowOnCourses(course) }}
            style={{
                borderColor: theme.colors.$gray,
                backgroundColor: 'white',
                borderTopWidth: 1,
                borderRadius: 8,
                padding: theme.space.$4,
                margin: theme.space.$3,
                flexBasis: '23%',
                minWidth: 350,
                shadowColor: '#000',
                shadowOffset: {
                    width: 0,
                    height: 3,
                },
                shadowOpacity: 0.33,
                shadowRadius: 3.84,
                elevation: 5
            }}
        >
            <View sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <Text sx={{ fontSize: '$0', textTransform: 'uppercase', letterSpacing: 0.08333 }}>{course.category.name}</Text>
                {course.popular &&
                    <Text sx={{
                        backgroundColor: '$orange',
                        color: 'white',
                        textTransform: 'uppercase',
                        borderRadius: 3,
                        padding: '$1',
                        fontSize: '$00',
                        fontWeight: 'bold'
                    }}>Popular</Text>
                }
            </View>
            <Text sx={{ color: '$primary', fontSize: '$4', paddingBottom: '$2', paddingTop: '$1' }}>{course.name}</Text>
            <Text sx={{ fontSize: '$1', fontWeight: 'bold', paddingBottom: '$4' }}>{course.code}</Text>
            <Text sx={{ color: '$darkgray' }}>{course.description}</Text>

            <Text sx={{ paddingTop: '$2', marginTop: 'auto' }} onPress={() => { ClickFollowOnCourses(course) }}>
                <MaterialIcons
                    name="add-circle"
                    size={24}
                    style={{ verticalAlign: 'middle' }}
                />
                <Text sx={{ marginLeft: '$1', color: '$primary', textTransform: 'uppercase', fontWeight: 'bold' }}>View Course</Text>
            </Text>
        </Pressable>
    )
}