
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { Container, H3, Text, View } from "../components/ui";
import { useContext, useEffect } from "react";
import LoginForm from "../components/login/LoginForm";
import Member from "../models/Member";
import { PushNotificationsContext } from "../providers/PushNotificationsProvider";
import { Platform } from "react-native";

export default function LoginScreen() {
    const navigation = useNavigation<NativeStackNavigationProp<any>>()
    const { onAskForPermission, askForPermission, ready } = useContext(PushNotificationsContext)

    function handleLogin(member: Member) {
        if (askForPermission && ready || Platform.OS == 'web') {
            // navigation.navigate("Tabs", { screen: 'Training'})
        } else {
            //TODO: pushnotification screen might have to be added to the tabs flow
            // because once we have a member, we don't render the onboarding navigator
            navigation.reset({
                index: 0,
                routes: [{
                    name: 'Onboarding', params: {
                        screen: 'PushNotification'
                    }
                }]
            })
        }
    }

    function handleSignup() {
        navigation.push("Signup")
    }

    return (
        <View sx={{ flex: 1, padding: '$2', backgroundColor: '#fff' }}>
            <Container variant="form">
                <H3 sx={{ color: '$primary', textAlign: 'center', textTransform: 'uppercase' }}>Sign In</H3>
                <LoginForm
                    onLogin={handleLogin}
                />
                <Text sx={{ textAlign: 'center', marginTop: '$2' }}>Don't have an account? <Text sx={{ color: '$primary', fontWeight: 'bold' }} onPress={handleSignup}>Sign up!</Text></Text>
            </Container>
        </View>
    )
}