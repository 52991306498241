import { Pressable as DripsyPressable, useDripsyTheme, styled } from "dripsy";
import { Text, View, ActivityIndicator } from '../ui';
import React from "react";
import { useState } from "react";
import { GestureResponderEvent } from "react-native";

type DripsyPressableProps = React.ComponentPropsWithoutRef<typeof DripsyPressable>

interface PressableProps extends DripsyPressableProps {
    version?: string
}

export function Pressable(props: PressableProps) {
    const { sx, onPress, ...otherProps } = props
    const [loading, setLoading] = useState<boolean>(false)

    var buttonSx = {
        paddingX: '$3',
        paddingY: '$2',
        margin: '$2',
        flexDirection: 'row',
        justifyContent: 'space-around',
        verticalAlign: 'middle',
        opacity: loading ? 0.75 : 1
    }

    const myOnPress = async (event: GestureResponderEvent) => {
        if (onPress) {
            setLoading(true);
            await onPress(event);
            setLoading(false);
        }
    };

    switch (props.version) {

        case "buttons.tab":
            return <DripsyPressable
                onPress={myOnPress}
                sx={{ backgroundColor: '$gray', width: '50%', paddingY: '$2', textAlign: 'center', borderBottomWidth: 1, borderBottomColor: '$darkgray', ...sx }}
                {...otherProps}
            >
                {otherProps.children}
            </DripsyPressable>

        case "buttons.gray":
            return <DripsyPressable
                onPress={myOnPress}
                sx={{ ...buttonSx, backgroundColor: '$darkgray', ...sx }}
                {...otherProps}
                disabled={loading}
            >
                {loading && <ActivityIndicator size={16} style={{ marginRight: 8 }} color="$background" />}
                {otherProps.children}
            </DripsyPressable>

        case "buttons.primary":
            return <DripsyPressable
                onPress={myOnPress}
                sx={{ ...buttonSx, backgroundColor: '$primary', ...sx }}
                {...otherProps}
                disabled={loading}
            >
                {loading && <ActivityIndicator size={16} style={{ marginRight: 8 }} color="$background" />}
                {otherProps.children}
            </DripsyPressable>
        case "buttons.highlight":
            return <DripsyPressable
                onPress={myOnPress}
                sx={{ ...buttonSx, backgroundColor: '$highlight', ...sx }}
                {...otherProps}
                disabled={loading}
            >
                {loading && <ActivityIndicator size={16} style={{ marginRight: 8 }} color="$background" />}
                {otherProps.children}
            </DripsyPressable>

        default:
            return <DripsyPressable
                onPress={myOnPress}
                sx={sx}
                {...otherProps}
            />
    }

}