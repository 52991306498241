import { createContext, useEffect, useState } from "react";
import { useMemberApi } from "../api/useMemberApi";
import { Training, TrainingData, TrainingListItem } from "../models/TrainingData";
import Course from "../models/Course";

import MissingProvider from "../utils/MissingProvider";

type TrainingDataKeys = {
    active: string,
    future: string,
    past: string,
    followOn: string
}
interface TrainingDataContextType {
    loading: boolean
    trainingData: TrainingData | undefined
    trainingCalendar: TrainingListItem[] | undefined
    refreshTrainingData: () => Promise<void>
    updateTraining: (id: string, values: { [key: string]: any }) => void
    getTrainingCalendarEvents: () => void
}

export const TrainingDataContext = createContext<TrainingDataContextType>({
    loading: false,
    trainingData: { active: [], future: [], past: [], followOn: [] },
    trainingCalendar: [],
    refreshTrainingData: async () => { MissingProvider() },
    updateTraining: (id, values) => { MissingProvider() },
    getTrainingCalendarEvents: () => { MissingProvider() }
})

interface TrainingProviderProps {
    children?: React.ReactNode
}

function TrainingProvider({ children }: TrainingProviderProps) {
    const { getTrainingData, getTrainingCalendar } = useMemberApi()
    const [trainingData, setTrainingData] = useState<TrainingData>({ active: [], future: [], past: [], followOn: [] })
    const [trainingCalendar, setTrainingCalendar] = useState<TrainingListItem[]>()
    const [loading, setLoading] = useState(false)

    async function refreshTrainingData() {
        try {
            setLoading(true)
            const newtrainingData = await getTrainingData();
            setTrainingData(newtrainingData)
        } catch (err: any) {
            console.error("Error refreshing training data", err.toString())
        } finally {
            setLoading(false)
        }
    }

    function updateTraining(id: number | string | undefined, values: { [key: string]: any }) {

        let k: keyof TrainingDataKeys

        if (trainingData) {
            for (k in trainingData) {
                const trainingList: Training[] | Course[] = trainingData[k]
                for (const i in trainingList) {
                    let training = trainingList[i]
                    if (training.id === id) {
                        training = { ...training, ...values }
                        trainingList[i] = training
                    }
                }
            }
        }

    }

    async function getTrainingCalendarEvents() {
        try {
            setLoading(true)
            const newtrainingData = await getTrainingCalendar();
            setTrainingCalendar(newtrainingData)
        } catch (err: any) {
            console.error("Error refreshing training calendar data", err.toString())
        } finally {
            setLoading(false)
        }
    }

    return (
        <TrainingDataContext.Provider value={{ loading, trainingData, trainingCalendar, refreshTrainingData, updateTraining, getTrainingCalendarEvents }}>
            {children}
        </TrainingDataContext.Provider>
    )
}

export default TrainingProvider