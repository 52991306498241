import { DripsyProvider } from "dripsy";
import { useEffect, useState } from "react";
import { Platform, View, Text } from "react-native";
import { NavigationContainer } from "./navigation/NavigationContainer";
import Storage from "./utils/Storage";
import { Theme } from './Theme'
import { SafeAreaProvider } from "react-native-safe-area-context";
import { StatusBar } from "expo-status-bar";
import { ActivityIndicator } from "react-native-paper";
import { useMember } from "./hooks/useMember";
import { ConfirmMember } from "./models/Member";
import Header from "./components/Header";
import { Pressable } from "./components/ui/Pressable";
import { InitializeGoogleAnalytics } from "./utils/GoogleAnalytics";

function AppUI() {
    const { fetchMember, loginHubID, postConfirmHubID } = useMember()
    const [appIsReady, setAppIsReady] = useState(false)
    const [confirmMember, setConfirmMember] = useState<ConfirmMember>()
    const [confirmingMember, setConfirmingMember] = useState(false)

    const sleep = (ms: number) => new Promise(
        resolve => setTimeout(resolve, ms)
    )

    useEffect(() => {
        InitializeGoogleAnalytics();
        async function prepare() {
            try {
                const storageKeys = await Storage.init()
                console.log("Preparing app...")

                let studentHubID = null;
                if (Platform.OS == 'web') {
                    if (window.location.search) {
                        const params = new URLSearchParams(window.location.search)
                        studentHubID = params.get('studenthubid')
                        params.delete('studenthubid')
                    }
                }
                if (studentHubID && studentHubID.length > 0) {
                    const confirmMember = await postConfirmHubID(studentHubID)
                    if (confirmMember.hasAccount) {
                        await loginHubID(studentHubID)
                    } else {
                        setConfirmMember(confirmMember)
                    }
                } else {
                    await fetchMember()
                }
            } catch (err: any) {
                //console.warn(err.toString())
            } finally {
                setAppIsReady(true)
            }
        }

        prepare()
    }, [])

    const handleStudentHubLogin = async () => {
        if (confirmMember) {
            setConfirmingMember(true)
            await loginHubID(confirmMember.studentHubId)
            setConfirmMember(undefined)
        }
    }

    if (!appIsReady) {
        return (
            <View style={{ position: 'relative', zIndex: 1001, flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: Theme.colors.$primary }}>
                <ActivityIndicator size="large" color="#fff" />
            </View>
        )
    }

    if (confirmMember) {
        return (
            <DripsyProvider theme={Theme}>
                <SafeAreaProvider>
                    <Header />
                    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ padding: 4 }}>Hi <Text style={{ color: Theme.colors.$primary, fontWeight: 'bold' }}>{confirmMember.name}</Text>,</Text>
                        <Text style={{ padding: 10 }}>Please click continue to access the Training Hub</Text>
                        {confirmingMember ? (
                            <Text style={{ color: Theme.colors.$primary, fontWeight: 'bold' }}>Initializing...</Text>
                        ) : (
                            <Pressable onPress={handleStudentHubLogin} version="buttons.primary">
                                <Text style={{ color: 'white' }}>Continue</Text>
                            </Pressable>
                        )}
                    </View>
                </SafeAreaProvider>
            </DripsyProvider>
        )
    }

    return (
        <DripsyProvider theme={Theme}>
            <SafeAreaProvider>
                <StatusBar style="dark" />
                <NavigationContainer />
            </SafeAreaProvider>
        </DripsyProvider>
    )
}

export default AppUI