import { Linking, Platform } from "react-native";
import Contact from "../models/Contact";
import Member, { Address, ConfirmMember, BannerAdvertisement } from "../models/Member";
import { TestConnection } from "../models/TestConnection";
import { ReggieFile, Training, TrainingData, TrainingListItem } from "../models/TrainingData";
import { useRestUtils } from "./useRestUtils";
import { useConfig } from "../config/useConfig";
import { TrainingMgmtData, TrainingMgmt, CoordinatorQuestionnaire, RosterInvitee, Attendance, Certificates } from "../models/TrainingMgmtData";
import { TrainingInstructorData, TrainingInstructorFiles } from "../models/TrainingInstructorData";
import { clearParams } from "../utils/Common";
import { getEnvVars } from "../config/useConfig";
import Storage from "../utils/Storage";
import { Event } from "../models/Event";
import { Quiz } from "../models/Quiz";

export function useMemberApi() {
    const { multipartRestOptions, get, post, patch, _delete } = useRestUtils()
    const { brand } = useConfig()
    const envVars = getEnvVars();

    function getCurrentMember(): Promise<Member> {
        let url = "/members"

        if (Platform.OS === 'web') {
            const params = new URLSearchParams(window.location.search)
            const studentHubToken = params.get('stoken')
            if (studentHubToken) {
                url = `${url}?stoken=${studentHubToken}`
            }
        }
        return get<Member>(url)
    }

    function postLogin(email: string, password: string): Promise<Member> {
        return post<Member>("/members/login", { "Email": email, "Password": password })
    }

    function postLoginHubID(studentHubId: string): Promise<Member> {
        return post<Member>("/members/loginStudentHubID", { "StudentHubId": studentHubId })
    }

    function postConfirmHubID(studentHubId: string): Promise<ConfirmMember> {
        return post("/members/confirmStudentHubID", { "StudentHubId": studentHubId })
    }

    function postLogout(): Promise<any> {
        return post("/members/logout")
    }

    function postForgotPassword(email: string): Promise<string[]> {
        return post("/members/forgot", { "Email": email, "AxcelBrand": brand })
    }

    function getQuotes(): Promise<any> {
        return get("/members/quotes")
    }

    function getNotifications(): Promise<any> {
        return get("/members/notifications")
    }

    function getCountries(): Promise<{ [key: string]: string }> {
        return get("/members/countries", { camelCaseKeys: false, jsonBody: true })
    }

    function getStates(country: string): Promise<{ [key: string]: string }> {
        return get(`/members/states?Country=${country}`, { camelCaseKeys: false, jsonBody: true })
    }

    function patchUpdate(email: string, firstName: string, lastName: string, company: string, role: string, password: string, country: string, state: string, street: string, city: string, zip: string, shipping: Address, shippingSameAsContact: boolean, axcelBrand: string): Promise<Member> {
        return patch<Member>("/members", { "Email": email, "FirstName": firstName, "LastName": lastName, "Company": company, "Role": role, "Password": password, "Country": country, "State": state, "Street": street, "City": city, "Zip": zip, "Shipping": shipping, "ShippingSameAsContact": shippingSameAsContact, "AxcelBrand": axcelBrand })
    }

    function postCreate(firstName: string, lastName: string, password: string, repeatPassword: string, email: string, company: string, country: string, state: string): Promise<Member> {
        return post<Member>("/members", { "FirstName": firstName, "Surname": lastName, "Password": password, "PasswordVerify": repeatPassword, "NewEmail": email, "Company": company, "Contact": { "Country": country, "State": state } })
    }

    function postUpdatePassword(password: string, hash: string) {
        return post("/members/updatePwd", { "Password": password, "Hash": hash })
    }

    function postValidateCode(code: string): Promise<Member> {
        return post("/members/validateCode", { "Code": code })
    }

    function postValidateCodeByHash(code: string, hash: string): Promise<string[]> {
        return post("/members/validateCodeByHash", { "Code": code, "Hash": hash })
    }

    function getEmailCode(): Promise<void> {
        return post("/members/emailCode", { "AxcelBrand": brand })
    }

    function getTrainingData(): Promise<TrainingData> {
        let url = `/members/training`;
        if (Platform.OS === 'web') {
            const params = new URLSearchParams(window.location.search)
            const reggieID = params.get('reggieID')
            const contactID = params.get('contactID')
            if (reggieID && contactID) {
                url = `${url}/${reggieID}?contactID=${contactID}`
                clearParams(['reggieID', 'contactID'])
            } else if (reggieID) {
                url = `${url}/${reggieID}`
            }
        }

        return get(url)
    }

    function getTraining(reggieId: number): Promise<Training> {
        return get(`/members/training/${reggieId}?single=1`);
    }

    function getTrainingCalendar(): Promise<TrainingListItem[]> {
        return get("/members/calendarEvents");
    }

    function getTrainingMgmtData(startDate?: string, endDate?: string): Promise<TrainingMgmtData> {
        let url = `/members/trainingManagement`;
        var params = [];
        const eventId = Storage.get('TrainingMgmtEventID')

        if (eventId) params.push(['eventId', eventId])
        if (startDate) params.push(['startDate', startDate]);
        if (endDate) params.push(['endDate', endDate]);

        if (params.length > 0) url += '?';
        params.forEach((p) => { url += p[0] + '=' + p[1] + '&'; });
        return get(url)
    }

    function getTrainingCertificates(eventId: number, parentEventId: number, OppID: string): Promise<Certificates[]> {
        var url = `/management/trainingCertificates?ReggieID=${eventId}&ParentEventID=${parentEventId}&OppID=${OppID}`;
        return get(url);
    }

    function getTrainingMgmtFiles(eventId: number, parentEventId?: number): Promise<ReggieFile[]> {
        let url = `/management/eventFiles?EventID=${eventId}`
        if (parentEventId && parentEventId > 0) {
            url = `${url}&ParentEventId=${parentEventId}`
        }
        return get(url)
    }

    function downloadAttendanceRecords(oppId: string, eventName: string, eventID: number) {
        const defaultRestOptions = {
            camelCaseKeys: true,
            jsonBody: false,
            download: true
        }
        return get(`/management/exportAttendanceRecords?OppID=${oppId}&EventId=${eventID}&EventTitle=${encodeURIComponent(eventName)}`, defaultRestOptions);
    }

    function getTrainingInstructorData(startDate?: string, endDate?: string): Promise<TrainingInstructorData> {
        const token = Storage.get('token');
        let url = `/management/trainingInstructors?token=${token}`
        if (startDate && endDate) {
            url = `${url}&startDate=${startDate}&endDate=${endDate}`
        }
        return get(url);
    }

    function getRoster(oppId: string, eventId?: number): Promise<RosterInvitee[]> {
        let url = `/management/roster?OppID=${oppId}`
        if (eventId) {
            url = url + `&EventID=${eventId}`
        }
        return get(url)
    }

    function postRoster(oppId: string, firstName: string, lastName: string, email: string, eventId?: number): Promise<RosterInvitee> {
        return post("/management/roster", { "OppID": oppId, "FirstName": firstName, "LastName": lastName, "Email": email, "EventID": eventId ? eventId : 0 })
    }

    function postRosterMultiple(oppId: string, roster: { [key: string]: string }[]): Promise<RosterInvitee[]> {
        return post("/management/rosterList", { "OppID": oppId, "Roster": roster })
    }

    function getAttendance(eventId?: number): Promise<Attendance> {
        return post("/management/attendance", { "EventID": eventId })
    }

    function delRoster(oppId: string): Promise<boolean> {
        return _delete(`/management/roster?OppID=${oppId}`)
    }

    function getContacts(): Promise<Contact[]> {
        return get("/members/contacts")
    }

    function getRoles(): Promise<string[]> {
        return get("/members/roles")
    }

    function postUpdateOpp(sfId: string, key: string, val: string | boolean) {
        return post("/members/updateOpp", { "SFID": sfId, "OppKey": key, "OppVal": val })
    }

    function getTestConnectionDetails(sfId: string, platform?: string): Promise<TestConnection> {
        let url = `/members/testConnectionDetails?OppID=${sfId}`
        if (platform) {
            url = `${url}&platform=${platform}`
        }
        return get(url)
    }

    function getStartedClassroomLink(id: string): Promise<any> {
        return get(`/members/getStarted/classroom/${id}`)
    }

    function getBannerAd(brand: string = 'exitcertified'): Promise<BannerAdvertisement[]> {
        return get(`/members/bannerAdvertisement?brand=${brand}`)
    }

    function getInstructorFiles(): Promise<TrainingInstructorFiles[]> {
        return get(`/management/instructorFiles?Brand=${brand}`)
    }

    function downloadInstructorFile(hash: string): Promise<any> {
        const defaultRestOptions = {
            camelCaseKeys: true,
            jsonBody: false,
            download: true
        }
        return get(`/management/instructorFileDownload/${hash}`, defaultRestOptions);
    }

    function getQuestionnaire(reggieId: number): Promise<CoordinatorQuestionnaire> {
        return get(`/members/coordinatorQuestionnaire?ID=${reggieId}`)
    }

    function getProgramEvents(reggieId: number): Promise<Event[]> {
        return get(`/management/programEvents?EventID=${reggieId}`)
    }

    function getProgramOpps(reggieId: number): Promise<Training[]> {
        return get(`/members/programOpps?EventID=${reggieId}`)
    }

    function getQuizzes(reggieId: number): Promise<Quiz[]> {
        return get(`/members/quizzes?EventID=${reggieId}`)
    }

    function patchUpdateQuestionnaire(training: TrainingMgmt, auditors: string, attendance_rpt_rqd: boolean, os_arrival_time: string, os_location: string, os_shipping_address: string, os_shipping_instructions: string, os_instructor_byod: string, os_internet: boolean, os_instructor_internet: boolean, projector: boolean, os_projector_details: string, whiteboard: boolean, os_arrival_instructions: string, os_site_info_for_instructor: string, alternate_contact: string, imvp_platform: string, record_class: boolean, additional_info: string, exta_pq_question_1_answer: string, exta_pq_question_2_answer: string, exta_pq_question_3_answer: string): Promise<CoordinatorQuestionnaire> {
        return patch<CoordinatorQuestionnaire>("/members/coordinatorQuestionnaire", {
            "ID": training.reggieId,
            "Course_Name": training.name,
            "Course_Start_Date": training.startDate,
            "Course_End_Date": training.endDate,
            "Course_Time": training.time,
            "Course_Location": training.location,
            "Course_Code": training.code,
            "Course_Link": training.courseUrl,
            "Auditors": auditors,
            "Attendance_Rpt_Rqd": attendance_rpt_rqd ? "1" : "0",
            "OS_Arrival_Time": os_arrival_time,
            "OS_Location": os_location,
            "OS_Shipping_Address": os_shipping_address,
            "OS_Shipping_Instructions": os_shipping_instructions,
            "OS_Internet": os_internet ? "1" : "0",
            "OS_Instructor_BYOD": os_instructor_byod,
            "OS_Instructor_Internet": os_instructor_internet ? "1" : "0",
            "Projector": projector ? "1" : "0",
            "OS_Projector_Details": os_projector_details,
            "Whiteboard": whiteboard ? "1" : "0",
            "OS_Arrival_Instructions": os_arrival_instructions,
            "OS_Site_Info_For_Instructor": os_site_info_for_instructor,
            "Alternate_Contact": alternate_contact,
            "iMVP_Platform": imvp_platform,
            "Record_Class": record_class ? "1" : "0",
            "Additional_Info": additional_info,
            "Extra_PE_Answer_1": exta_pq_question_1_answer,
            "Extra_PE_Answer_2": exta_pq_question_2_answer,
            "Extra_PE_Answer_3": exta_pq_question_3_answer
        })
    }

    return {
        getCurrentMember,
        postLogin,
        postLoginHubID,
        postLogout,
        postForgotPassword,
        getQuotes,
        getNotifications,
        getCountries,
        getStates,
        patchUpdate,
        postCreate,
        getTraining,
        getTrainingData,
        getTrainingCalendar,
        getTrainingMgmtData,
        getTrainingCertificates,
        getTrainingMgmtFiles,
        downloadAttendanceRecords,
        getTrainingInstructorData,
        getContacts,
        getRoles,
        getRoster,
        postRoster,
        postRosterMultiple,
        downloadInstructorFile,
        delRoster,
        getAttendance,
        postValidateCode,
        postValidateCodeByHash,
        getEmailCode,
        postUpdateOpp,
        postConfirmHubID,
        getTestConnectionDetails,
        getStartedClassroomLink,
        getBannerAd,
        getInstructorFiles,
        getQuestionnaire,
        patchUpdateQuestionnaire,
        postUpdatePassword,
        getProgramEvents,
        getProgramOpps,
        getQuizzes
    }
}