import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { H3, View, Text, ActivityIndicator } from "../components/ui";
import { useContext, useEffect, useState } from "react";
import { PushNotificationsContext } from "../providers/PushNotificationsProvider";
import { Pressable } from "../components/ui/Pressable";

export default function PushNotificationsScreen() {
    const { onAskForPermission, askForPermission, ready } = useContext(PushNotificationsContext)
    const navigation = useNavigation<NativeStackNavigationProp<any>>()
    const [askingForPermission, setAskingForPermission] = useState(false);

    useEffect(() => {
        if (askForPermission && ready) {
            navigation.replace("Tabs")
        }
    }, [askForPermission, ready])

    function handleAskForPermission() {
        if (!ready) {
            setAskingForPermission(true)
            onAskForPermission()
        } else {
            navigation.replace("Tabs")
        }
    }

    function handleSkip() {
        navigation.replace("Tabs")
    }

    return (
        <View sx={{ flex: 1, padding: '$2', backgroundColor: '#fff', borderWidth: 1, borderColor: 'green' }}>
            <H3 sx={{ color: "$primary", textAlign: "center", textTransform: "uppercase" }}>Push Notifications</H3>
            <View sx={{ justifyContent: 'center', alignItems: 'center' }}>
                {askingForPermission ?
                    (
                        <ActivityIndicator sx={{ marginTop: '$4' }} size="large" color="$primary" />
                    ) : (
                        <>
                            <Pressable onPress={handleAskForPermission} sx={{ marginTop: '$4', marginBottom: '$2' }} version="buttons.primary">
                                <Text sx={{ textAlign: 'center', color: '#fff' }}>Allow Notifications</Text>
                            </Pressable>

                            <Pressable sx={{ backgroundColor: 'white' }} onPress={handleSkip}>
                                <Text sx={{ textAlign: 'center' }}>Skip for Now</Text>
                            </Pressable>
                        </>
                    )
                }
            </View >
        </View >
    )
}