import { useConfig } from '../../config/useConfig';
import { Text } from '../ui';

export default function ContactInfo() {
    const { brand, webBase } = useConfig()

    const phone = brand === 'accelebrate' ? '(844) 849-1850' : '1-844-275-4687'
    const emailDomain = webBase.replace("https://www.", "")

    return (
        <Text sx={{fontSize: '$3'}}>For assistance, contact {brand === "exitcertified" ? ("training-support@exitcertified.com") : (`support@${emailDomain}`)} or call {phone}</Text>
    )
}