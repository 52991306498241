import { useMediaQuery } from "react-responsive";
import { Training } from "../../models/TrainingData"
import ICalendarLink from "../../utils/Calendar";
import CalendarIcon from "svg/calendar-icon.svg"
import { View, Text } from "../../components/ui"
import { useConfig } from "../../config/useConfig";
import { BrandName } from "../../utils/Common";

interface CalendarButtonProps {
    training: Training,
    bottom?: boolean,
    programs?: Training[]
}
export default function CalendarButton(props: CalendarButtonProps) {
    const { training, programs, bottom } = props
    const isMobile = useMediaQuery({ query: '(max-width: 550px)' })
    const env = useConfig()

    var brandName = BrandName(env.brand);

    return (
        <>
            {training.calendarInviteEnabled && <ICalendarLink training={training} programs={programs} env={env} brand={brandName} style={{ textDecorationLine: 'none' }}>
                <View sx={{
                    zIndex: 10, margin: 0, flexDirection: 'row', cursor: 'pointer', minWidth: isMobile ? 170 : 200,
                    flexShrink: 1, justifyContent: 'flex-start', alignItems: 'center', marginTop: '$1', bg: '$primary', alignSelf: 'flex-start', paddingX: isMobile ? 5 : 12, paddingY: isMobile ? 5 : 12,
                    boxShadow: '1px 1px 3px #00000054', height: isMobile ? 40 : 48
                }}>
                    <View sx={{ flexDirection: 'row', flexShrink: 1, justifyContent: 'flex-start', alignItems: 'center', bg: '$primary', alignSelf: 'flex-start' }}>
                        <View sx={{ marginRight: '$2', marginTop: isMobile ? 3 : 0, marginBottom: 3, marginLeft: isMobile ? 4 : 0 }}>
                            <CalendarIcon height={24} width={24} />
                        </View>
                    </View>
                    <Text sx={{
                        paddingY: isMobile ? 5 : 5,
                        paddingX: isMobile ? 10 : 12,
                        fontSize: isMobile ? 12 : 16,
                        top: isMobile ? 8 : 10,
                        fontWeight: 500,
                        color: '$background',
                        textDecorationLine: 'none',
                        zIndex: 100,
                        position: 'absolute',
                        left: 35,
                        right: 0,
                        textAlign: 'center'
                    }}>Add To Calendar</Text>
                </View>
            </ICalendarLink>}
        </>
    )
}