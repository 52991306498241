import { createMaterialBottomTabNavigator } from "@react-navigation/material-bottom-tabs"
import { useDripsyTheme } from "dripsy"
import { useContext } from "react"
import { MemberDataContext } from "../providers/MemberDataProvider"
import NotificationsScreen from "../screens/NotificationsScreen"
import ProfileScreen from "../screens/ProfileScreen"
import QuotesScreen from "../screens/QuotesScreen"
import TrainingScreen from "../screens/TrainingScreen"
import TrainingTabSvgIcon from "svg/my-training.svg"
import QuotesTabSvgIcon from "svg/quotes.svg"
import NotificationsTabSvgIcon from "svg/notifications.svg"
import ProfileTabSvgIcon from "svg/manage-profile.svg"
import { useMember } from "../hooks/useMember";

const BottomTabs = createMaterialBottomTabNavigator()

export default function BottomTabsNavigator() {
    const { theme } = useDripsyTheme()
    const { trainingMode } = useMember()
    const { memberData: { unreadNotificationCount } } = useContext(MemberDataContext)
    const notificationBadge = unreadNotificationCount > 0 ? {
        tabBarBadge: unreadNotificationCount
    } : {}

    return (
        <BottomTabs.Navigator
            activeColor={theme.colors.$highlight}
            shifting={false}
            inactiveColor='#ffffff'
            barStyle={{ backgroundColor: theme.colors.$primary }}
        >
            <BottomTabs.Screen name="Training" component={TrainingScreen}
                options={{
                    tabBarIcon: ({ color, focused }) => <TrainingTabSvgIcon color={focused ? theme.colors.$highlight : color} />
                }}
            />
            { trainingMode!='instructor' && 
                <BottomTabs.Screen name="Quotes" component={QuotesScreen}
                    options={{
                        tabBarIcon: ({ color, focused }) => <QuotesTabSvgIcon color={focused ? theme.colors.$highlight : color} />
                    }}
                />
            }
            <BottomTabs.Screen name="Profile" component={ProfileScreen}
                options={{
                    tabBarIcon: ({ color, focused }) => <ProfileTabSvgIcon color={focused ? theme.colors.$highlight : color} />
                }}
            />
            <BottomTabs.Screen name="Notifications" component={NotificationsScreen}
                options={{
                    tabBarIcon: ({ color, focused }) => <NotificationsTabSvgIcon color={focused ? theme.colors.$highlight : color} />,
                    ...notificationBadge
                }}
            />
        </BottomTabs.Navigator>
    )
}