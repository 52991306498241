import { View } from "../ui";
import { Platform, Text } from "react-native";
import { useMember } from "../../hooks/useMember";
import { Pressable } from "../../components/ui/Pressable";
import { inIframe } from "../../utils/Common";

type LogoutButtonProps = {}

export default function LogOutButton(props: LogoutButtonProps) {
    const { logout, member } = useMember();

    var closeWord = 'LOGOUT';

    var textStyle:any = { color: '#ffffff' };
    var viewStyle:any = { flexDirection: 'row', justifyContent: 'center', marginTop: 50 };

    const LogOutButton = () => {
        return (
            <>
                { member && !inIframe() && (
                    <Pressable onPress={logout} version="buttons.primary">
                        <Text style={textStyle}>{closeWord}</Text>
                    </Pressable>
                )}
            </>
        )
    }

    return ( 
        <View sx={viewStyle}>
            <LogOutButton></LogOutButton>
        </View>
    )
}