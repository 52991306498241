import { useRef } from "react"
import Constants from "expo-constants"
import { nativeApplicationVersion } from "expo-application"
import * as Updates from 'expo-updates';
import { FlatList, ListRenderItemInfo, Linking, Platform } from "react-native"
import { H2, Text, View } from '../components/ui'
import ProfileForm from "../components/profile/ProfileForm";
import Member from "../models/Member";
import { Container, useDripsyTheme } from "dripsy";
import { useIsFocused, useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import ContactsView from "../components/profile/ContactsView";
import { MaterialTopTabScreenProps } from "@react-navigation/material-top-tabs";
import { Pressable } from "../components/ui/Pressable";
import { useConfig } from '../config/useConfig'
    
type ListItem = string

function ProfileScreen({navigation, route}: MaterialTopTabScreenProps<any>) {
    const flatListRef = useRef<FlatList<ListItem>>(null)
    const bundleVersion = Constants.expoConfig?.version
    const buildNumber = nativeApplicationVersion
    const stage = Constants.expoConfig?.extra?.STAGE
    const channel = Updates.channel
    const { theme } = useDripsyTheme()
    const isFocused = useIsFocused()
    const env = useConfig()

    const EditContacts = () => {
        Linking.openURL(`${env.webBase}/profile?tab=profile`);
    }

    function renderItem(info: ListRenderItemInfo<ListItem>) {
        const { item, index, separators } = info

        switch (item) {
            case 'profileForm': return (<><H2 sx={theme.styles.header}>Profile</H2><ProfileForm onSave={handleUpdate} showTab={route.params?.formTab} /></>)
            case 'buildInfo': return (<><H2 sx={theme.styles.header}>Build Info</H2>
                <View sx={{ flexDirection: 'column', padding: '$2' }}>
                    <Text>Bundle: {bundleVersion}</Text>
                    {Platform.OS !== 'web' && <Text>Binary: {buildNumber}</Text>}
                    <Text>Stage: {stage}</Text>
                    {Platform.OS !== 'web' && <Text>Channel: {channel}</Text>}
                </View>
            </>)
            case 'contacts': return (<><H2 sx={theme.styles.header}>Contacts</H2>
                <ContactsView />
                <Pressable onPress={EditContacts} sx={{ backgroundColor: 'transparent' }}>
                    <Text sx={{color: theme.colors.$primary, textAlign: 'center'}}>Edit Contacts</Text>
                </Pressable>
            </>)
            default: return null
        }
    }

    async function handleUpdate(member: Member) {
        console.log(member)
    }

    if (!isFocused) {
        return null
    }

    let viewSections = [
        'profileForm'
    ]
    
    if (Platform.OS === 'web') {
        viewSections.push('contacts')   
    }
    viewSections.push('buildInfo')

    return (
        <FlatList
            ref={flatListRef}
            data={viewSections}
            bounces={false}
            renderItem={renderItem}
            keyExtractor={(item, index) => index.toString()}
            style={{ padding: theme.space.$2, backgroundColor: '#fff' }}
        />
    )
}

export default ProfileScreen
