import { useContext } from "react"
import { TrainingDataContext } from "../providers/TrainingProvider"
import { useMemberApi } from "../api/useMemberApi"

export function useTraining() {
    const { postUpdateOpp, getStartedClassroomLink, getProgramEvents, getProgramOpps, getTraining, getQuizzes } = useMemberApi()
    const { loading, trainingData, trainingCalendar, refreshTrainingData, updateTraining, getTrainingCalendarEvents } = useContext(TrainingDataContext)

    async function updateOpp(sfId: string, key: string, val: string | boolean): Promise<void> {
        await postUpdateOpp(sfId, key, val)
    }

    return { loading, trainingData, trainingCalendar, getTraining, refreshTrainingData, updateOpp, updateTraining, getStartedClassroomLink, getProgramEvents, getProgramOpps, getQuizzes, getTrainingCalendarEvents }

}