
import { useState } from 'react'
import { TrainingData } from "../../models/TrainingData";
import { TrainingMgmtData } from "../../models/TrainingMgmtData";
import { TrainingInstructorData } from "../../models/TrainingInstructorData";
import { modalHeight, modalWidth } from '../../utils/Common'
import { useMember } from "../../hooks/useMember";
import { Text, View } from '../../components/ui'
import { Pressable } from "../ui/Pressable"
import NoticeIcon from "svg/icon-notice.svg";
import Modal from "react-native-modal"
import { useDripsyTheme } from "dripsy";

interface NoTrainingMessageProps {
    trainingData: TrainingData
    loading: boolean
}

export default function NoTrainingMessage(props: NoTrainingMessageProps) {
    const [noTrainingVisible, setNoTrainingVisible] = useState('');
    const { trainingData, loading } = props
    const { logout } = useMember()
    const { theme } = useDripsyTheme()

    if (!loading && trainingData && trainingData.active.length == 0 && trainingData.future.length == 0 && trainingData.past.length == 0 && !trainingData.hasPast && noTrainingVisible == '') {
        setTimeout(() => { setNoTrainingVisible('true'); }, 300);
    }

    return <Modal isVisible={noTrainingVisible == 'true'}>
        <View sx={{ alignItems: 'center' }}>
            <View sx={{ bg: '$primary', color: 'white', maxHeight: modalHeight(), width: modalWidth(), maxWidth: 770, flexDirection: "column", justifyContent: "center", alignItems: "center", paddingY: '$2', borderRadius: 4 }}>
                <Text sx={{ color: 'white', fontSize: 18, textAlign: 'center', fontWeight: 500, padding: '$5' }}>
                    <NoticeIcon fill="white" style={{ width: 53, position: 'relative', top: 4 }} />
                    <Text sx={{ display: 'block', marginTop: 20 }}>This login does not have any classes associated with it.</Text>
                    <Text sx={{ display: 'block', marginTop: 20 }}>Please create a login with the email address that you registered with.</Text>
                    <Text sx={{ display: 'block', marginTop: 20 }}>To create your new login, click "Sign Out" and then click "Sign Up!" on the login page.</Text>
                    <Text sx={{ display: 'block', marginTop: 20 }}>Send us a chat message if you need assistance.</Text>
                </Text>
            </View>
            <View sx={{ flexDirection: "row", justifyContent: "center" }}>
                <Pressable sx={{ margin: 0, marginTop: 11, marginRight: 5.5, minWidth: 91 }} onPress={() => setNoTrainingVisible('false')} version="buttons.gray">
                    <Text sx={{ color: 'white' }}>Close</Text>
                </Pressable>
                <Pressable sx={{ margin: 0, marginTop: 11, marginLeft: 5.5, minWidth: 91 }} onPress={() => logout()} version="buttons.gray">
                    <Text sx={{ color: 'white' }}>Sign Out</Text>
                </Pressable>
            </View>
        </View>
    </Modal>
}