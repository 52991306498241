import { createContext, useEffect, useState } from "react";
import { useMemberApi } from "../api/useMemberApi";
import MissingProvider from "../utils/MissingProvider";
import { CoordinatorQuestionnaire, RosterInvitee, Attendance, TrainingMgmt, TrainingMgmtData } from "../models/TrainingMgmtData";

type TrainingMgmtDataKeys = {
    active: string,
    future: string,
    past: string
}

interface TrainingMgmtDataContextType {
    loading: boolean
    trainingMgmtData: TrainingMgmtData | undefined
    roster: RosterInvitee[]
    attendance: Attendance | undefined
    questionnaire: CoordinatorQuestionnaire | undefined
    refreshTrainingMgmtData: (start?: string, end?: string) => Promise<void>
    updateTrainingMgmt: (id: string, values: { [key: string]: any }) => void
    fetchQuestionnaire: (eventId: number) => Promise<void>
    fetchRoster: (oppId: string, eventId?: number) => Promise<void>
    removeRoster: (oppId: string) => Promise<void>
    fetchAttendance: (eventId: number) => Promise<void>
    updateRoster: (oppId: string, firstName: string, lastName: string, email: string, eventId?: number) => Promise<void>
    updateRosterMultiple: (oppId: string, roster: { [key: string]: string }[]) => Promise<void>
}

export const TrainingMgmtDataContext = createContext<TrainingMgmtDataContextType>({
    loading: false,
    trainingMgmtData: { active: [], future: [], past: [] },
    roster: [],
    attendance: undefined,
    questionnaire: undefined,
    refreshTrainingMgmtData: async (start, end) => { MissingProvider() },
    updateTrainingMgmt: (id, values) => MissingProvider,
    fetchQuestionnaire: async (eventId) => { MissingProvider() },
    fetchRoster: async (oppId, eventId?) => { MissingProvider() },
    removeRoster: async (oppId) => { MissingProvider() },
    fetchAttendance: async (eventId) => { MissingProvider() },
    updateRoster: async (oppId, firstName, lastName, email, eventId?) => { MissingProvider() },
    updateRosterMultiple: async (oppId, roster) => { MissingProvider() },
})

interface TrainingMgmtProviderProps {
    children?: React.ReactNode
}

function TrainingMgmtProvider({ children }: TrainingMgmtProviderProps) {
    const { getTrainingMgmtData, getRoster, getQuestionnaire, getAttendance, postRoster, delRoster, postRosterMultiple } = useMemberApi()
    const [trainingMgmtData, setTrainingMgmtData] = useState<TrainingMgmtData>()
    const [roster, setRoster] = useState<RosterInvitee[]>([])
    const [attendance, setAttendance] = useState<Attendance>()
    const [questionnaire, setQuestionnaire] = useState<CoordinatorQuestionnaire>()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        async function loadAsync() {
            try {
                setLoading(true)
                const trainingDataList = await getTrainingMgmtData()
                setTrainingMgmtData(trainingDataList)
            } catch (err: any) {
                console.error("Error fetching training management data", err.toString())
            } finally {
                setLoading(false)
            }
        }
        loadAsync()
    }, [])

    async function refreshTrainingMgmtData(start?: string, end?: string) {
        try {
            setLoading(true)
            setTrainingMgmtData(undefined)
            const trainingDataList = await getTrainingMgmtData(start, end);
            setTrainingMgmtData(trainingDataList);
        } catch (err: any) {
            console.error("Error refreshing training management data", err.toString())
        } finally {
            setLoading(false)
        }
    }

    async function fetchRoster(oppId: string, eventId?: number) {
        try {
            setLoading(true)
            const rosterList = await getRoster(oppId, eventId)
            setRoster(rosterList)
        } catch (err: any) {
            console.error("Error roster data", err.toString())
        } finally {
            setLoading(false)
        }
    }

    async function fetchQuestionnaire(eventId: number) {
        try {
            setLoading(true)
            const questionnaire = await getQuestionnaire(eventId)
            setQuestionnaire(questionnaire)
        } catch (err: any) {
            console.error("Error questionnaire data", err.toString())
        } finally {
            setLoading(false)
        }
    }

    async function fetchAttendance(eventId?: number) {
        try {
            setLoading(true)
            const attendanceList = await getAttendance(eventId)
            setAttendance(attendanceList)
        } catch (err: any) {
            console.error("Error roster data", err.toString())
        } finally {
            setLoading(false)
        }
    }

    async function updateRoster(oppId: string, firstName: string, lastName: string, email: string, eventId?: number): Promise<void> {
        const rosterAttendee = await postRoster(oppId, firstName, lastName, email, eventId)
        roster.push(rosterAttendee)
    }

    async function updateRosterMultiple(oppId: string, addroster: { [key: string]: string }[]): Promise<void> {
        const rosterAttendees = await postRosterMultiple(oppId, addroster)
        roster.push(...rosterAttendees)
    }

    async function removeRoster(oppId: string) {
        const newRoster = roster.filter((invitee) => invitee.oppId !== oppId)
        setRoster(newRoster)
        await delRoster(oppId)
    }

    function updateTrainingMgmt(id: string, values: { [key: string]: any }) {

        let k: keyof TrainingMgmtDataKeys

        if (trainingMgmtData) {
            for (k in trainingMgmtData) {
                const trainingList: TrainingMgmt[] = trainingMgmtData[k]
                for (const i in trainingList) {
                    let training = trainingList[i]
                    if (training.id === id) {
                        training = { ...training, ...values }
                        trainingList[i] = training
                    }
                }
            }
        }
    }

    return (
        <TrainingMgmtDataContext.Provider value={{ loading, trainingMgmtData, roster, questionnaire, attendance, fetchAttendance, refreshTrainingMgmtData, updateTrainingMgmt, fetchRoster, fetchQuestionnaire, updateRoster, removeRoster, updateRosterMultiple }}>
            {children}
        </TrainingMgmtDataContext.Provider>
    )
}

export default TrainingMgmtProvider