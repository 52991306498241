import { useDripsyTheme } from "dripsy"
import { Image, View, Text, H1 } from "../../components/ui"
import { useConfig } from "../../config/useConfig"
import { Training } from "../../models/TrainingData"
import { ImageBackground, StyleSheet } from "react-native"

const bgImg = require('../../../assets/hero-bg.jpg');
const logo = require('../../../assets/training-hub-logo.png');

interface EventHeaderProps {
    training: Training
}

export function EventHeader(props: EventHeaderProps) {
    const env = useConfig()
    const { theme } = useDripsyTheme()
    const { training } = props

    return (
        <ImageBackground
            source={env.appTheme && env.appTheme.heroBG ? env.appTheme.heroBG : bgImg}
            resizeMode="cover"
            style={{ paddingBottom: 14 }}
        >
            {training.name != '' && training.name.length > 0 && <H1 sx={theme.trainingHeader.header}>{training.name}</H1>}
            {training.customDate != '' && training.customDate.length > 0 ? (
                <Text sx={theme.trainingHeader.subheader}>Date: <Text sx={{ fontWeight: 'bold' }}>{training.customDate}</Text></Text>
            ) : (
                <Text sx={theme.trainingHeader.subheader}>Date: <Text sx={{ fontWeight: 'bold' }}>{training.startDate.toString()}</Text> - <Text sx={{ fontWeight: 'bold' }}>{training.endDate.toString()}</Text></Text>
            )}
            {(training.time && !training.program && training.sessionCount === 0) && <Text sx={theme.trainingHeader.subheader}>Time: <Text sx={{ fontWeight: 'bold' }}>{training.time}</Text></Text>}
            {training.location && <Text sx={theme.trainingHeader.subheader}>Location: <Text sx={{ fontWeight: 'bold' }}>{training.location}</Text></Text>}
            {training.instructor.length > 0 && <Text sx={theme.trainingHeader.subheader}>Instructor: <Text sx={{ fontWeight: 'bold' }}>{training.instructor}</Text></Text>}
        </ImageBackground>
    )
}