import { Training } from "../../models/TrainingData";
import { View, Text } from '../ui';
import { useDripsyTheme } from "dripsy";
import { useMediaQuery } from "react-responsive";
import { Platform } from "react-native";
import { TrainingMgmt } from "../../models/TrainingMgmtData";
import { TrainingInstructor } from "../../models/TrainingInstructorData";
import { Event } from "../../models/Event";

export default function CourseInfo({ tableView, training }: { tableView: boolean, training: Training | TrainingMgmt | TrainingInstructor | Event }) {
    const { theme } = useDripsyTheme()
    const isWebAndDesktop = useMediaQuery({ query: '(min-width: 768px)' }) && Platform.OS === 'web'

    const WebView = () => {
        var traininginstructor = training as TrainingInstructor;
        return (
            <View sx={{ padding: '$0', flexDirection: 'row', justifyContent: 'center' }}>
                <View sx={theme.styles.tableColumn}>
                    <Text sx={theme.styles.rowHeader}>Course Title</Text>
                    <View sx={theme.styles.rowContent}>
                        <Text sx={{ color: '$primary', fontWeight: 'bold' }}>{training.name}</Text>
                    </View>
                </View>
                {training.customDate.length > 0 ? (
                    <View sx={theme.styles.tableColumn}>
                        <Text sx={theme.styles.rowHeader}>Date</Text>
                        <View sx={theme.styles.rowContent}><Text>{training.customDate}</Text></View>
                    </View>
                ) : (
                    <>
                        <View sx={theme.styles.tableColumn}>
                            <Text sx={theme.styles.rowHeader}>Start Date</Text>
                            <View sx={theme.styles.rowContent}><Text>{training.startDate.toString()}</Text></View>
                        </View>
                        <View sx={theme.styles.tableColumn}>
                            <Text sx={theme.styles.rowHeader}>End Date</Text>
                            <View sx={theme.styles.rowContent}><Text>{training.endDate.toString()}</Text></View>
                        </View>
                    </>
                )}
                <View sx={theme.styles.tableColumn}>
                    <Text sx={theme.styles.rowHeader}>Time</Text>
                    <View sx={theme.styles.rowContent}><Text>{training.time && `${training.time}`}</Text></View>
                </View>
                {(training.location !== undefined && training.location.length > 0) &&
                    <View sx={theme.styles.tableColumn}>
                        <Text sx={theme.styles.rowHeader}>Location</Text>
                        <View sx={theme.styles.rowContent}><Text>{training.location}</Text></View>
                    </View>
                }
                {(training.instructor !== undefined && training.instructor.length > 0) &&
                    <View sx={theme.styles.tableColumn}>
                        <Text sx={theme.styles.rowHeader}>Instructor</Text>
                        <View sx={theme.styles.rowContent}><Text>{training.instructor}</Text></View>
                    </View>
                }
                {((traininginstructor.status?.length > 0) &&
                    <View sx={theme.styles.tableColumn}>
                        <Text sx={theme.styles.rowHeader}>Status</Text>
                        <View sx={theme.styles.rowContent}><Text>{traininginstructor.status}</Text></View>
                    </View>
                )}
            </View>
        )
    }

    const MobileView = () => {
        var traininginstructor = training as TrainingInstructor;
        return (
            <View sx={{ padding: '$0', flexDirection: 'row', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                <View sx={{ ...theme.styles.courseInfoItem, width: '100%', paddingTop: '$3' }}>
                    <Text sx={{ color: '$primary' }}>Course Title</Text>
                    {training.name && <Text sx={{ color: '$primary', fontWeight: 'bold' }}>{training.name}</Text>}
                </View>
                {training.customDate.length > 0 ? (
                    <View sx={theme.styles.courseInfoItem}>
                        <Text sx={{ color: '$primary' }}>Date</Text>
                        <Text sx={{ color: '$primary', fontWeight: 'bold' }}>{training.customDate}</Text>
                    </View>
                ) : (
                    <>
                        <View sx={theme.styles.courseInfoItem}>
                            <Text sx={{ color: '$primary' }}>Start Date</Text>
                            {training.startDate && <Text sx={{ color: '$primary', fontWeight: 'bold' }}>{training.startDate.toString()}</Text>}
                        </View>
                        <View sx={theme.styles.courseInfoItem}>
                            <Text sx={{ color: '$primary' }}>End Date</Text>
                            {training.endDate && <Text sx={{ color: '$primary', fontWeight: 'bold' }}>{training.endDate.toString()}</Text>}
                        </View>
                    </>
                )}
                <View sx={theme.styles.courseInfoItem}>
                    <Text sx={{ color: '$primary' }}>Time</Text>
                    {training.time && <Text sx={{ color: '$primary', fontWeight: 'bold' }}>{training.time}</Text>}
                </View>
                {(training.location !== undefined && training.location.length > 0) &&
                    <View sx={theme.styles.courseInfoItem}>
                        <Text sx={{ color: '$primary' }}>Location</Text>
                        <Text sx={{ color: '$primary', fontWeight: 'bold' }}>{training.location}</Text>
                    </View>
                }
                {(training.instructor !== undefined && training.instructor.length > 0) &&
                    <View sx={{ ...theme.styles.courseInfoItem, paddingBottom: '$3' }}>
                        <Text sx={{ color: '$primary' }}>Instructor</Text>
                        <Text sx={{ color: '$primary', fontWeight: 'bold' }}>{training.instructor}</Text>
                    </View>
                }
                {((traininginstructor.status?.length > 0) &&
                    <View sx={{ ...theme.styles.courseInfoItem, paddingBottom: '$3' }}>
                        <Text sx={{ color: '$primary' }}>Status</Text>
                        <Text sx={{ color: '$primary', fontWeight: 'bold' }}>{traininginstructor.status}</Text>
                    </View>
                )}
            </View>
        )
    }

    return (
        <>
            {!tableView ? (
                <MobileView />
            ) : (
                isWebAndDesktop ? (
                    <WebView />
                ) : (
                    <MobileView />
                )
            )}
        </>
    )
}