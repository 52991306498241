import { useEffect, useState } from "react";
import { Container, H3, Text, View } from "../components/ui";
import { useTraining } from "../hooks/useTraining";
import { Platform } from "react-native";
import { UtilityScreenProps } from "../navigation/types";
import TestConnectionView from "../components/training/TestConnectionView";

export default function GetStartedScreen({ navigation, route }: UtilityScreenProps<'GetStarted'>) {
    const { getStartedClassroomLink } = useTraining()
    const [error, setError] = useState("")
    const [platform, setPlatform] = useState("")
    const [oppId, setOppId] = useState("")

    useEffect(() => {
        async function loadAsync() {
            if (Platform.OS === 'web' && route.params) {

                if (route.params.action === 'classroom') {
                    try {
                        const res = await getStartedClassroomLink(route.params.id);
                        window.location.replace(res.redirectUrl)
                    } catch (err: any) {
                        setError(err.toString())
                    }
                } else if (route.params.id) {
                    setOppId(route.params.id)
                    if (route.params.platform) {
                        setPlatform(route.params.platform)
                    }
                } else {
                    setError("Please check your link. It should be in the form /get-started/classroom/<ReggieID>")
                }
            } else {
                setError("This is not a valid url")
            }
        }

        loadAsync()
    }, [])

    return (
        <View sx={{ flex: 1, padding: '$2', backgroundColor: '#fff' }}>
            <Container variant="form" sx={{ marginY: '$4' }}>
                {error.length > 0 ? (
                    <Text sx={{ color: '$error', fontWeight: 'bold', padding: '$2', borderRadius: 5 }}>
                        Sorry, there was an error. {error}
                    </Text>
                ) : (
                    <>
                        {oppId ? (
                            <TestConnectionView opportunityId={oppId} platform={platform} />
                        ) : (
                            <Text sx={{ color: '$primary', fontWeight: 'bold', padding: '$2', borderRadius: 5 }}>
                                Redirecting to your class, please wait ...
                            </Text>
                        )}
                    </>
                )}
            </Container>
        </View>
    )
}