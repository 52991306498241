import { Container, useDripsyTheme } from "dripsy";
import { FlatList, ListRenderItem } from "react-native";
import { useMember } from "../../hooks/useMember";
import Contact from "../../models/Contact";
import { H1, H2, ScrollView, Text, View } from '../../components/ui'
import { useEffect } from "react";

export default function ContactsView() {
    const { loading, contacts, fetchContacts } = useMember()
    const { theme } = useDripsyTheme()

    useEffect(() => {
        async function loadAsync() {
            console.log("Fetching contacts")
            try {
                await fetchContacts()
            } catch (err: any) {
                console.warn(err.toString())
            }
        }

        loadAsync()
    }, [])

    const Header = () => (
        <Container>
            <View sx={{ flexFlow: 'row nowrap', justifyContent: 'flex-start' }}>
                <Text sx={{ ...theme.styles.rowHeader, minWidth: '50%', textAlign: 'left' }}> Name</Text>
                <Text sx={{ ...theme.styles.rowHeader, minWidth: '50%', textAlign: 'left' }}>Email</Text>
            </View>
        </Container >
    )

    const ContactView = ({ contact }: { contact: Contact }) => (
        <Container>
            <View sx={{ ...theme.styles.rowContent, flexFlow: 'row nowrap', justifyContent: 'flex-start' }}>
                <Text sx={{ minWidth: '50%', textAlign: 'left' }}>{contact.firstName} {contact.lastName}</Text>
                <Text sx={{ minWidth: '50%', textAlign: 'left' }}>{contact.email}</Text>
            </View>
        </Container >
    )

    return (
        <div className="contactlist">
        <FlatList
            data={contacts}
            bounces={false}
            renderItem={({ item }) => <ContactView contact={item as Contact} />}
            ListHeaderComponent={<Header />}
            keyExtractor={(item) => item.id}
            style={{ padding: theme.space.$2, backgroundColor: '#fff' }}
        />
        </div>
    )
}