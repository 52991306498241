import { useDripsyTheme } from 'dripsy';
import { useEffect, useState } from "react";
import { View, Text, H2, H3, ScrollView, ActivityIndicator } from '../ui';
import { AntDesign } from '@expo/vector-icons';
import { Pressable } from '../ui/Pressable';
import { Certificates } from '../../models/TrainingMgmtData';
import { useMediaQuery } from "react-responsive";
import { Dimensions, Linking } from "react-native";
import { useTrainingMgmt } from "../../hooks/useTrainingMgmt"
import { saveAs } from '@progress/kendo-file-saver';

interface CertificatesViewProps {
    id: number,
    parentId: number,
    oppId: string,
    title: string
}

export default function CertificatesView(props: CertificatesViewProps) {
    const { getTrainingCertificates } = useTrainingMgmt()
    const { theme } = useDripsyTheme();
    const [trainingCertificates, setCertificates] = useState<Certificates[]>([])
    const [loading, setLoading] = useState(false)
    const windowSize: any = Dimensions.get('window').height - 200;
    const isMobile = useMediaQuery({ query: '(max-width: 400px)' });
    const { id, oppId, title, parentId } = props;

    useEffect(() => {
        async function loadAsync() {
            setLoading(true)
            try {
                const certs = await getTrainingCertificates(id, parentId, oppId)
                setCertificates(certs)
            } catch (err: any) {
                console.error("Error fetching training certificates.", err.toString())
            } finally {
                setLoading(false)
            }
        }

        loadAsync()
    }, [])

    const Certifications = () => {
        return (
            <View sx={{ width: '100%' }}>
                <H3 sx={{ marginBottom: '$3' }}>{title}</H3>
                <View sx={{
                    flexDirection: 'row', justifyContent: 'space-between', paddingVertical: '$2',
                    paddingHorizontal: '$3', alignItems: 'center', backgroundColor: '$primary'
                }}>
                    <Text sx={{ fontSize: 14, color: '#FFF', fontWeight: 'bold', paddingRight: '$5', flexBasis: '20%' }}>First Name</Text>
                    <Text sx={{ fontSize: 14, color: '#FFF', fontWeight: 'bold', paddingRight: '$5', flexBasis: '20%' }}>Last Name</Text>
                    <Text sx={{ fontSize: 14, color: '#FFF', fontWeight: 'bold', paddingRight: '$5', flexBasis: '44%' }}>Email</Text>
                    <Text sx={{ fontSize: 14, color: '#FFF', fontWeight: 'bold', flexBasis: '16%' }}></Text>
                </View>
                {trainingCertificates.map((certif, index) => (
                    <View key={index} sx={{
                        flexDirection: 'row', justifyContent: 'space-between', paddingVertical: '$2',
                        paddingX: isMobile ? 7 : '$3', alignItems: 'center', backgroundColor: '$gray',
                        borderLeftWidth: 1, borderColor: '$primary',
                        borderBottomWidth: 1,
                        borderRightWidth: 1
                    }}>
                        <Text sx={{ fontSize: 14, color: '$primary', paddingRight: '$5', flexBasis: '20%' }}>{certif.firstName}</Text>
                        <Text sx={{ fontSize: 14, color: '$primary', paddingRight: '$5', flexBasis: '20%' }}>{certif.lastName}</Text>
                        <Text sx={{ fontSize: 14, color: '$primary', paddingRight: '$5', flexBasis: '44%' }}>{certif.email}</Text>
                        <View sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flexBasis: '16%' }}>
                            <Pressable sx={{ margin: 'auto' }} onPress={() => Linking.openURL(certif.certificationUrlExternal)}>
                                <AntDesign name="clouddownloado" size={32} color={theme.colors.$primary} />
                            </Pressable>
                        </View>
                    </View>
                ))}
            </View>
        )
    }

    if (loading) {
        return <ActivityIndicator sx={{ marginTop: '$4' }} size="large" color="$primary" />
    }

    return (
        <>
            <ScrollView sx={{ paddingBottom: theme.space.$5, paddingX: theme.space.$3, maxHeight: windowSize, minWidth: theme.sizes.labMinWidth }}>
                <H2 sx={theme.styles.header}>Certificates of Completion</H2>
                <View sx={{ alignItems: 'center' }}>
                    {trainingCertificates.length > 0 ? (
                        <Certifications />
                    ) : (
                        <Text sx={{
                            padding: 20,
                            fontStyle: 'italic',
                            color: '#a6a6a7'
                        }}>No Certificates Found</Text>
                    )}
                </View>
            </ScrollView>
        </>
    )
}