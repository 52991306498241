import { Linking, Dimensions, Platform } from 'react-native'
import { View, Image } from './';
import { useEffect, useState } from "react";
import { useMember } from "../../hooks/useMember";
import { Pressable } from "../ui/Pressable";
import { useQuery } from '@tanstack/react-query';
import { useMemberApi } from '../../api/useMemberApi';
import { useConfig } from '../../config/useConfig';
import { useTraining } from '../../hooks/useTraining';

export default function BannerAd() {
    const { getBannerAd } = useMemberApi()
    const { isPending, isError, data, error } = useQuery({ queryKey: ['bannerAd'], queryFn: () => getBannerAd(brand), staleTime: Infinity })
    const [mobileMode, setMobileMode] = useState(false)
    const [windowWidth, setWindowWidth] = useState(0)
    const { brand } = useConfig()

    useEffect(() => {
        detectMode();
    }, []);

    const onOpenBannerAd = (link: string) => {
        Linking.openURL(link);
    }

    const detectMode = () => {
        var win = Dimensions.get("window").width;
        setWindowWidth(win);
        setMobileMode(win < 760 ? true : false);
    }

    window.onresize = function (event) {
        detectMode();
    }

    var btnStyl: any = { margin: 'auto', width: '100%' };
    if (Platform.OS != 'android') {
        btnStyl = { display: 'inline', ...btnStyl };
    }

    return (
        <View sx={{ paddingTop: '$4' }}>
            {data?.map((ad: any, i: any) => ((mobileMode ? ad.mobile != '' ? true : false : ad.desktop != '' ? true : false) &&
                <View key={i} sx={{ paddingTop: '$2', textAlign: '-webkit-center' }}>
                    <Pressable onPress={() => onOpenBannerAd(ad.url)} sx={btnStyl}>
                        <Image
                            accessibilityActions={[{ name: ad.title, label: ad.alt }]}
                            source={{ uri: mobileMode ? ad.mobile : ad.desktop }}
                            resizeMethod="scale"
                            resizeMode={Platform.OS == 'ios' || Platform.OS == 'android' ? "cover" : "center"}
                            sx={{ height: mobileMode ? 400 : 250, maxWidth: mobileMode ? 400 : 960 }} />
                    </Pressable>
                </View>
            ))}
        </View>
    )
}