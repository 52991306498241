import { useDripsyTheme } from "dripsy";
import { useEffect } from "react";
import { Linking, RefreshControl, SectionList } from "react-native";
import { H2, H3, View, Text, ScrollView } from "../components/ui";
import { Pressable } from "../components/ui/Pressable";
import { TableWrapper } from "../components/ui/TableWrapper";
import { useMember } from "../hooks/useMember";
import Quote from "../models/Quote";

export default function QuotesScreen() {
    const { loading, quotes, fetchQuotes } = useMember()
    const { theme } = useDripsyTheme()

    useEffect(() => {
        async function loadAsync() {
            console.log("Fetching quotes data")
            try {
                await fetchQuotes()
            } catch (err: any) {
                console.warn(err.toString())
            }
        }

        loadAsync()
    }, [])

    function handleViewQuote(url: string) {
        Linking.openURL(url)
    }

    function handleAddToCartQuote(url: string) {
        Linking.openURL(url)
    }

    let activeQuotes: Quote[] = []
    let expiredQuotes: Quote[] = []

    quotes.map((quote,i) => {
        const quotesCreatedMiliSeconds = Date.parse(quote.created)
        const thirtyDayMiliSeconds = 30 * (24 * 60 * 60 * 1000)
        const todayMiliSeconds = (new Date()).getTime()

        if ((todayMiliSeconds - thirtyDayMiliSeconds) > quotesCreatedMiliSeconds) {
            expiredQuotes.push(quote)
        } else {
            activeQuotes.push(quote)
        }
    })

    const DATA = [
        {
            title: 'Active Quotes',
            data: activeQuotes
        },
        {
            title: 'Expired Quotes',
            data: expiredQuotes
        }
    ]

    const QuoteTable = ({title, quotes}: {title: string, quotes: Quote[]}) => {
        return (
            <>
            <H2 sx={theme.styles.header}>{title}</H2>
            {quotes.length ? (
                <TableWrapper>
                    <View sx={theme.styles.tableColumn}>
                        <Text sx={theme.styles.rowHeader}>Company</Text>
                        {quotes.map((quote, index) => <View key={index} sx={theme.styles.rowContent}><Text>{quote.company ? quote.company : "N/A"}</Text></View>)}
                    </View>
                    <View sx={theme.styles.tableColumn}>
                        <Text sx={theme.styles.rowHeader}>Partner</Text>
                        {quotes.map((quote, index) => <View key={index} sx={theme.styles.rowContent}><Text>{quote.partner}</Text></View>)}
                    </View>
                    <View sx={theme.styles.tableColumn}>
                        <Text sx={theme.styles.rowHeader}>Cost</Text>
                        {quotes.map((quote, index) => <View key={index} sx={theme.styles.rowContent}><Text>{quote.total} ({quote.currency})</Text></View>)}
                    </View>
                    <View sx={theme.styles.tableColumn}>
                        <Text sx={theme.styles.rowHeader}>Created</Text>
                        {quotes.map((quote, index) => <View key={index} sx={theme.styles.rowContent}><Text>{quote.created}</Text></View>)}
                    </View>
                    <View sx={theme.styles.tableColumn}>
                        <Text sx={theme.styles.rowHeader}>Actions</Text>
                        {title === 'Active Quotes' ? (
                            <>
                                {quotes.map((quote, index) => <View key={index} sx={{ paddingTop: '$0', paddingBottom: '$0', ...theme.styles.rowContent }}>
                                    <Pressable onPress={() => handleViewQuote(quote.link)} version="buttons.primary">
                                        <Text sx={{ color: '#fff' }}>View Quote</Text>
                                    </Pressable>
                                    <Pressable onPress={() => handleAddToCartQuote(quote.addToCartLink)} version="buttons.primary">
                                        <Text sx={{ color: '#fff' }}>Add To Cart</Text>
                                    </Pressable>
                                </View>)}
                            </>
                        ):(
                            <>
                                {quotes.map((quote, index) => <View key={index} sx={{ paddingTop: '$0', paddingBottom: '$0', ...theme.styles.rowContent }}>
                                    <Pressable onPress={() => handleViewQuote(quote.courseLink)} version="buttons.primary">
                                        <Text sx={{ color: '#fff' }}>Re-Quote</Text>
                                    </Pressable>
                                </View>)}
                            </>
                        )}
                    </View>

                </TableWrapper>
            ) : (<H3>No Quotes</H3>)}
            </>
        )
    }

    return (
        <View sx={{ flex: 1, padding: '$2', backgroundColor: '#fff' }}>
            <SectionList
                sections={DATA}
                stickySectionHeadersEnabled={false}
                keyExtractor={(item, index) => `${item.id}`}
                renderItem={({ item, section }) => (<></>)}
                renderSectionHeader={({ section: { title, data } }) => <QuoteTable title={title} quotes={data} />}
                renderSectionFooter={({ section: { title } }) => <View sx={{ borderBottomColor: '$gray', borderBottomWidth: 0 }}></View>}
                refreshing={loading}
                onRefresh={fetchQuotes}
                ListEmptyComponent={<H3>No Quotes Found</H3>}
            />
        </View>
    )
}