import { useDripsyTheme } from 'dripsy';
import { Linking, Platform, Dimensions } from 'react-native';
import { View } from './ui';
import { getFormattedWebViewHtml } from '../utils/Common';
import WebView from "react-native-webview";

export function WebViewer({ html, heightDivision = 1, scrollEnabled = false, autoHeight = false }: { html?: any, heightDivision?: number, scrollEnabled?: boolean, autoHeight?: boolean }) {
    const { theme } = useDripsyTheme()
    const windowSize: any = Dimensions.get('window').height - 200;

    let webview: any = null;
    const shouldStartLoadWithRequest = (req: any) => {
        if (req.url != 'about:blank') {
            Linking.openURL(req.url);
            webview.stopLoading();
        }
    };

    const scalesPageToFit = Platform.OS === 'android';
    return (
        <>
            {Platform.OS === 'web' ? (
                <View sx={{ alignItems: 'stretch', width: '100%', overflow: 'hidden', height: autoHeight ? 'auto' : '100%' }}>
                    <div dangerouslySetInnerHTML={{ __html: html }} />
                </View>
            ) : (
                <View sx={{ maxHeight: windowSize / heightDivision }}>
                    <WebView
                        scalesPageToFit={scalesPageToFit}
                        bounces={false}
                        scrollEnabled={scrollEnabled}
                        style={{ width: 300, height: 'auto' }}
                        originWhitelist={['*']}
                        ref={ref => (webview = ref)}
                        onNavigationStateChange={shouldStartLoadWithRequest}
                        source={{ html: getFormattedWebViewHtml(html) }} />
                </View>
            )}
        </>
    )
}