import { Linking, Alert, Dimensions, Platform } from 'react-native'
import { useConfig } from '../config/useConfig'
import { useMediaQuery } from 'react-responsive'
import { TrainingMgmt } from '../models/TrainingMgmtData';

export function getFormattedWebViewHtml(content: string): string {
    return `
        <!DOCTYPE html>
        <html>
        <head>
            <title>Web View</title>
            <meta http-equiv="content-type" content="text/html; charset=utf-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <style type="text/css">
            body {
                margin: 0;
                padding: 0;
                font-family: "Open Sans", Helvetica, sans-serif;
                /* These are technically the same, but use both */
                overflow-wrap: break-word;
                word-wrap: break-word;

                -ms-word-break: break-all;
                /* This is the dangerous one in WebKit, as it breaks things wherever */
                word-break: break-all;
                /* Instead use this non-standard one: */
                word-break: break-word;
                height: 100vh;
            }
            </style>
        </head>
        <body>
            ${content}
        </body>
        </html>
    `;
}

export function inIframe() {
    if (Platform.OS === 'web') {
        try {
            return window.self !== window.top
        } catch (e) {
            return true
        }
    }

    return false;
}

export const BrandName = (brand: string) => {
    if (brand == 'exitcertified') {
        return "ExitCertified";
    } else if (brand == 'accelebrate') {
        return "Accelebrate";
    } else if (brand == 'webage') {
        return "Web Age Solutions";
    }
}

export const SupportEmail = (brand: string) => {
    if (brand == 'exitcertified') {
        return "training-support@exitcertified.com";
    } else if (brand == 'accelebrate') {
        return "support@accelebrate.com";
    } else if (brand == 'webage') {
        return "support@webagesolutions.com";
    }
}

export const SupportPhone = (brand: string) => {
    if (brand == 'exitcertified') {
        return "1-800-803-3948 ext. 3";
    } else if (brand == 'accelebrate') {
        return "1-844-849-1850 ext. 3";
    } else if (brand == 'webage') {
        return "1-844-721-3948 ext. 3";
    }
}

export function emailDomain() {
    const { webBase } = useConfig();
    return webBase.replace("http://", "").replace("https://", "").replace("www.", "");
}

export function modalWidth() {
    let width: string | number = '100%'
    if (Platform.OS === 'web') {
        if (useMediaQuery({ query: '(min-width: 960px)' })) {
            width = 750
        }

        if (useMediaQuery({ query: '(min-width: 1280px)' })) {
            width = 1024
        }
    }

    return width
}

export function modalHeight() {
    return Dimensions.get('window').height - 200;
}

export function formatName(name: string) {
    name = name.trim().toLocaleLowerCase();
    return name.charAt(0).toUpperCase() + name.slice(1);
}
export function formatEmail(email: string) {
    return email.trim().toLocaleLowerCase();
}
export function EmailUsername(coordination: string) {
    if (coordination == "PE Coordinator") {
        return 'privateevents';
    } else {
        return 'customer-experience';
    }
}
export function mergeArraysToMap(keys: string[], values: string[]): { [key: string]: string } {
    if (keys.length !== values.length) {
        throw new Error("The number of keys and values must be the same.");
    }

    // Use reduce to create the key/value map
    const mergedMap: { [key: string]: string } = {}

    keys.forEach((val, i) => {
        mergedMap[val] = values[i]
    })

    return mergedMap;
}

export function ValidateEmail(input: string) {

    // var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    var validRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (input.match(validRegex)) {
        return true;
    } else {
        return false;
    }
}

export function clearParams(params: string[]) {
    if (Platform.OS === 'web') {
        const queryParams = new URLSearchParams(location.search)

        params.forEach((param, i) => {
            if (queryParams.has(param)) {
                queryParams.delete(param)
            }
        })

        history.pushState({}, '', (queryParams.size > 0 ? '?' + queryParams.toString() : location.pathname))
    }
}

export const shouldStartLoadWithRequest = (req: any) => {
    if (req.url != 'about:blank') {
        Linking.openURL(req.url);
    }
};

export function downloadFile(url?: string, blob?: Blob, filename?: string): void {
    const linkEl = document.createElement("a");
    if (blob) {
        linkEl.href = window.URL.createObjectURL(blob);
        linkEl.setAttribute("download", filename ? filename : 'download');
    } else if (url) {
        linkEl.href = url;
        linkEl.download = url.substr(url.lastIndexOf('/') + 1);
    }
    document.body.appendChild(linkEl);
    linkEl.click();
    document.body.removeChild(linkEl);
}

export const mailtoURL = (training: TrainingMgmt, domainName: string, subject: string) => {
    var mailto = 'mailto:';
    if (training.coordination == 'Delivery Team') {
        mailto = mailto + 'privateevents';
    } else {
        mailto = mailto + 'customer-experience';
    }
    return mailto + '@' + domainName + '?subject=' + subject;
}
interface AlertOptions {
    text: string,
    onPress: () => void
    style?: string,
}
// Alert Box Polyfill for Web, iOS, & Android
const alertPolyfill = (title?: string, description?: string, options?: AlertOptions[]) => {
    const result = window.confirm([title, description].filter(Boolean).join('\n'))
    if (options) {
        if (result) {
            const confirmOption = options.find(({ style }) => style !== 'cancel')
            confirmOption && confirmOption.onPress()
        } else {
            const cancelOption = options.find(({ style }) => style === 'cancel')
            cancelOption && cancelOption.onPress()
        }
    }
    // } else {
    //     if (result) {
    //         const confirmOption = options;
    //         confirmOption && confirmOption.onPress()
    //     } else {
    //         const cancelOption = options;
    //         cancelOption && cancelOption.onPress()
    //     }
    // }
}

export function isIOSSafari(): boolean {
    const ua = window.navigator.userAgent;
    const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    const webkit = !!ua.match(/WebKit/i);

    return iOS && webkit && !ua.match(/CriOS/i);
}

export function isIOSChrome(): boolean {
    const ua = window.navigator.userAgent;
    const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);

    return iOS && !!ua.match(/CriOS/i);
}

const mobileAlertPolyfill = (title: string, description?: string, options?: AlertOptions[]) => {
    if (options) {
        const confirmOption = options.find(({ style }) => style !== 'cancel')
        const cancelOption = options.find(({ style }) => style === 'cancel')
        Alert.alert(title, description,
            [
                {
                    text: 'Yes',
                    onPress: async () => {
                        confirmOption && confirmOption.onPress()
                    },
                },
                {
                    text: 'No',
                    onPress: () => {
                        cancelOption && cancelOption.onPress();
                    },
                    style: 'cancel',
                },
            ],
            { cancelable: false }
        );
    }
}

const alert = Platform.OS === 'web' ? alertPolyfill : mobileAlertPolyfill

export default alert
