import { DripsyScrollViewProps } from '@dripsy/core/build/components/ScrollView'
import { ScrollView as DripsyScrollView } from 'dripsy'
import { Ref } from 'react';
import { ScrollView as nScrollView } from 'react-native';
export { A, ActivityIndicator, Container, H1, H2, H3, H4, H5, H6, Image, P, SafeAreaView, Text, TextInput, View, FlatList } from 'dripsy'
export { Modal, ImageBackground } from 'react-native'

interface ScrollViewProps extends DripsyScrollViewProps {
    innerRef?: Ref<nScrollView> | undefined;
}

export function ScrollView(props: ScrollViewProps) {
    const { sx, innerRef, children, ...otherProps } = props

    return <DripsyScrollView 
                id={undefined} 
                aria-label={undefined} 
                aria-busy={undefined} 
                aria-checked={undefined} 
                aria-disabled={undefined} 
                aria-expanded={undefined} 
                aria-selected={undefined} 
                aria-labelledby={undefined} 
                aria-valuemax={undefined} 
                aria-valuemin={undefined} 
                aria-valuenow={undefined} 
                aria-valuetext={undefined} 
                aria-hidden={undefined} 
                aria-live={undefined} 
                aria-modal={undefined} 
                role={undefined} 
                accessibilityLabelledBy={undefined} 
                accessibilityLanguage={undefined} 
                onPointerEnter={undefined} 
                onPointerEnterCapture={undefined} 
                onPointerLeave={undefined} 
                onPointerLeaveCapture={undefined} 
                onPointerMove={undefined} 
                onPointerMoveCapture={undefined} 
                onPointerCancel={undefined} 
                onPointerCancelCapture={undefined} 
                onPointerDown={undefined} 
                onPointerDownCapture={undefined} 
                onPointerUp={undefined} 
                onPointerUpCapture={undefined} 
                stickyHeaderHiddenOnScroll={undefined} 
                StickyHeaderComponent={undefined} 
                automaticallyAdjustKeyboardInsets={undefined} 
                automaticallyAdjustsScrollIndicatorInsets={undefined} 
                sx={sx}
                ref={innerRef}
                {...otherProps}
            >
                {children}
            </DripsyScrollView>
}