import Constants from "expo-constants"
import { getEnvVars } from "../config/useConfig"

const envVars = getEnvVars()

export function getEnv() {
    return Constants.manifest && Constants.manifest.extra ? Constants.manifest.extra.ENVIRONMENT_TYPE : envVars.env
}

export function isDev(): boolean {
    return envVars.env === "development"
}

export function isStaging(): boolean {
    return envVars.env === "staging"
}

export function isProd(): boolean {
    return envVars.env === "production"
}

export function getVersion(): string {
    return Constants.manifest && Constants.manifest.version ? Constants.manifest.version : "unknown"
}