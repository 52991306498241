import { useContext } from "react"
import { useMemberApi } from "../api/useMemberApi"
import { CoordinatorQuestionnaire } from '../models/TrainingMgmtData';
import { TrainingMgmtDataContext } from "../providers/TrainingMgmtProvider";
import { TrainingMgmt } from "../models/TrainingMgmtData";

export function useTrainingMgmt() {
    const { postUpdateOpp, getStartedClassroomLink, patchUpdateQuestionnaire, getTrainingMgmtFiles, downloadAttendanceRecords, getAttendance, getTrainingCertificates } = useMemberApi()
    const { loading, trainingMgmtData, roster, questionnaire, attendance, updateTrainingMgmt, refreshTrainingMgmtData, fetchQuestionnaire, fetchRoster, fetchAttendance, updateRoster, removeRoster, updateRosterMultiple } = useContext(TrainingMgmtDataContext)

    async function updateOpp(sfId: string, key: string, val: string | boolean): Promise<void> {
        await postUpdateOpp(sfId, key, val)
    }

    async function saveCoordinatorQuestionnaire(training: TrainingMgmt, auditors: string, attendance_rpt_rqd: boolean, os_arrival_time: string, os_location: string, os_shipping_address: string, os_shipping_instructions: string, os_instructor_byod: string, os_internet: boolean, os_instructor_internet: boolean, projector: boolean, os_projector_details: string, whiteboard: boolean, os_arrival_instructions: string, os_site_info_for_instructor: string, alternate_contact: string, imvp_platform: string, record_class: boolean, additional_info: string, exta_pq_question_1_answer: string, exta_pq_question_2_answer: string, exta_pq_question_3_answer: string): Promise<CoordinatorQuestionnaire> {
        const questionnaire = await patchUpdateQuestionnaire(training, auditors, attendance_rpt_rqd, os_arrival_time, os_location, os_shipping_address, os_shipping_instructions, os_instructor_byod, os_internet, os_instructor_internet, projector, os_projector_details, whiteboard, os_arrival_instructions, os_site_info_for_instructor, alternate_contact, imvp_platform, record_class, additional_info, exta_pq_question_1_answer, exta_pq_question_2_answer, exta_pq_question_3_answer);
        return questionnaire;
    }

    return { loading, trainingMgmtData, roster, questionnaire, attendance, refreshTrainingMgmtData, updateOpp, updateTrainingMgmt, getStartedClassroomLink, fetchQuestionnaire, saveCoordinatorQuestionnaire, fetchRoster, updateRoster, removeRoster, updateRosterMultiple, downloadAttendanceRecords, fetchAttendance, getAttendance, getTrainingMgmtFiles, getTrainingCertificates }
}