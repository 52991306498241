import { Image } from 'dripsy'
import { Platform } from 'react-native';
import { Text, View, ActivityIndicator } from '../../components/ui'

export const IconText = ({ icon, text, onAction, textColor = '$primary', underline = false, reverse = false, last = false }: { icon: any, text: string, onAction?: () => void, textColor?: string, underline?: boolean, reverse?: boolean, last?: boolean }) => {
    var iconStyle: any = { height: 40, width: 40, marginRight: '$2' };
    var textStyle: any = { color: reverse ? 'white' : textColor, fontWeight: 'bold', textDecorationLine: underline ? 'underline' : 'none' }
    var viewStyle: any = { flexDirection: 'row', minWidth: 250, flexShrink: 1, justifyContent: 'flex-start', alignItems: 'center', padding: '$2', ...reverse && { marginTop: '$1', bg: '$primary', alignSelf: 'flex-start' } };
    return (
        <View sx={{ padding: '$1', flexBasis: Platform.OS === 'web' ? 'auto' : '100%', ...Platform.OS !== 'web' && !last && { borderBottomWidth: 1, borderBottomColor: '$mediumgray', width: '100%' } }}>
            <View sx={viewStyle}>
                <Image source={icon} sx={iconStyle} resizeMode="contain" />
                <Text sx={textStyle} onPress={onAction}>{text}</Text>
            </View>
        </View>
    )
}