import { Image, View, Text } from "../components/ui"
import { ImageBackground, StyleSheet } from "react-native"
import { useMember } from "../hooks/useMember"
import { useConfig } from "../config/useConfig"
import { useMediaQuery } from "react-responsive";

const bgImg = require('../../assets/hero-bg.jpg')
const logo = require('../../assets/training-hub-logo.png')

export default function Header() {
    const { member, showHeader } = useMember()
    const { brand, appTheme } = useConfig()
    const isMobile = useMediaQuery({ query: '(max-height: 480px)' })

    const ECHeader = () => {
        return (
            <>
                {!isMobile && (
                    <View sx={{ paddingY: '$5', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', backgroundColor: appTheme && appTheme.heroBG ? 'none' : '$primary' }}>
                        <ImageBackground
                            source={appTheme && appTheme.heroBG ? appTheme.heroBG : bgImg}
                            resizeMode="cover"
                            style={StyleSheet.absoluteFill}
                        />
                        <Image source={appTheme && appTheme.siteLogo ? appTheme.siteLogo : logo} resizeMode="contain" sx={{ height: 60, width: 120 }} />
                        {member &&
                            <View>
                                <Text sx={{ color: '#fff', marginLeft: '$4', fontSize: '$2', fontWeight: 'bold' }}>Welcome {`${member.firstName}`}</Text>
                            </View>
                        }
                    </View>
                )}
            </>
        )
    }

    return ((showHeader &&
        <View>
            <ECHeader />
        </View>
    )
    )
}