import { Image, View } from "../components/ui";
import { useConfig } from "../config/useConfig";
import { ImageBackground, StyleSheet } from "react-native";
import { useMember } from "../hooks/useMember"

const logo = require('../../assets/exitcertified-logo.png');
const traininghublogo = require('../../assets/training-hub-logo.png');
const bgImg = require('../../assets/hero-bg.jpg')

export default function Logo() {
    const { showHeader } = useMember()
    const { appTheme } = useConfig()

    const BrandLogo = () => {
        return (
            <Image
                source={appTheme && appTheme.brandLogo ? appTheme.brandLogo : logo}
                resizeMode="contain"
                sx={{ height: 40, width: 193 }}
            />
        )
    }

    const styles = StyleSheet.create({
        triangle: {
            width: 0,
            height: 0,
            backgroundColor: "transparent",
            borderStyle: "solid",
            borderLeftWidth: 36,
            borderRightWidth: 36,
            borderBottomWidth: 36,
            borderLeftColor: "transparent",
            borderRightColor: "transparent",
            borderBottomColor: "white",
            transform: 'rotate(-45deg)',
            position: "absolute",
            left: -24,
            top: -6
        }
    });

    const SmallLogo = () => {
        return (
            <View sx={{ position: 'absolute', right: 0, top: 0, bottom: 0, padding: 5, paddingLeft: 100, backgroundColor: appTheme ? appTheme.primaryColor : '#000' }}>
                <ImageBackground
                    source={appTheme && appTheme.heroBG ? appTheme.heroBG : bgImg}
                    resizeMode="cover"
                    style={StyleSheet.absoluteFill}
                />
                <View style={[styles.triangle]} />
                <Image source={appTheme && appTheme.siteLogo ? appTheme.siteLogo : traininghublogo} resizeMode="contain" sx={{ top: 2, height: 35, width: 90, right: 34 }} />
            </View>
        )
    }

    return (
        <View sx={{ height: 50, alignItems: 'center', justifyContent: 'center' }}>
            {!showHeader && <SmallLogo />}
            <BrandLogo />
        </View>
    )
}