import { LayoutAnimation, SectionList } from "react-native";
import { Training, TrainingData } from "../models/TrainingData";
import { Section } from "../models/Section";
import { TrainingMgmt, TrainingMgmtData } from "../models/TrainingMgmtData";
import { H2, H3, Text, View } from '../components/ui';
import { Pressable } from "./ui/Pressable"
import { useEffect, useState, useRef } from "react";
import { useDripsyTheme } from "dripsy";
import CourseInfo from "./training/CourseInfo";
import { AntDesign } from '@expo/vector-icons';
import { useMember } from "../hooks/useMember";
import SectionLinks from "./ui/ScrollToSection"
import { TrainingInstructor, TrainingInstructorData } from "../models/TrainingInstructorData";

const ACTIVE = 1
const FUTURE = 2
const PAST = 3

interface TrainingStates {
    id: string | number,
    open: boolean
}

interface TrainingDataViewProps {
    trainingData: TrainingData | TrainingMgmtData | TrainingInstructorData | undefined
    loading: boolean
    showSections: boolean
    onRefreshTraining?: () => Promise<void>
    setupValidateEmailModal?: () => void
    ItemContent: React.ComponentType<{
        training: Training | TrainingMgmt | TrainingInstructor
        section: Section
    }>
    FooterContent: React.ElementType
}

export default function TrainingDataView(props: TrainingDataViewProps) {
    const { member, trainingMode } = useMember()
    const { trainingData, loading, showSections, onRefreshTraining, setupValidateEmailModal, ItemContent, FooterContent } = props
    const [trainingStates, setTrainingStates] = useState<TrainingStates[]>([])
    const { theme } = useDripsyTheme()
    const mainRef = useRef<SectionList<Section>>(null)
    const DATA: Section[] = [
        {
            title: "Active Training",
            view: ACTIVE,
            data: trainingData ? trainingData.active : []
        },
        {
            title: "Future Training",
            view: FUTURE,
            data: trainingData ? trainingData.future : []
        },
        {
            title: "Past Training",
            view: PAST,
            data: trainingData ? trainingData.past : []
        }
    ]

    useEffect(() => {
        if (trainingData) {
            const allTraining = [...trainingData.active, ...trainingData.future, ...trainingData.past]

            let states: TrainingStates[] = [];
            allTraining.map((t, i) => {
                states.push({ id: t.id, open: t.open })
            })

            setTrainingStates(states)
        }
    }, [trainingData])

    const toggleOpen = (training: Training | TrainingMgmt | TrainingInstructor) => {
        const tstateIndex = trainingStates.findIndex(t => { return t.id === training.id })

        if (tstateIndex >= 0) {
            trainingStates[tstateIndex].open = !trainingStates[tstateIndex].open
            setTrainingStates([...trainingStates])
            LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
        }
    }

    const Header = ({ title, view, data }: { title: string, view: number, data: any }) => {
        return (
            <>
                <H2 sx={theme.styles.header}>{title}</H2>
                {data.length === 0 &&
                    <View sx={{ flexDirection: 'column' }}>
                        {(setupValidateEmailModal !== undefined && view === PAST && member && !member.emailValidated) ?
                            <Text sx={{ color: '$primary' }} onPress={setupValidateEmailModal}>Validate your email for access to past classes.</Text>
                            :
                            <Text>No {title}</Text>
                        }
                    </View>
                }
            </>
        )
    }

    const Item = ({ training, section }: { training: TrainingMgmt | Training | TrainingInstructor, section: any }) => {
        const tstate = trainingStates.find(t => { return t.id === training.id })
        return (<>
            <Pressable
                onPress={() => toggleOpen(training)} key={training.id}
                sx={{
                    backgroundColor: theme.colors.$gray,
                    paddingLeft: theme.space.$1, paddingRight: theme.space.$1,
                    margin: 0,
                    flexDirection: 'row', justifyContent: 'space-between',
                    alignItems: 'center', borderBottomWidth: 1, borderBottomColor: theme.colors.$mediumgray
                }}>
                <View sx={{ flexDirection: 'row', flexBasis: '65%', padding: '$3' }}>
                    {training.program ? (
                        <View sx={{ backgroundColor: '$secondary', justifyContent: 'center', padding: '$1', borderRadius: 4 }}>
                            <Text sx={{ color: '#fff', fontStyle: 'italic' }}>Program</Text>
                        </View>
                    ) : (
                        <View sx={{ backgroundColor: '$primary', justifyContent: 'center', padding: '$1', borderRadius: 4 }}>
                            <Text sx={{ color: '#fff', fontStyle: 'italic' }}>Class</Text>
                        </View>
                    )}

                    <Text sx={{ color: '$primary', fontWeight: 'bold', marginLeft: '$2', padding: '$1' }}>{`${training.name}`}</Text>
                </View>
                <View sx={{ flexDirection: 'row', alignItems: 'center' }}>
                    <View sx={{ flexDirection: 'column', alignItems: 'right' }}>
                        <Text sx={{ color: '$primary', textAlign: 'right', fontWeight: 'bold', marginRight: '$2' }}>{`${training.startDate}`}</Text>
                        {section.view == PAST && trainingMode == 'instructor' &&
                            <Text sx={{ color: '$primary', textAlign: 'right', fontWeight: 'bold', marginRight: '$2' }}>{`${training.instructor}`}</Text>
                        }
                    </View>
                    <AntDesign name={tstate?.open ? "up" : "down"} size={28} color={theme.colors.$primary} />
                </View>
            </Pressable>
            <View sx={{ overflow: 'hidden', height: !tstate?.open ? 0 : undefined }}>
                <CourseInfo training={training} tableView={true} />
                <ItemContent training={training} section={section} />
            </View>
        </>)
    }

    return (
        <SectionList
            ref={mainRef}
            sections={DATA}
            stickySectionHeadersEnabled={false}
            keyExtractor={(item, index) => item.id}
            renderItem={({ item, section }) => <Item training={item} section={section} />}
            renderSectionHeader={({ section: { title, view, data } }) =>
                showSections && <Header title={title} data={data} view={view} />
            }
            renderSectionFooter={({ section: { title } }) =>
                showSections && <View sx={{ borderBottomColor: '$gray', borderBottomWidth: 0 }}></View>
            }
            ListHeaderComponent={() =>
                showSections && <SectionLinks mainRef={mainRef} DATA={DATA} staffHub={true} />
            }
            refreshing={loading}
            onRefresh={onRefreshTraining}
            ListEmptyComponent={<H3>No Training Found</H3>}
            ListFooterComponent={<FooterContent />}
        />
    )
}