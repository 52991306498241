import { createContext, useState } from "react";
import camelcaseKeys from 'camelcase-keys'
import MemberData from "../models/MemberData";
import MissingProvider from "../utils/MissingProvider";


interface MemberDataContextType {
    memberData: MemberData
    updateMemberData: (memberDataJson: string | null) => void
}

const initialMemberData = {
    unreadNotificationCount: 0
}

export const MemberDataContext = createContext<MemberDataContextType>({
    memberData: initialMemberData,
    updateMemberData: (memberDataJson: string | null) => { MissingProvider() }
})

interface MemberDataProviderProps {
    children?: React.ReactNode
}

function MemberDataProvider({ children }: MemberDataProviderProps) {
    const [memberData, setMemberData] = useState<MemberData>(initialMemberData)

    function updateMemberData(memberDataJson: string | null) {
        if (memberDataJson) {
            const memberData: MemberData = camelcaseKeys(JSON.parse(memberDataJson), { deep: true })
            setMemberData(memberData)
        }
    }

    return (
        <MemberDataContext.Provider value={{ memberData, updateMemberData }}>
            {children}
        </MemberDataContext.Provider>
    )
}

export default MemberDataProvider