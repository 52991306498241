import ReactGA4 from "react-ga4";
import { getEnvVars } from '../config/useConfig';

let config = getEnvVars();
let gaInitialized = false;
let gaAttempted = false;

const InitializeGoogleAnalytics = () => {
    if (!gaInitialized && !gaAttempted) {
        if(config.GoogleAnalyticsTrackingId) {
            console.log("Initializing GA " + config.GoogleAnalyticsTrackingId);
            ReactGA4.initialize(config.GoogleAnalyticsTrackingId);
            gaInitialized = true;
        } else {
            console.warn("No GA tracking ID set. Google Analytics was not loaded.");
        }
        gaAttempted = true;
    }
};

const TrackGoogleAnalyticsEvent = (category: string, action: string, label: string, transport: string) => {
    var e = {
        category: category,
        action: action,
        label: label,
        transport: transport
    };
    ReactGA4.event(category, e);
};
  

const TrackGoogleAnalyticsPage = (hitType: string, page: string, name: string) => {
    var e = {
        hitType: hitType,
        page: page,
        name: name,
    };
    ReactGA4.send(e);
};
  
export default InitializeGoogleAnalytics;
export { InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent, TrackGoogleAnalyticsPage };