import { useDripsyTheme } from "dripsy"
import { Pressable } from "./Pressable"
import { MaterialIcons } from '@expo/vector-icons'
import { Text } from '../../components/ui'

export default function InfoButton({ text, onPress }: { text: string, onPress: () => void }) {
    const { theme } = useDripsyTheme()

    return (
        <Pressable version="buttons.highlight" sx={{ flexDirection: 'row', alignItems: 'center' }} onPress={onPress}>
            <MaterialIcons name="info-outline" size={24} color={theme.colors.$highlightText} />
            <Text sx={{ color: '$highlightText', fontWeight: 'bold', marginLeft: '$2' }}>{text}</Text>
        </Pressable>
    )
}