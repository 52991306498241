import { useDripsyTheme } from 'dripsy';
import { Pressable } from './ui/Pressable';
import { Linking, Platform, Dimensions } from 'react-native';
import { View, Text, H2, H3, H4, ScrollView } from './ui';
import { ReggieFile } from "../models/TrainingData";
import { AntDesign } from '@expo/vector-icons';

export function FilesView({ files, title, scrollable = false }: { files: ReggieFile[], title?: string, scrollable?: boolean }) {
    const { theme } = useDripsyTheme()
    const windowSize: any = Dimensions.get('window').height - 200;
    const fileStyle = {
        paddingBottom: theme.space.$5,
        paddingX: theme.space.$3,
        maxHeight: windowSize,
        minWidth: theme.sizes.labMinWidth,
        width: '100%'
    }

    const MyView = ({ children }: { children: React.ReactNode }) => {
        return scrollable ? <ScrollView sx={fileStyle}>{children}</ScrollView> : <View sx={fileStyle}>{children}</View>
    }

    return (
        <>
            {files.length > 0 && (
                <MyView>
                    {title && <H2 sx={theme.styles.header}>{title}</H2>}
                    <View sx={{ alignItems: 'center' }}>
                        <H3 sx={{ marginBottom: '$3' }}>Downloads</H3>
                        <View sx={{ width: '100%', maxWidth: '560px' }}>
                            <View sx={{
                                flexDirection: 'row', justifyContent: 'space-between', paddingVertical: '$2',
                                paddingHorizontal: '$3', alignItems: 'center', backgroundColor: '$primary'
                            }}>
                                <Text sx={{ fontSize: 14, color: '#FFF', fontWeight: 'bold', paddingRight: '$5', flexBasis: '85%' }}>Name</Text>
                                <Text sx={{ fontSize: 14, color: '#FFF', fontWeight: 'bold', flexBasis: '15%' }}></Text>
                            </View>
                            {files.filter((file) => file.textTitle === "").map((file, index) => (
                                <View key={index} sx={{
                                    flexDirection: 'row', justifyContent: 'space-between', paddingVertical: '$2',
                                    paddingHorizontal: '$3', alignItems: 'center', backgroundColor: '$gray',
                                    borderLeftWidth: 1, borderColor: '$primary',
                                    borderBottomWidth: 1,
                                    borderRightWidth: 1
                                }}>
                                    <Text sx={{ fontSize: 14, color: '$primary', paddingRight: '$5', flexBasis: '85%' }}>{file.name}</Text>
                                    <View sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flexBasis: '15%' }}>
                                        <Pressable onPress={() => Linking.openURL(file.link)}>
                                            <AntDesign name="clouddownloado" size={32} color={theme.colors.$primary} />
                                        </Pressable>
                                    </View>
                                </View>
                            ))}
                        </View>
                    </View>
                </MyView>
            )}
        </>
    )
}