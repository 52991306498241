import React from "react";
import { Platform } from "react-native";
import { useMediaQuery } from "react-responsive";
import { ScrollView, View } from '../../components/ui'

interface TableWrapperProps {
    children: React.ReactNode
}

export function TableWrapper({ children }: TableWrapperProps) {

    const isWebAndDesktop = useMediaQuery({ query: '(min-width: 768px)' }) && Platform.OS === 'web'

    return (
        isWebAndDesktop ? (
            <View sx={{ padding: '$0', flexDirection: 'row', justifyContent: 'center' }}>
                {children}
            </View >
        ) : (
            <ScrollView horizontal={true} sx={{ padding: '$0', flex: 1, marginBottom: '$0' }}>
                {children}
            </ScrollView>
        )
    )
}