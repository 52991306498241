import { ActivityIndicator, useDripsyTheme } from 'dripsy';
import { Platform, Dimensions, ScrollView } from 'react-native';
import { View, Text, H2 } from '../ui';
import { useEffect, useState } from "react";
import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { Pressable } from "../ui/Pressable";
import { RadioButton } from "react-native-paper";
import FormTextInput from "../ui/forms/FormTextInput";
import DropDownPicker from 'react-native-dropdown-picker';
import { TrainingMgmt, CoordinatorQuestionnaire } from '../../models/TrainingMgmtData';
import { useTrainingMgmt } from '../../hooks/useTrainingMgmt';
import CourseInfo from './CourseInfo';
import BrandEmailLink from '../ui/BrandEmailLink';

interface FormValues {
    auditors: string,
    attendance_rpt_rqd: boolean,
    os_arrival_time: string,
    os_location: string,
    os_shipping_address: string,
    os_shipping_instructions: string,
    os_instructor_byod: string,
    os_internet: boolean,
    os_instructor_internet: boolean,
    projector: boolean,
    os_projector_details: string,
    whiteboard: boolean,
    os_arrival_instructions: string,
    os_site_info_for_instructor: string,
    alternate_contact: string,
    imvp_platform: string,
    record_class: boolean,
    additional_info: string,
    exta_pq_question_1: string,
    exta_pq_question_1_answer: string,
    exta_pq_question_2: string,
    exta_pq_question_2_answer: string,
    exta_pq_question_3: string,
    exta_pq_question_3_answer: string,
    name: string,
    email: string,
    timestamp: string
}

type CoordinatorQuestionnaireProps = {
    training: TrainingMgmt,
    hideMainTitle?: boolean
}

export function CoordinatorQuestionnaireView(props: CoordinatorQuestionnaireProps) {
    const { hideMainTitle, training } = props
    const { loading, questionnaire, fetchQuestionnaire, saveCoordinatorQuestionnaire } = useTrainingMgmt();
    const { ...methods } = useForm<FormValues>({ mode: 'onBlur' })
    const canSubmit = methods.formState.isValid && !methods.formState.isSubmitting
    const [updateError, setUpdateError] = useState("")
    const [formSuccess, setFormSuccess] = useState(false)
    const [windowSize, setWindowSize] = useState(0)
    const [windowWidth, setWindowWidth] = useState<any>('100%')
    const [windowBoxDropdownWidth, setWindowBoxDropdownWidth] = useState<any>('100%');
    const [windowBoxWidth, setWindowBoxWidth] = useState<any>('100%');
    const [iMVPPlatformOpen, setiMVPPlatformOpen] = useState(false);
    const [RequireAttendance, setRequireAttendance] = useState(false);
    const [InternetAccess, setInternetAccess] = useState(false);
    const [InstructorInternetAccess, setInstructorInternetAccess] = useState(false);
    const [Projector, setProjector] = useState(false);
    const [Record, setRecord] = useState(false);
    const [Whiteboard, setWhiteboard] = useState(false);
    const [classRecordingEnabled, setClassRecordingEnabled] = useState(false);
    const [iMVPPlatform, setiMVPPlatform] = useState<string>("");
    const [extraPeQuestion1, setExtraPeQuestion1] = useState<string>("");
    const [extraPeQuestion2, setExtraPeQuestion2] = useState<string>("");
    const [extraPeQuestion3, setExtraPeQuestion3] = useState<string>("");
    const conferencingPlatforms = [
        { key: 0, value: 'zoom', label: 'Zoom' },
        { key: 1, value: 'teams', label: 'Teams' },
        { key: 2, value: 'webex', label: 'WebEx' },
        { key: 3, value: 'other', label: 'Other' }
    ];
    DropDownPicker.setListMode("SCROLLVIEW");
    const { theme } = useDripsyTheme()

    useEffect(() => {
        async function loadAsync() {
            await fetchQuestionnaire(training.reggieId)
        }
        loadAsync()
        setWindowSize(Dimensions.get('window').height - 200)
        if (Platform.OS !== 'web') {
            setWindowWidth(Dimensions.get('window').width - 64)
        } else {
            setWindowWidth('100%');
        }
        setWindowBoxDropdownWidth(Number(Dimensions.get('window').width - 64 - 16));
        setWindowBoxWidth(Number(Dimensions.get('window').width - 64 - 14));
    }, [])

    useEffect(() => {
        if (questionnaire) {
            preloadTrainingData(questionnaire)
        }
        // setEditable(!disabled);
    }, [questionnaire])

    const preloadTrainingData = (questionnaire: CoordinatorQuestionnaire) => {
        onRequireAttendance(questionnaire.attendanceRptRqd ? questionnaire.attendanceRptRqd : false);
        onInternetAccess(questionnaire.osInternet ? questionnaire.osInternet : false);
        onInstructorInternetAccess(questionnaire.osInstructorInternet ? questionnaire.osInstructorInternet : false);
        onProjector(questionnaire.projector ? questionnaire.projector : false);
        onWhiteboard(questionnaire.whiteboard ? questionnaire.whiteboard : false);
        oniMVPPlatformChange(questionnaire.iMvpPlatform ? questionnaire.iMvpPlatform : false);
        setClassRecordingEnabled(questionnaire.allowRecordingClass ? questionnaire.allowRecordingClass : false);
        onRecord(questionnaire.allowRecordingClass ? questionnaire.recordClass : false);
        methods.setValue('auditors', questionnaire.auditors ? questionnaire.auditors : '');
        methods.setValue('os_arrival_time', questionnaire.osArrivalTime ? questionnaire.osArrivalTime : '');
        methods.setValue('os_location', questionnaire.osLocation ? questionnaire.osLocation : '');
        methods.setValue('os_shipping_address', questionnaire.osShippingAddress ? questionnaire.osShippingAddress : '');
        methods.setValue('os_shipping_instructions', questionnaire.osShippingInstructions ? questionnaire.osShippingInstructions : '');
        methods.setValue('os_instructor_byod', questionnaire.osInstructorByod ? questionnaire.osInstructorByod : '');
        methods.setValue('os_projector_details', questionnaire.osProjectorDetails ? questionnaire.osProjectorDetails : '');
        methods.setValue('os_arrival_instructions', questionnaire.osArrivalInstructions ? questionnaire.osArrivalInstructions : '');
        methods.setValue('os_site_info_for_instructor', questionnaire.osSiteInfoForInstructor ? questionnaire.osSiteInfoForInstructor : '');
        methods.setValue('alternate_contact', questionnaire.alternateContact ? questionnaire.alternateContact : '');
        methods.setValue('additional_info', questionnaire.additionalInfo ? questionnaire.additionalInfo : '');
        setExtraPeQuestion1(questionnaire.extraPeQuestion1 ? questionnaire.extraPeQuestion1 : '');
        setExtraPeQuestion2(questionnaire.extraPeQuestion2 ? questionnaire.extraPeQuestion2 : '')
        setExtraPeQuestion3(questionnaire.extraPeQuestion3 ? questionnaire.extraPeQuestion3 : '')
        methods.setValue('exta_pq_question_1_answer', questionnaire.extraPeAnswer1 ? questionnaire.extraPeAnswer1 : '');
        methods.setValue('exta_pq_question_2_answer', questionnaire.extraPeAnswer2 ? questionnaire.extraPeAnswer2 : '');
        methods.setValue('exta_pq_question_3_answer', questionnaire.extraPeAnswer3 ? questionnaire.extraPeAnswer3 : '');
    }

    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        let { auditors, attendance_rpt_rqd, os_arrival_time, os_location, os_shipping_address, os_shipping_instructions, os_instructor_byod, os_internet, os_instructor_internet, projector, os_projector_details, whiteboard, os_arrival_instructions, os_site_info_for_instructor, alternate_contact, record_class, additional_info, exta_pq_question_1_answer, exta_pq_question_2_answer, exta_pq_question_3_answer } = data;
        try {
            if (!projector) os_projector_details = '';
            await saveCoordinatorQuestionnaire(training, auditors, attendance_rpt_rqd, os_arrival_time, os_location, os_shipping_address, os_shipping_instructions, os_instructor_byod, os_internet, os_instructor_internet, projector, os_projector_details, whiteboard, os_arrival_instructions, os_site_info_for_instructor, alternate_contact, iMVPPlatform, record_class, additional_info, exta_pq_question_1_answer, exta_pq_question_2_answer, exta_pq_question_3_answer);
            setUpdateError("");
            setFormSuccess(true);
            setWindowSize(Dimensions.get('window').height - 256);
            setTimeout(() => {
                setFormSuccess(false);
            }, 6000);
        } catch (err: any) {
            setUpdateError(err.toString());
            setWindowSize(Dimensions.get('window').height - 256);
            setTimeout(() => { setUpdateError(''); }, 6000);
        }
    }

    const FormMessaging = () => {
        if (updateError.length > 0) {
            return <Text sx={{ overflow: 'hidden', marginTop: 15, marginBottom: 8, color: 'white', bg: '$error', fontWeight: 'bold', padding: '$2', borderRadius: 5, minWidth: '100%', textAlign: 'center' }}>{updateError}</Text>
        } else if (formSuccess) {
            return <Text sx={{ overflow: 'hidden', marginTop: 15, marginBottom: 8, color: '$primary', bg: '$highlight', fontWeight: 'bold', padding: '$2', borderRadius: 5, minWidth: '100%', textAlign: 'center' }}>Submitted Successfully</Text>
        } else {
            return null
        }
    }

    const onError: SubmitErrorHandler<FormValues> = (errors, e) => {
        if (errors) {
            setUpdateError('Missing required fields are present.');
        }
        return console.error("Form error(s):", errors)
    }

    const onRequireAttendance = (checked: boolean) => {
        setRequireAttendance(checked)
        methods.setValue('attendance_rpt_rqd', checked)
    }

    const onInternetAccess = (checked: boolean) => {
        setInternetAccess(checked)
        methods.setValue('os_internet', checked)
    }

    const onInstructorInternetAccess = (checked: boolean) => {
        setInstructorInternetAccess(checked)
        methods.setValue('os_instructor_internet', checked)
    }

    const onProjector = (checked: boolean) => {
        setProjector(checked)
        methods.setValue('projector', checked)
    }

    const onRecord = (record: boolean) => {
        setRecord(record)
        methods.setValue('record_class', record)
    }

    const onWhiteboard = (whiteboard: boolean) => {
        setWhiteboard(whiteboard)
        methods.setValue('whiteboard', whiteboard)
    }

    const oniMVPPlatformChange = async (platform: any) => {
        setiMVPPlatform(platform);
        methods.setValue("imvp_platform", platform)
    }

    return (
        <>
            <ScrollView
                automaticallyAdjustKeyboardInsets={true}
                style={{ paddingBottom: theme.space.$5, paddingHorizontal: theme.space.$3, maxHeight: windowSize }}>
                {!hideMainTitle && <H2 sx={theme.styles.header}>Coordinator Questionnaire</H2>}
                {loading ? (
                    <ActivityIndicator sx={{ marginTop: '$4' }} size="large" color="$primary" />
                ) : (
                    <View sx={{ width: windowWidth, alignItems: 'flex-start' }}>
                        <FormProvider {...methods}>
                            {training.isDuplicate &&
                                <Text sx={{ bg: '$error', color: '#fff', padding: '$2', fontWeight: 'bold', borderRadius: 8 }}>
                                    <Text sx={{ marginRight: '$1' }}>
                                        This event has not been setup for this portal, please contact
                                        <BrandEmailLink style={{ color: theme.colors.$primary }} username={training.coordination == 'Delivery Team' ? "customer-experience" : "privateevents"} />
                                        for assistance.
                                    </Text>
                                </Text>
                            }

                            <Text sx={{ marginLeft: 10, marginTop: 16 }}>
                                {training.rosterEditable ?
                                    <Text>Please complete the form below to submit your class information and help us plan a successful training event for you.</Text>
                                    :
                                    <Text sx={{ textAlign: 'center' }}>This questionnaire is no longer editable, please email to request changes.</Text>
                                }
                            </Text>

                            <CourseInfo training={training} tableView={false} />

                            <View sx={{
                                marginTop: 9, flexDirection: 'row', marginBottom: 8, marginLeft: -17, marginRight: -17,
                                paddingTop: 10, paddingRight: 22, paddingBottom: 12, paddingLeft: 22, backgroundColor: '#ececec',
                                borderWidth: 1, borderStyle: 'solid', borderColor: '#21558c'
                            }}>
                                <Text sx={{ verticalAlign: 'middle' }}>
                                    If anything above looks incorrect or you would like to request a change in any of those course details, please email:
                                    <BrandEmailLink style={{ color: theme.colors.$primary }} username={training.coordination == 'Delivery Team' ? "customer-experience" : "privateevents"} />
                                </Text>

                            </View>

                            <Text sx={{ marginLeft: 10, marginTop: 16 }}>We welcome seating up to two auditors in your class at no charge. Please note that auditors do not receive courseware, access to hands-on labs, or recordings of the class (where applicable). If you have any auditors, please provide their name, email, title, department, and telephone number.</Text>
                            <Text sx={{ marginLeft: 10, marginTop: 16 }}>Auditor Details</Text>
                            <FormTextInput
                                sx={{ height: 82 }}
                                name="auditors"
                                multiline={true}
                                placeholder="Please provide their name, email, title, department, and telephone number."
                                defaultValue=""
                                editable={training.rosterEditable}
                            />
                            {training.type == 'OS' && (
                                <>
                                    <Text sx={{ marginLeft: 10, marginTop: 16 }}>What time should the instructor arrive on the first day? We typically suggest 30 minutes prior to class.</Text>
                                    <FormTextInput
                                        name="os_arrival_time"
                                        placeholder="Instructor Arrival Time"
                                        defaultValue=""
                                        editable={training.rosterEditable}
                                    />

                                    <Text sx={{ marginLeft: 10, marginTop: 16 }}>What is the training site address?</Text>
                                    <FormTextInput
                                        sx={{ height: 82 }}
                                        name="os_location"
                                        multiline={true}
                                        placeholder="Training Site Address"
                                        defaultValue=""
                                        editable={training.rosterEditable}
                                    />
                                </>
                            )}
                            {training.type == 'DED' ? (
                                <>
                                    <Text sx={{ marginLeft: 10, marginTop: 16 }}>What are the name, email address, and phone number of an alternate contact we can reach out to if you are not available?</Text>
                                    <FormTextInput
                                        name="alternate_contact"
                                        placeholder="Alternative Contact"
                                        defaultValue=""
                                        editable={training.rosterEditable}
                                    />
                                </>
                            ) : training.type == 'OS' && (
                                <>
                                    <Text sx={{ marginLeft: 10, marginTop: 16 }}>What are the name, email address, and phone number of an onsite contact.</Text>
                                    <FormTextInput
                                        name="alternate_contact"
                                        placeholder="Onsite contact details"
                                        defaultValue=""
                                        editable={training.rosterEditable}
                                    />

                                    <Text sx={{ marginLeft: 10, marginTop: 16 }}>Where should the instructor go upon arrival?</Text>
                                    <FormTextInput
                                        name="os_arrival_instructions"
                                        placeholder="Instructor Arrival Instructions"
                                        defaultValue=""
                                        multiline={true}
                                        editable={training.rosterEditable}
                                    />

                                    <Text sx={{ marginLeft: 10, marginTop: 16 }}>Is there anything that the instructor should be aware of, i.e. parking restrictions, IDs required, sign-in procedures, specific directions to the training room, etc.?</Text>
                                    <FormTextInput
                                        name="os_site_info_for_instructor"
                                        placeholder="Instructor Directions"
                                        defaultValue=""
                                        multiline={true}
                                        editable={training.rosterEditable}
                                    />

                                    <Text sx={{ marginLeft: 10, marginTop: 16 }}>What is the shipping address?</Text>
                                    <FormTextInput
                                        sx={{ height: 82 }}
                                        name="os_shipping_address"
                                        placeholder="Shipping Address"
                                        editable={training.rosterEditable}
                                        multiline={true}
                                        defaultValue=""
                                    />

                                    <Text sx={{ marginLeft: 10, marginTop: 16 }}>Are there any specific shipping instructions?</Text>
                                    <FormTextInput
                                        sx={{ maxWidth: '100%' }}
                                        name="os_shipping_instructions"
                                        placeholder="Shipping Instructions"
                                        multiline={true}
                                        defaultValue=""
                                        editable={training.rosterEditable}
                                    />

                                    <View sx={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                        <View sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                            <Text sx={{ marginLeft: 10, marginTop: 16 }}>Will the classroom have internet access?</Text>
                                        </View>
                                        <View sx={theme.buttons.radioGroup}>
                                            <RadioButton.Group onValueChange={newValue => onInternetAccess(newValue == 'yes' ? true : false)} value={InternetAccess ? 'yes' : 'no'}>
                                                <View sx={{ flexDirection: 'row' }}>
                                                    <View sx={{ flexDirection: 'row' }}>
                                                        <Pressable sx={theme.buttons.radioLabel} onPress={() => onInternetAccess(false)}>
                                                            <Text>No</Text>
                                                        </Pressable>
                                                        <RadioButton.Android
                                                            uncheckedColor={theme.colors.$gray}
                                                            color={theme.colors.$primary}
                                                            value="no"
                                                            disabled={!training.rosterEditable}
                                                            status={!InternetAccess ? 'checked' : 'unchecked'}
                                                        />
                                                    </View>
                                                    <View sx={{ flexDirection: 'row', marginRight: 8 }}>
                                                        <Pressable sx={theme.buttons.radioLabel} onPress={() => onInternetAccess(true)}>
                                                            <Text>Yes</Text>
                                                        </Pressable>
                                                        <RadioButton.Android
                                                            uncheckedColor={theme.colors.$gray}
                                                            color={theme.colors.$primary}
                                                            value="yes"
                                                            disabled={!training.rosterEditable}
                                                            status={InternetAccess ? 'checked' : 'unchecked'}
                                                        />
                                                    </View>
                                                </View>
                                            </RadioButton.Group>
                                        </View>
                                    </View>

                                    <View sx={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                        <View sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                            <Text sx={{ marginLeft: 10, marginTop: 16 }}>Will the instructor have internet access?</Text>
                                        </View>
                                        <View sx={theme.buttons.radioGroup}>
                                            <RadioButton.Group onValueChange={newValue => onInstructorInternetAccess(newValue == 'yes' ? true : false)} value={InstructorInternetAccess ? 'yes' : 'no'}>
                                                <View sx={{ flexDirection: 'row' }}>
                                                    <View sx={{ flexDirection: 'row' }}>
                                                        <Pressable sx={theme.buttons.radioLabel} onPress={() => onInstructorInternetAccess(false)}>
                                                            <Text>No</Text>
                                                        </Pressable>
                                                        <RadioButton.Android
                                                            uncheckedColor={theme.colors.$gray}
                                                            color={theme.colors.$primary}
                                                            value="no"
                                                            disabled={!training.rosterEditable}
                                                            status={!InstructorInternetAccess ? 'checked' : 'unchecked'}
                                                        />
                                                    </View>
                                                    <View sx={{ flexDirection: 'row', marginRight: 8 }}>
                                                        <Pressable sx={theme.buttons.radioLabel} onPress={() => onInstructorInternetAccess(true)}>
                                                            <Text>Yes</Text>
                                                        </Pressable>
                                                        <RadioButton.Android
                                                            uncheckedColor={theme.colors.$gray}
                                                            color={theme.colors.$primary}
                                                            value="yes"
                                                            disabled={!training.rosterEditable}
                                                            status={InstructorInternetAccess ? 'checked' : 'unchecked'}
                                                        />
                                                    </View>
                                                </View>
                                            </RadioButton.Group>
                                        </View>
                                    </View>

                                    <Text sx={{ marginLeft: 10, marginTop: 16 }}>Are there any restrictions to the instructor using their laptop, tablet, or cell phone in the classroom?</Text>
                                    <FormTextInput
                                        name="os_instructor_byod"
                                        placeholder="Instructor Restrictions"
                                        editable={training.rosterEditable}
                                        multiline={true}
                                        defaultValue=""
                                    />

                                    <View sx={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                        <View sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                            <Text sx={{ marginLeft: 10, marginTop: 16 }}>Is there a projector in the classroom?</Text>
                                        </View>
                                        <View sx={theme.buttons.radioGroup}>
                                            <RadioButton.Group onValueChange={newValue => onProjector(newValue == 'yes' ? true : false)} value={Projector ? 'yes' : 'no'}>
                                                <View sx={{ flexDirection: 'row' }}>
                                                    <View sx={{ flexDirection: 'row' }}>
                                                        <Pressable sx={theme.buttons.radioLabel} onPress={() => onProjector(false)}>
                                                            <Text>No</Text>
                                                        </Pressable>
                                                        <RadioButton.Android
                                                            uncheckedColor={theme.colors.$gray}
                                                            color={theme.colors.$primary}
                                                            disabled={!training.rosterEditable}
                                                            value="no"
                                                            status={!Projector ? 'checked' : 'unchecked'}
                                                        />
                                                    </View>
                                                    <View sx={{ flexDirection: 'row', marginRight: 8 }}>
                                                        <Pressable sx={theme.buttons.radioLabel} onPress={() => onProjector(true)}>
                                                            <Text>Yes</Text>
                                                        </Pressable>
                                                        <RadioButton.Android
                                                            uncheckedColor={theme.colors.$gray}
                                                            color={theme.colors.$primary}
                                                            disabled={!training.rosterEditable}
                                                            value="yes"
                                                            status={Projector ? 'checked' : 'unchecked'}
                                                        />
                                                    </View>
                                                </View>
                                            </RadioButton.Group>
                                        </View>
                                    </View>

                                    {Projector && (
                                        <>
                                            <Text sx={{ marginLeft: 10, marginTop: 16 }}>What video connection ports does the projector support, i.e. HDMI, DVI, etc.?</Text>
                                            <FormTextInput
                                                name="os_projector_details"
                                                placeholder="Projector Connection Details"
                                                editable={training.rosterEditable}
                                                defaultValue=""
                                            />
                                        </>
                                    )}

                                    <View sx={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                        <View sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                            <Text sx={{ marginLeft: 10, marginTop: 16 }}>Is there a whiteboard in the classroom?</Text>
                                        </View>
                                        <View sx={theme.buttons.radioGroup}>
                                            <RadioButton.Group onValueChange={newValue => onWhiteboard(newValue == 'yes' ? true : false)} value={Whiteboard ? 'yes' : 'no'}>
                                                <View sx={{ flexDirection: 'row' }}>
                                                    <View sx={{ flexDirection: 'row' }}>
                                                        <Pressable sx={theme.buttons.radioLabel} onPress={() => onWhiteboard(false)}>
                                                            <Text>No</Text>
                                                        </Pressable>
                                                        <RadioButton.Android
                                                            uncheckedColor={theme.colors.$gray}
                                                            color={theme.colors.$primary}
                                                            disabled={!training.rosterEditable}
                                                            value="no"
                                                            status={!Whiteboard ? 'checked' : 'unchecked'}
                                                        />
                                                    </View>
                                                    <View sx={{ flexDirection: 'row', marginRight: 8 }}>
                                                        <Pressable sx={theme.buttons.radioLabel} onPress={() => onWhiteboard(true)}>
                                                            <Text>Yes</Text>
                                                        </Pressable>
                                                        <RadioButton.Android
                                                            uncheckedColor={theme.colors.$gray}
                                                            color={theme.colors.$primary}
                                                            disabled={!training.rosterEditable}
                                                            value="yes"
                                                            status={Whiteboard ? 'checked' : 'unchecked'}
                                                        />
                                                    </View>
                                                </View>
                                            </RadioButton.Group>
                                        </View>
                                    </View>

                                </>
                            )}

                            {/* { training.kitInfo.kitPreMessage.length > 0 ? ( 
                                    <View sx={{maxWidth: 500, marginBottom: 20, marginTop: 20}}>
                                        <Text>Course Material Access: Students will be notified that,</Text>
                                        <View sx={{ marginLeft: 10, marginTop: 16, marginBottom: theme.space.$2, fontSize:14 }}>
                                            <WebViewer html={training.kitInfo.kitPreMessage}></WebViewer>
                                        </View>
                                    </View>
                                ):null} */}


                            {training.mvpType === 'iMVP' && (
                                <>
                                    <View sx={{ maxWidth: 500 }}>
                                        <Text sx={{ marginLeft: 10, marginTop: 16, marginBottom: theme.space.$2 }}>What video conferencing platform should this class be delivered on?</Text>
                                        <DropDownPicker
                                            open={iMVPPlatformOpen}
                                            setOpen={setiMVPPlatformOpen}
                                            items={conferencingPlatforms}
                                            value={iMVPPlatform}
                                            setValue={oniMVPPlatformChange}
                                            disabled={!training.rosterEditable}
                                            dropDownDirection="TOP"
                                            dropDownContainerStyle={{ overflow: 'scroll', width: windowBoxDropdownWidth, maxWidth: 384, left: theme.sizes.selectorLeft, right: theme.sizes.selectorRight, bottom: theme.sizes.selectorStateBottom, borderColor: '#ececec' }}
                                            style={{ zIndex: 5, position: 'relative', width: windowBoxWidth, maxWidth: 386, minHeight: theme.sizes.selectorHeight, borderRadius: 0, borderColor: theme.colors.$background, backgroundColor: theme.colors.$gray, padding: theme.space.$2, margin: theme.sizes.selectorMargin, height: theme.sizes.selector }}>
                                        </DropDownPicker>
                                    </View>
                                </>
                            )}

                            {classRecordingEnabled && (
                                <>
                                    <View sx={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                        <View sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                            <Text sx={{ marginLeft: 10, marginTop: 16 }}>Would you like the class to be recorded? If so, the recordings will only be available to paid attendees.</Text>
                                        </View>
                                        <View sx={theme.buttons.radioGroup}>
                                            <RadioButton.Group onValueChange={newValue => onRecord(newValue == 'yes' ? true : false)} value={Record ? 'yes' : 'no'}>
                                                <View sx={{ flexDirection: 'row' }}>
                                                    <View sx={{ flexDirection: 'row' }}>
                                                        <Pressable sx={theme.buttons.radioLabel} onPress={() => onRecord(false)}>
                                                            <Text>No</Text>
                                                        </Pressable>
                                                        <RadioButton.Android
                                                            uncheckedColor={theme.colors.$gray}
                                                            color={theme.colors.$primary}
                                                            disabled={!training.rosterEditable}
                                                            value="no"
                                                            status={!Record ? 'checked' : 'unchecked'}
                                                        />
                                                    </View>
                                                    <View sx={{ flexDirection: 'row', marginRight: 8 }}>
                                                        <Pressable sx={theme.buttons.radioLabel} onPress={() => onRecord(true)}>
                                                            <Text>Yes</Text>
                                                        </Pressable>
                                                        <RadioButton.Android
                                                            uncheckedColor={theme.colors.$gray}
                                                            color={theme.colors.$primary}
                                                            disabled={!training.rosterEditable}
                                                            value="yes"
                                                            status={Record ? 'checked' : 'unchecked'}
                                                        />
                                                    </View>
                                                </View>
                                            </RadioButton.Group>
                                        </View>
                                    </View>
                                </>
                            )}

                            {training.type !== 'OS' && (
                                <>
                                    <View sx={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                        <View sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                            <Text sx={{ marginLeft: 10, marginTop: 16 }}>Do you require an attendance report?</Text>
                                        </View>
                                        <View sx={theme.buttons.radioGroup}>
                                            <RadioButton.Group onValueChange={newValue => onRequireAttendance(newValue == 'yes' ? true : false)} value={RequireAttendance ? 'yes' : 'no'}>
                                                <View sx={{ flexDirection: 'row' }}>
                                                    <View sx={{ flexDirection: 'row' }}>
                                                        <Pressable sx={theme.buttons.radioLabel} onPress={() => onRequireAttendance(false)}>
                                                            <Text>No</Text>
                                                        </Pressable>
                                                        <RadioButton.Android
                                                            uncheckedColor={theme.colors.$gray}
                                                            color={theme.colors.$primary}
                                                            disabled={!training.rosterEditable}
                                                            value="no"
                                                            status={!RequireAttendance ? 'checked' : 'unchecked'}
                                                        />
                                                    </View>
                                                    <View sx={{ flexDirection: 'row', marginRight: 8 }}>
                                                        <Pressable sx={theme.buttons.radioLabel} onPress={() => onRequireAttendance(true)}>
                                                            <Text>Yes</Text>
                                                        </Pressable>
                                                        <RadioButton.Android
                                                            uncheckedColor={theme.colors.$gray}
                                                            color={theme.colors.$primary}
                                                            disabled={!training.rosterEditable}
                                                            value="yes"
                                                            status={RequireAttendance ? 'checked' : 'unchecked'}
                                                        />
                                                    </View>
                                                </View>
                                            </RadioButton.Group>
                                        </View>
                                    </View>
                                </>
                            )}

                            {extraPeQuestion1 && (
                                <>
                                    <Text sx={{ marginLeft: 10, marginTop: 16 }}>{extraPeQuestion1}</Text>
                                    <FormTextInput
                                        name="exta_pq_question_1_answer"
                                        placeholder="Add an answer"
                                        editable={training.rosterEditable}
                                        defaultValue=""
                                    />
                                </>
                            )}
                            {extraPeQuestion2 && (
                                <>
                                    <Text sx={{ marginLeft: 10, marginTop: 16 }}>{extraPeQuestion2}</Text>
                                    <FormTextInput
                                        name="exta_pq_question_2_answer"
                                        placeholder="Add an answer"
                                        editable={training.rosterEditable}
                                        defaultValue=""
                                    />
                                </>
                            )}
                            {extraPeQuestion3 && (
                                <>
                                    <Text sx={{ marginLeft: 10, marginTop: 16 }}>{extraPeQuestion3}</Text>
                                    <FormTextInput
                                        name="exta_pq_question_3_answer"
                                        placeholder="Add an answer"
                                        editable={training.rosterEditable}
                                        defaultValue=""
                                    />
                                </>
                            )}

                            <Text sx={{ marginLeft: 10, marginTop: 16 }}>Is there anything else you would like to ask or tell us?</Text>
                            <FormTextInput
                                name="additional_info"
                                multiline={true}
                                placeholder="Additional info to ask or tell us..."
                                editable={training.rosterEditable}
                                defaultValue=""
                            />
                        </FormProvider>
                    </View>
                )}
            </ScrollView>
            <View sx={{ maxWidth: windowWidth, width: Platform.OS === 'web' ? '95%' : 'auto' }}><FormMessaging /></View>
            {!loading && training.rosterEditable && <View>
                <Pressable onPress={methods.handleSubmit(onSubmit, onError)} version="buttons.primary">
                    <Text sx={{ color: 'white' }}>Submit</Text>
                </Pressable>
            </View>}
        </>
    )
}